@use "./assets/scss/mixin"as *;

#member {
  .mainSection {
    margin-top: 0;
  }
  .sec01{
    .sec_ttl{
      margin:fluid(120,60) 0 fluid(60,35);
    }
    .box{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .l_area, .r_area{
        width: 49%;
        background-color:#FAFAFA;
        padding: fluid_tb(85,35) fluid_tb(85,20);
        @include media(sp) {
          width: 100%;
        }
      }
      .l_area{
        @include media(sp) {
          margin-bottom: 20px;
        }
        .btn_area_c{
          margin: fluid(50,25) 0 25px;
        }
        .txt_link{
          display: block;
          width: max-content;
          margin: 0 auto;
        }
      }
      h4{
        font-size: fluid(28,18);
        font-weight: 300;
        letter-spacing: .1em;
        line-height: 1.6;
        margin-bottom: 10px;
        color: #1F1F1F;
        @include media(sp) {
          
        }
        & + p{
          margin-bottom: fluid(50,15);
        }
      }
      dl{
        &:not(:last-of-type){
          margin-bottom: 25px;
        }
        dt{
          margin-bottom: 5px;
          color: #1F1F1F;
        }
      }
    }
  }

  .modaal-container{
    background: #fff;
    border-radius: 0;
    position: relative;
    padding: 5% 5% 3.5%;
    width: 100%;
    min-height: 400px;
    @include media(sp) {
      padding: 13% 5% 12%;
    }
    @include media(sp) {
      width: 100%;
    }
    h3{
      font-size: fluid(32,19);
      text-align: center;
      margin-bottom:10px;
      font-weight: 400;
      @include media(sp) {
        margin-bottom:15px;
      }
      & + p{
        text-align: center;
        margin-bottom: fluid(50,25);
        @include media(sp) {
          text-align: left;
        }
      }
    }
    dl{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      dt{
        @media only screen and (max-width: 860px){
          margin-bottom: 10px;
        }
      }
      dd{
        width: 70%;
        @media only screen and (max-width: 860px){
          width: 100%;
        }
      }
    }
    .btn{
      margin-top: fluid(60,40);
    }
    .txt_link{
      display: block;
      margin-top: fluid(25,15);
      color: #717D95;
      font-size: fluid(16,12);
    }
    .code_list{
      display: flex;
      justify-content: center;
      input{
        text-align: center;
        max-width: 56px;
        max-height: 56px;
        font-size: fluid(20,16);
        &:not(:last-child){
          margin-right: 2%;
        }
      }
    }
    .note_area{
      color: #797979;
      border-top: 1px solid #CDCDCD;
      padding-top: fluid(40,20);
      margin-top: fluid(80,40);
      font-size: fluid(13,11);
      p:first-child{
        margin-bottom: fluid(30,20);
      }
      ol{
        list-style-type: decimal;
        padding-left: 16px;
      }
    }
  }
  .code:focus {
    outline:auto;
  }

  input[type=checkbox] {
		display: none;
    &:checked+.checkbox01::before {
			background-color: #717d95;
		}
		&:checked+.checkbox01::after {
			opacity: 1;
		}
	}

  .checkbox01 {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding-left: 35px;
    position: relative;
    width: auto;
  
    &::before {
      background: #fff;
      border: 1px solid #e0e0e0;
      content: "";
      display: block;
      height: 20px;
      width: 20px;
      left: 0;
      position: absolute;
      top: fluid(5,1)
    }
  
    &::after {
      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;
      content: '';
      display: block;
      height: 9px;
      left: 7.5px;
      margin-top: -8px;
      opacity: 0;
      position: absolute;
      top: fluid(17,13);
      transform: rotate(45deg);
      width: 5px;
    }
  }

}
@use "./assets/scss/mixin"as *;

.footer {
	background: #717D95;
	position: relative;

	@include media(sp) {
		height: auto;
	}

	.footer_in {
		max-width: calc(1440px + 120px * 2);
		padding: fluid(60,30) fluid_tb(120, 20);
		width: 100%;
		margin: 0 auto;
		position: relative;
		color: #fff;


		.footer_upper {
			position: relative;
			margin-bottom: 60px;
			@include media(sp) {
				margin-bottom:80px;
			}

			nav {
				display: flex;
				@include media(sp) {
					margin-bottom: 30px;
				}

				dl {
					&:first-of-type {
						margin-right: 60px;
					}

					dt {
						font-size: fluid(22,18);
						letter-spacing: .15em;
						font-weight: 200;
						margin-bottom: fluid(15,5);
						font-family: 'Poppins', sans-serif;
					}

					dd {

						&:not(:last-child) {
							margin-bottom: fluid(13,5);
						}

						a {
							text-decoration: underline;
							&:hover{
								text-decoration: none;
							}
						}
					}
				}
			}

			.sns_list {
				position: absolute;
				right: 0;
				display: flex;
				top: 0;
				@include media(sp) {
					position: static;
				}

				li {
					width: fluid(32,28);
					transition: .3s;
					&:hover{
						opacity: .6;
					}

					&:first-child {
						margin-right: fluid(30,15);
					}
				}
			}
		}

		.footer_under {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include media(sp) {
				display: block;
			}

			ul {
				display: flex;
				flex-wrap: wrap;
				font-size: fluid(13,12);
				margin-bottom: fluid(20,15);
				margin-right: 20px;
				@include media(sp) {
					display: block;
					margin-right: 0;
				}

				li {
					position: relative;
					@include media(sp) {
						display: inline-block;
					}

					&:not(:first-child)::before {
						content: '';
						position: absolute;
						top: calc(50%);
						left: -20px;
						-webkit-transform: translateY(-50%);
						-ms-transform: translateY(-50%);
						transform: translateY(-50%);
						display: block;
						width: 1px;
						height: 14px;
						background-color: #fff;
					}

					&:not(:last-child) {
						margin-right: 40px;
					}

					@include media(sp) {
							
						&:nth-of-type(3)::before{
							content: none;
						}
						}
						a{
							&:hover{
								opacity: .6;
							}
						}
				}
			}

			.copy-light {
				font-size: fluid(11,10);
				font-family: 'Poppins', sans-serif;
			}
		}
	}
}

.btn-to-top {
	width: 60px;
	height: 60px;
	background: #333;
	position: fixed;
	display: none;
	right: 25px;
	bottom: 110px;
	border-radius: 100%;
	cursor: pointer;

	@include media(sp) {
		width: 45px;
		height: 45px;
		right: 15px;
	}

	&:after {
		content: "";
		display: block;
		width: 17px;
		height: 17px;
		border-top: 4px solid #fff;
		border-right: 4px solid #fff;
		transform: rotate(-45deg);
		position: absolute;
		top: 24px;
		left: 19px;

		@include media(sp) {
			width: 12px;
			height: 12px;
			top: 18px;
			left: 15px;
		}
	}
}
@use "./assets/scss/mixin" as *;

#TOP {
  .container,
  .mainSection {
    margin-top: 0;
  }

  header {
    position: absolute;

    @include media(sp) {
      background: none;
    }

    nav {
      border-bottom: none;
    }

    .header_up {
      @include media(sp) {
        background: none;

        &.change_sp,
        &.bg_f {
          background-color: #fff;
        }
      }
    }
  }

  .kv {
    background: url("../../assets/img/top/kv.jpg") no-repeat center;
    background-size: cover;
    height: auto;
    position: relative;
    padding-bottom: 56.2%;
    position: relative;

    @media only screen and (max-width: 768px) {
      background: url("../../assets/img/top/kv_sp.jpg") no-repeat center;
      background-size: cover;
      height: calc(100vh - 44px);
      padding-bottom: 0;
    }

    .kv_in {
      @include center;
      text-align: left;
      left: fluid_tb(120, 30);
      transform: translate(0%, -50%);
      top: 57%;
      left: 8.42%;
      width: auto;

      @media only screen and (max-width: 768px) {
        left: 50%;
        top: 30%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }

    h2 {
      font-family: fot-tsukumin-pr6n, sans-serif;
      font-size: fluid_tb(72, 38);
      font-weight: 100;
      line-height: 1.2;
      margin-bottom: 20px;

      @media only screen and (max-width: 768px) {
        text-align: center;
        @include fz_vw(38);
        line-height: 1.3;
      }

      span {
        display: block;

        @media only screen and (max-width: 768px) {
          margin-right: -6%;
        }
      }
    }

    .btn {
      margin: 0;
      @media only screen and (max-width: 768px) {
        margin: 0 auto;
      }
    }
  }

  .bnr_area {
    margin: fluid(60, 20) auto 0;
    ul {
      display: flex;
      justify-content: space-between;
      gap: 0 60px;
      @include media(sp) {
        flex-wrap: wrap;
        gap: 10px 0;
      }
      li {
        width: 33.3333%;
        flex: 1 1 0;
        @include media(sp) {
          width: 100%;
          flex: auto;
        }
        a{
          &:hover{
            opacity: .6;
          }
        }
      }
    }
  }

  .sec02 {
    background: url("../../assets/img/top/sec02_bg.jpg") no-repeat;
    background-position: center;
    background-size: contain;
    margin-bottom: 0;
    border-bottom: 1px solid #e9e9e9;

    @include media(sp) {
      background: none;
      position: relative;

      &::after {
        content: "";
        display: inline-block;
        position: relative;
        width: 100%;
        height: auto;
        background-repeat: no-repeat;
        z-index: 1;
        bottom: -52px;
        padding-bottom: 39.5%;
        background-image: url("../../assets/img/top/sec02_obj.png");
        background-size: contain;
        vertical-align: middle;
      }
    }

    .sec_in_bg {
      padding: fluid(200, 60) fluid(30, 20) fluid(160, 0);

      @include media(sp) {
        margin-bottom: -40px;
      }
    }

    h3 {
      text-align: center;
      font-family: fot-tsukumin-pr6n, sans-serif;
      font-weight: 100;
      font-size: fluid(44, 19);
      margin-bottom: fluid(60, 30);
      line-height: 1.6;
    }

    p {
      line-height: 3;
      text-align: center;

      @media only screen and (max-width: 1245px) {
        line-height: 2;
        text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff,
          -1px 0 0 #fff, 1px 0 0 #fff;
      }

      @media only screen and (max-width: 767px) {
        text-align: left;
      }

      & + p {
        margin-top: fluid(40, 20);
      }
    }
  }

  .sec03 {
    .sec_in_bg {
      padding: fluid(160, 80) fluid(120, 20) 0;
    }

    .sec_ttl {
      margin-bottom: fluid(120, 40);

      @include media(sp) {
        line-height: 1.3;
        letter-spacing: 0.05em;

        @media only screen and (max-width: 320px) {
          font-size: 21px;
        }

        span {
          margin-top: 5px;
        }
      }
    }

    .obj {
      position: absolute;
      bottom: 120px;
      left: 0;
      z-index: 10;
      width: fluid_tb(251, 180);

      @media only screen and (max-width: 1590px) {
        bottom: 0;
      }

      @media only screen and (max-width: 1440px) {
        bottom: 60px;
        position: absolute;
      }

      @media only screen and (max-width: 1439px) {
        position: static;
        margin-top: -25px;
      }

      @include media(sp) {
        margin: 0 auto fluid(50, 40);
      }
    }

    .j_slider {
      overflow: hidden;
      position: relative;
      margin-left: auto;
      padding-bottom: fluid(120, 0);

      @include media(sp) {
        margin: 0 calc(50% - 50vw);
        width: 100vw;
        padding-left: 17px;
      }

      .swiper-button {
        position: absolute;
        right: 50.7%;
        display: flex;
        bottom: 115px;

        @media only screen and (max-width: 1445px) {
          bottom: -60px;
          position: relative;
          left: 0;
          justify-content: flex-end;
        }

        @media only screen and (max-width: 1200px) {
          bottom: -27px;
        }
      }
    }

    .swiper-slide {
      background-color: #fff;

      @include media(sp) {
        max-width: 45%;
      }
      @media only screen and (max-width: 767px) {
        max-width: 90%;
      }

      .info_area {
        width: 100%;

        @include media(sp) {
          position: relative;
        }

        &::after {
          content: "";
          display: block;
          clear: both;
        }

        .txt_area {
          border-left: 1px solid #e9e9e9;
          padding-left: 40px;
          margin-left: fluid_tb(115, 95);
          position: relative;

          @include media(sp) {
            margin-left: 0;
            text-align: center;
            border: none;
            padding-left: 0;
            background-color: rgba(255, 255, 255, 0.98);
            padding: 20px;
            position: absolute;
            bottom: 15px;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 90%;
            height: auto;
          }

          .number {
            position: absolute;
            font-family: fot-tsukumin-pr6n, sans-serif;
            font-size: fluid(70, 26);
            top: 190px;
            left: -70px;
            letter-spacing: 0.1em;
            line-height: 1;
            transform: translate(-50%, -50%);
            text-align: center;

            @media only screen and (max-width: 1440px) {
              top: 260px;
            }

            @include media(sp) {
              position: static;
              transform: none;
              margin-bottom: 8px;
            }
          }
        }

        img {
          float: right;
          width: 46%;
          margin: 0 0 0 40px;

          @include media(sp) {
            float: none;
            width: 100%;
            margin: 0;
          }
        }

        h3 {
          font-size: fluid(32, 16);
          font-weight: 300;
          letter-spacing: 0.13em;
          color: #1f1f1f;
          line-height: 1.5;
          margin-bottom: fluid(10, 6);
        }

        h4 {
          font-size: fluid(19, 14);
          font-weight: 300;
          margin-bottom: fluid(15, 0);

          @include media(sp) {
            line-height: 1.6;
          }

          & + p {
            @include media(sp) {
              display: none;
            }
          }
        }
      }
    }
  }

  .sec04 {
    background: url("../../assets/img/top/sec04_bg.jpg") no-repeat;
    background-position: right 28% top;
    background-size: cover;
    height: fluid(514, 345);

    @include media(sp) {
      background: url("../../assets/img/top/sec04_bg_sp.jpg") no-repeat center;
      background-size: cover;
      text-align: center;
      margin-bottom: 75px;
    }

    h3 {
      font-size: fluid(44, 22);
      letter-spacing: 0.1em;
      margin-bottom: fluid(25, 15);
      font-weight: 300;
      span {
        font-size: fluid(19, 14);
        font-family: fot-tsukumin-pr6n, sans-serif;
        display: block;
        font-weight: 100;
        letter-spacing: 0;
        margin-top: -10px;
      }
    }

    p {
      margin-bottom: fluid(35, 15);
    }

    .sec_in {
      display: flex;
      align-items: center;
      height: 100%;

      @include media(sp) {
        justify-content: center;
      }
    }
  }

  .sec05 {
    position: relative;

    @include media(sp) {
      margin-bottom: 80px;
    }

    .sec_ttl + p {
      font-size: fluid(16, 14);
      margin-bottom: fluid(190, 80);

      @include media(sp) {
        text-align: center;
      }
    }
  }

  .sec06,
  .sec08 {
    @include media(sp) {
      margin-bottom: 40px;
    }

    .sec_in {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 100%;
      padding: 0;

      .l_area {
        width: 50%;
        background-color: #ecf1f5;
        position: relative;
        display: flex;
        align-items: center;

        @include media(sp) {
        }

        @media only screen and (max-width: 767px) {
          order: 2;
          width: 100%;
        }

        .l_area_in {
          padding: 0 fluid_tb(120, 30);

          @include media(sp) {
            padding: 40px 20px;
          }

          h3 {
            width: fluid(233, 140);
            margin-bottom: 30px;

            @media only screen and (max-width: 767px) {
              margin-bottom: 30px;
              position: absolute;
              top: -60px;
            }
          }

          p {
            & + p {
              margin: fluid(30, 20) 0 fluid(40, 30);

              @include media(sp) {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .r_area {
        width: 50%;

        @media only screen and (max-width: 767px) {
          order: 1;
          width: 100%;
        }

        img {
          min-height: 650px;
          object-fit: cover;

          @include media(sp) {
            min-height: 100%;
          }
        }
      }
    }
  }

  .sec06 {
    .btn {
      @include media(sp) {
        margin-top: 30px;
      }
    }
  }

  .sec07,
  .sec09 {
    .sec_ttl_jp {
      @include media(sp) {
        margin-bottom: 40px;
      }
    }

    .pickup_list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: fluid(100, 40);

      @include media(sp) {
        overflow-x: auto;
        flex-wrap: nowrap;
        justify-content: flex-start;
      }

      .item {
        width: 32%;

        @include media(sp) {
          width: 41.5%;
          flex-shrink: 0;

          &:not(:last-of-type) {
            margin-right: 5%;
          }
        }

        p {
          font-size: fluid(19, 14);

          span {
            font-size: fluid(16, 12);
            font-weight: 300;
          }
        }

        .p_ttl {
          margin-top: 20px;
          line-height: 1.6;

          &.link_blank {
            &::after {
              content: "";
              display: inline-block;
              width: 22px;
              height: 23px;
              background-image: url("../../assets/img/common/icon_blank.png");
              background-size: contain;
              vertical-align: middle;
              background-repeat: no-repeat;
              margin: -3px 0 0 7px;
              @include media(sp) {
                width: 14px;
                height: 15px;
              }
            }
          }
        }

        .p_price {
          font-weight: 600;
          font-family: "Poppins", sans-serif;
        }

        a {
          &:hover {
            opacity: 0.7;
            text-decoration: underline;
          }
        }
      }
    }

    .a_area {
      background-color: #ecf1f5;
      padding: 60px fluid(60, 20) fluid(60, 30);

      .coments {
        position: relative;
        padding: 0 fluid(92, 0);
        margin: 0 auto fluid(50, 20);

        &::before,
        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          width: 43px;
          height: 38px;
          top: 0;
          left: 0;
          background-size: contain;
          vertical-align: middle;
          background: url("../../assets/img/top/txt_obj1.svg") no-repeat center;
          background-size: cover;

          @include media(sp) {
            width: 24px;
            height: 21px;
            top: -35px;
          }
        }

        &::after {
          transform: rotate(-180deg);
          top: auto;
          left: auto;
          right: 0;
          bottom: 0;

          @include media(sp) {
            content: none;
          }
        }
      }

      .a_profile {
        display: flex;
        align-items: center;

        img {
          width: fluid(72, 40);
          margin-right: 20px;
          border-radius: 50%;

          @include media(sp) {
            margin-right: 3%;
          }
        }

        .p_area {
          .name {
            font-weight: 400;
            font-size: fluid(19, 14);
            line-height: 1.6;
          }

          .title {
            font-size: fluid(16, 12);
          }
        }
      }
    }
  }

  .sec08 {
    .sec_in {
      .l_area {
        background-color: #f7f0f2;

        .l_area_in {
          h3 {
            width: fluid(191, 119);
            margin-bottom: 45px;

            @include media(sp) {
              top: -60px;
            }
          }
        }
      }
    }
  }

  .sec09 {
    .a_area {
      background-color: #f7f0f2;

      .coments {
        &::before,
        &::after {
          background: url("../../assets/img/top/txt_obj2.svg") no-repeat center;
          background-size: cover;
        }
      }
    }
  }

  .sec10 {
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    padding: fluid(175, 70) 0;

    @include media(sp) {
      margin-bottom: 80px;
    }

    .sec_ttl + p {
      margin-bottom: fluid(45, 30);
      text-align: center;
    }

    .s_list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 1000px;
      margin: 0 auto fluid(130, 70);

      li {
        width: 29%;
        margin-top: 15px;

        &:not(:last-child) {
          margin-right: 35px;

          @include media(sp) {
            margin-right: 0.5%;
          }
        }

        @media only screen and (max-width: 767px) {
          width: 46.5%;
          margin: 15px 0.5% 0 0.5%;
        }

        a {
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }

    .b_ttl {
      position: absolute;
      left: 9%;
      top: 24%;

      @media only screen and (max-width: 767px) {
        top: -5%;
        z-index: 2;
      }

      span {
        font-size: fluid(22, 18);
        font-family: "Poppins", sans-serif;
        letter-spacing: 0.1em;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        transform: rotate(-180deg);
      }
    }

    .p_slider_area {
      position: relative;
    }

    .p_slider {
      overflow: hidden;
      position: relative;
      width: 80%;
      margin-left: auto;
      padding-bottom: fluid(120, 0);

      @media only screen and (max-width: 767px) {
        width: 95.5%;
      }

      .swiper-slide {
        max-width: fluid_tb(532, 350);

        @media only screen and (max-width: 767px) {
          max-width: 90%;
        }

        h4 {
          font-weight: 300;
          font-size: fluid(19, 16);
          margin: fluid(20, 10) 0 fluid(10, 4);
          line-height: 1.6;

          @include media(sp) {
            font-weight: 400;
          }
        }

        p {
          line-height: 1.6;
          font-size: fluid(16, 14);
          color: #888c8e;
        }

        a {
          &:hover {
            opacity: 0.7;
            text-decoration: underline;
          }
        }
      }

      .swiper-button {
        position: absolute;
        right: 7%;
        display: flex;
        bottom: 0;
      }

      .swiper-pagination-horizontal {
        bottom: -10px;
        left: 0;
        width: max-content;
        position: relative;

        @include media(sp) {
          display: none;
        }
      }

      .swiper-pagination-bullet {
        border: 1px solid #717d95;
        background: none;
        width: fluid_tb(14, 10);
        height: fluid_tb(14, 10);
        opacity: 1;
        margin: 0 5px;
      }

      .swiper-pagination-bullet-active {
        background-color: #717d95;
      }
    }
  }

  .sec11 {
    .sec_ttl {
      margin-bottom: fluid(70, 30);
    }

    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: fluid(60, 30);

      li {
        width: 48.5%;
        border-bottom: 1px solid #e9e9e9;
        padding-bottom: 30px;

        @include media(sp) {
          width: 100%;
          padding-bottom: 15px;
          margin-bottom: 25px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &:nth-child(n + 3) {
          margin-top: 45px;

          @include media(sp) {
            margin-top: 0;
          }
        }

        a {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;

          &:hover {
            opacity: 0.7;
            text-decoration: underline;
          }

          .l_area {
            width: 70%;

            @include media(sp) {
              width: 100%;
            }

            .l_area_head {
              @include media(sp) {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-bottom: 4px;
              }
            }

            .cat {
              border: 1px solid #e9ebee;
              height: fluid(36, 24);
              line-height: fluid(36, 23);
              font-size: fluid(13, 11);
              width: auto;
              padding: 0 15px;
              display: inline-block;

              @include media(sp) {
                order: 2;
                padding: 0 8px;
              }
            }

            .time {
              margin: 13px 0 0;
              font-family: "Poppins", sans-serif;

              @include media(sp) {
                order: 1;
                margin: 0 4% 0 0;
              }
            }

            h3 {
              font-weight: 300;
              font-size: fluid(19, 14);
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
          }

          img {
            width: 24.4%;
            height: auto;

            @include media(sp) {
              display: none;
            }
          }
        }
      }
    }
  }

  //--------------------------------------------------------------
  //TOP共通CSS
  //--------------------------------------------------------------

  .swiper-button-next,
  .swiper-button-prev {
    top: auto;
    bottom: 0;
    position: static;
    left: auto;
    width: fluid(80, 40);
    height: fluid(80, 40);
    transition: 0.3s;

    &:hover {
      opacity: 0.5;
    }

    @include media(sp) {
      display: none;
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: url("../../assets/img/top/arrow.svg") no-repeat center;
      background-size: contain;
      vertical-align: middle;
      transform: rotate(-180deg);
    }
  }

  .swiper-button-prev {
    margin-right: 25px;

    &:after {
      transform: rotate(0deg);
    }
  }
  .footer {
    .footer_in {
      @include media(sp) {
        padding-bottom: 135px;
      }
    }
  }
}

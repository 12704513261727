@use "./assets/scss/mixin"as *;

#jewelrylifeconcierge {


  .sec01 {
    .sec_ttl {
      margin-bottom: fluid(120, 60);
      line-height: 1.2;
      span{
        margin-top: fluid(15, 7);
      }
      @include media(sp) {
        position: relative;
        &::after{
          content: "";
          width: 12%;
          height: 1px;
          background-color: #e9e9e9;
          position: absolute;
          left: 0;
          right: 0;
          margin: 30px auto 0;
        }
      }
    }

    .box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;

      &:not(:last-of-type) {
        margin-bottom: fluid(100, 30);
      }
      &:last-of-type{
        .info_area{
          @include media(sp) {
            padding-bottom: 0;
          }
        }
      }

      .info_area {
        width: 52%;
        position: relative;

        @include media(sp) {
          width: 100%;
          order: 2;
          background-color: rgba(255,255,255,.98);
          padding: 30px 20px;
          margin-top: -40px;
        }

        .info {
          border-left: 1px solid #e9e9e9;
          padding-left: 8%;
          margin-left: fluid(105,80);

          @include media(sp) {
            margin-left: 0;
            border-left: none;
            padding-left: 0;
          }

          h3 {
            font-size: fluid(32, 19);
            font-weight: 300;
            margin-bottom: fluid(25, 6);
            letter-spacing: .1em;
            line-height: 1.4;
            color: #1F1F1F;

            @include media(sp) {
              text-align: center;
              letter-spacing: 0;
            }
          }

          h4 {
            font-size: fluid(19, 16);
            font-weight: 300;
            margin-bottom: 25px;

            @include media(sp) {
              text-align: center;
              line-height: 1.7;
            }
            & + p{
              @include media(sp) {
                line-height: 1.8;
              }
            }
          }
        }

        .number {
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          left: 0;
          font-weight: 100;
          position: absolute;
          font-family: fot-tsukumin-pr6n, sans-serif;
          font-size: fluid(70, 30);

          @include media(sp) {
            position: static;
            width: max-content;
            line-height: 1;
            margin: 0 auto 8px;
          }
        }
      }

      .img_aera {
        width: 44.5%;

        @include media(sp) {
          margin: 0 calc(50% - 50vw);
          width: 100vw;
          order: 1;
        }
      }
    }
  }

  .sec02 {
    background-color: #FAFAFA;
    margin-bottom: 0;

    .sec_ttl_jp {
      margin-bottom: 20px;

      @include media(sp) {
        line-height: 1.6;
      }

      &+p {
        text-align: center;
        margin-bottom: fluid(120, 30);
      }
    }

    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      li {
        width: 49%;

        @media only screen and (max-width: 768px){
          width: 100%;

          &:first-child {
            margin-bottom: 30px;
          }
        }

        h4 {
          font-size: fluid(19, 16);
          margin: fluid(20, 15) 0 5px;
          font-weight: 300;

          @include media(sp) {}

          &+p {
            color: #868a8c;
            line-height: 1.8;

            @include media(sp) {}
          }
        }
      }
    }
  }

  .modaal-container {
    background: #F8F4EC;
    border-radius: 0;
    position: relative;
    padding: 5.5%;

    .modaal-close {
      position: absolute;
      right: 30px;
      width: 30px;
      height: 30px;
      top: 30px;

      @include media(sp) {
        right: 15px;
        top: 15px;
      }

      &:before,
      &:after {
        background: #000;
        width: 1px;
        border-radius: 0;
        height: 25px;
        left: 15px;
        top: 4px;

        @include media(sp) {
          height: 20px;
        }
      }

      &:hover {
        background: none;
        opacity: .6;
      }
    }

    @include media(sp) {
      width: 100%;
    }

    h3 {
      font-size: fluid(32, 16);
      text-align: center;
      font-family: fot-tsukumin-pr6n, sans-serif;
      margin-bottom: 15px;
      color: #75663f;
      font-weight: 100;
    }

    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &:first-of-type {
        margin-bottom: fluid(30, 15);
      }

      li {
        width: 25%;
        position: relative;

        @include media(sp) {
          width: 28%;
        }

        img {
          border-radius: 50%;
        }

        &:not(:last-child) {

          &:after {
            content: '';
            display: inline-block;
            position: absolute;
            width: fluid(17, 6);
            height: fluid(17, 6);
            top: 52.5%;
            right: -28%;
            vertical-align: middle;
            border-top: 4px solid #75663f;
            border-right: 4px solid #75663f;
            transform: rotate(45deg);

            @include media(sp) {
              right: -19%;
              top: 57.5%;
              border-top: 3px solid #75663f;
              border-right: 3px solid #75663f;
            }
          }
        }

        p {
          color: #75663f;
          font-weight: 500;
          text-align: center;
          margin-bottom: fluid(20, 15);
          font-size: fluid(19, 10);
          line-height: 1.3;
        }
      }

      &:last-of-type {
        li {
          &:after {
            top: 48%;

            @include media(sp) {
              top: 45.5%;
            }
          }
        }
      }
    }
  }
  .topic_path_area{
    background-color: #fafafa;
    @include media(sp) {
		  background-color: #fff;
    }
  }
}
@use "./assets/scss/mixin"as *;

#setting,
#password-reset,
#client,
#withdrawal-form,
#auth-number {
  .sec01 {
    max-width: calc(1105px + 120px * 2);
    padding: 0 fluid_tb(120, 20);

    .sec_ttl {
      margin-bottom: fluid(60, 35);
    }

    .sec_in {
      width: 100%;
      background-color: #FAFAFA;
      margin: 0 auto;
      padding: fluid_tb(85, 35) fluid_tb(85, 20);

      &:not(:last-of-type) {
        margin-bottom: fluid_tb(100, 30);
      }

      &.s_sec_in{
        padding: 0;
        background: none;
        .c_select_box{
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          & > div{
            width: 48%;
            border: 1px solid #E0E0E0;
            position: relative;

            &::after{
              content: '';
              display: inline-block;
              position: absolute;
              width: 7px;
              height: 7px;
              top: fluid_tb(52,40);
              right: 10%;
              vertical-align: middle;
              border-top: 1px solid #1F1F1F;
              border-right: 1px solid #1F1F1F;
              transform: rotate(45deg);
              @include media(sp) {
                right: 5%;
                top: calc(50% - 7px / 2);
              }
            }
            a{
              padding: fluid(40,20);
              &:hover{
                opacity: .6;
              }
              @include media(sp) {
                padding-right: 30px;
              }
            }
            h3{
              font-size: fluid(19,16);
              margin-bottom: fluid(12,8);
              font-weight: 300;
              width: 90%;
              line-height: 1.6;
              & + p{
                color: #787878;
                font-size: fluid(13,12);
              }
            }
            @include media(sp) {
              width: 100%;
              &:first-of-type{
                margin-bottom: 20px;
              }
            }
          }
        }
      }


      .sec_ttl_jp {
        margin-bottom: 7px;
        line-height: 1.6;

        &+p {
          text-align: center;
          margin-bottom: fluid(60, 30);

          &.b_none {
            margin-bottom: 0;
          }
        }
      }


    }

    .input_area {


      dl {
        display: flex;
        justify-content: space-between;

        &:not(:last-of-type) {
          margin-bottom: fluid(30, 25);
        }



        .required {
          color: #FF2700;
          font-size: 12px;
          margin: -3px 0 0 6px;
          display: inline-block;
          vertical-align: middle;
        }

        dt {
          width: 180px;
          text-align: left;
          line-height: 1.5;
          font-weight: 400;
          padding-top: 17px;

          @include media(sp) {
            padding-top: 0;
          }

          &.a_pt {
            padding-top: 45px;

            @include media(sp) {
              padding-top: 0;
            }
          }
        }

        dd {
          width: calc(100% - 180px);

          &.dd_flex {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .n_txt {
              width: 48.5%;

              &:first-of-type {
                margin-right: 3%;
              }
            }
          }

          &.pt_t {
            margin-top: 13px;

            @include media(sp) {
              margin-top: 0;
            }
          }

          .note {
            font-size: fluid(13, 11);
            line-height: 1.7;
            display: block;
            margin-top: 10px;
            color: #888c8e;
          }

          .select_box {
            width: auto;
            border: 1px solid #e0e0e0;
            background-color: #fff;
            overflow: hidden;
            width: 140px;
            text-align: center;
            position: relative;

            @media only screen and (max-width: 768px){
              width: 90%;
            }

            &:not(:last-of-type) {
              @media only screen and (max-width: 768px){
                margin: 0 0 12px 0;
              }
            }

            &::before {
              position: absolute;
              top: calc(50% - 10px / 2);
              right: 18px;
              width: 7px;
              height: 7px;
              content: '';
              border-top: 2px solid #ccc;
              border-right: 2px solid #ccc;
              transform: rotate(135deg);
              pointer-events: none;
            }

            select {
              height: 56px;
              line-height: 56px;
              width: 100%;
              font-size: 16px;
              cursor: pointer;
              text-overflow: ellipsis;
              border: none;
              outline: none;
              background: transparent;
              background-image: none;
              box-shadow: none;
              -webkit-appearance: none;
              appearance: none;
              padding: 0 2em 0 1em;
              color: #333;

              &::-ms-expand {
                display: none;
              }
            }

            &.a_select_box {
              @include media(sp) {
                width: 43.5%;
              }
            }
          }

          .dob {
            display: block;
            margin: 0 fluid_tb(15, 4) 0 fluid_tb(15, 4);
            vertical-align: middle;

            @media only screen and (max-width: 768px){
              margin: -5px 0 0 3%;
            }
          }
        }


        .dd_columns {
          width: calc(100% - 180px);

          @media only screen and (max-width: 768px){
            width: 100%;
          }

          dd {
            width: 100%;

            &:not(:last-of-type) {
              margin-bottom: fluid(15, 10);
            }

            .a_txt {
              font-size: fluid(14, 11);
              font-weight: 400;
            }
          }
        }
      }

      @media screen and (max-width: 767px) {
        width: 100%;

        dl {
          flex-wrap: wrap;

          dt {
            width: 100%;
            margin-bottom: 10px;

            .required {
              font-size: 10px;
              margin-left: 10px;
            }
          }

          dd {
            width: 100%;
          }
        }
      }

      input[type=submit] {
        margin-top: fluid(60, 25)
      }
    }
    .btn {
      margin-top: fluid(60, 45);
    }
  }

  .topic_path_area {
    @include media(sp) {
      background-color: #fff;
    }
  }

  #contact {
    .error_box {
      background-color: #f7f0f2;
      border: 1px solid #e05a5a;
    }

    .sec_in {
      padding: fluid(80,20) fluid(85,20);
      &:first-of-type {
        margin-bottom: fluid(80, 20);
      }
    }

    .sec_ttl_jp {
      position: relative;
      font-size: fluid(28, 18);
      margin-bottom: 4px;

      @include media(sp) {
        margin-bottom: 8px;
      }

      &:before {
        content: '';
        display: inline-block;
        width: fluid(27, 19);
        height: fluid(23, 16);
        top: calc(50% - 23px / 2);
        background-image: url("../../assets/img/contact/icon_mail.svg");
        background-size: contain;
        background-repeat: no-repeat;
        vertical-align: middle;
        margin: -5px 14px 0 0;

        @include media(sp) {
          margin: -1px 10px 0 0;
        }
      }

      &.p_sec_ttl_jp {
        &:before {
          content: '';
          display: inline-block;
          width: fluid(28, 20);
          height: fluid(28, 20);
          top: calc(50% - 23px / 2);
          background-image: url("../../assets/img/contact/icon_phone.svg");
          background-size: contain;
          background-repeat: no-repeat;
          vertical-align: middle;
          margin: -5px 14px 0 0;

          @include media(sp) {
            margin: -1px 10px 0 0;
          }
        }
      }
    }

    .btn {
      margin-top: fluid(30, 25);
    }

    //個人情報の取り扱い----------------------------------

    .privacy_area {
      height: 300px;
      background-color: #fff;
      border: 1px solid #e0e0e0;
      padding: fluid(40, 20);
      text-align: left;
      overflow-y: scroll;
      font-size: fluid(14, 13);
      line-height: 1.6em;
      letter-spacing: 0.05em;
      margin: 20px 0 40px;

      h4 {
        font-size: fluid(20, 16);
        margin: 25px 0 15px;
        font-weight: 400;
      }

      dt {
        margin-top: 1em;
        font-weight: 400;
      }

      dd {
        margin-top: 0.5em;
      }

      .establishment {
        text-align: right;
        margin-top: 50px;
      }
    }

    .agree_check {
      text-align: center;
    }

    .mw_wp_form_confirm {
      .input_area dl {
        border-bottom: 1px solid #ddd;
        padding-bottom: 27px;
        align-items: center;

        dt {
          padding-top: 0;
        }
      }

      .privacy_area {
        display: none;
      }

      .btn_area_c_flex {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        button {
          margin-right: 20px;
          @include media(sp) {
            margin: 30px 0 0 0;
            width: 100%;
            & + input[type=submit]{
              margin-top: 15px;
              width: 100%;
            }
          }
        }
      }

      .dd_columns {
        dd {
          margin-bottom: 0;

          &:last-of-type {
            display: none;
          }
        }
      }

    }

    .thanks_sec {
      .sec_ttl_jp {
        margin-bottom: fluid(25, 15) !important;

        &:before {
          content: none;
        }

        &+p {
          margin-bottom: 0 !important;
        }
      }
      .btn{
        border: 1px solid #717d95;
      }
    }

    .phone_area {
      width: 100%;
      background-color: #FAFAFA;
      margin: 0 auto;
      text-align: center;

      .p_number {
        font-size: fluid(26, 20);
        font-family: "Poppins", sans-serif;
        letter-spacing: .12em;
        margin-bottom: 0 !important;

        @include media(sp) {
          margin-bottom: 7px !important;
        }
      }
    }
  }

//--------------------------------------------------------------
//共通CSS
//--------------------------------------------------------------

  .modaal-container{
    background: #fff;
    border-radius: 0;
    position: relative;
    padding: 5% 5% 3.5%;
    width: 100%;
    min-height: 400px;
    @include media(sp) {
      padding: 13% 5% 12%;
    }
    @include media(sp) {
      width: 100%;
    }
    h3{
      font-size: fluid(32,19);
      text-align: center;
      margin-bottom:10px;
      font-weight: 400;
      @include media(sp) {
        margin-bottom:15px;
        line-height: 1.6;
      }
      & + p{
        text-align: center;
        margin-bottom: fluid(50,25);
        @include media(sp) {
          text-align: left;
        }
      }
    }
    dl{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      &:not(:last-of-type){
        margin-bottom: 25px;
      }
      dt{
        @media only screen and (max-width: 860px){
          margin-bottom: 10px;
        }
        &.phone_txt{
          margin-bottom: 0;
        }
      }
      dd{
        width: 70%;
        @media only screen and (max-width: 860px){
          width: 100%;
        }
      }
    }
    .btn{
      margin-top: fluid(60,40);
    }
    .txt_link{
      display: block;
      margin-top: fluid(25,15);
      color: #717D95;
      font-size: fluid(16,12);
    }
    .code_list{
      display: flex;
      justify-content: center;
      input{
        text-align: center;
        max-width: 56px;
        max-height: 56px;
        font-size: fluid(20,16);
        &:not(:last-child){
          margin-right: 2%;
        }
      }
    }
    .note_area{
      color: #797979;
      border-top: 1px solid #CDCDCD;
      padding-top: fluid(40,20);
      margin-top: fluid(80,40);
      font-size: fluid(13,11);
      p:first-child{
        margin-bottom: fluid(30,20);
      }
      ol{
        list-style-type: decimal;
        padding-left: 16px;
      }
    }
  }
  .code:focus {
    outline:auto;
  }

  .phone_number {
    font-size: fluid(20, 16);
    display: block;
    padding-top: 8px;

    @include media(sp) {
      padding-top: 0;
    }
  }

  .sms_note {
    line-height: 1.7;
    display: block;
    color: #e05a5a;
  }
}
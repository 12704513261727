@use "./assets/scss/mixin"as *;

#shop {
  .sec01 {
    border-bottom: 1px solid #e9e9e9;
    .sec_in{
      padding-bottom: fluid(120,60);
    }

    .s_list{
      padding-left: fluid(20,0);
      &:not(:last-of-type){
        border-bottom: 1px solid #e9e9e9;
        padding-bottom: fluid(60,30);
        margin-bottom: fluid(50,30);
      }
    }

    h4 {
      font-size: fluid(28, 16);
      line-height: 1.8;
      font-weight: 300;
      color: #1F1F1F;
      margin-top: fluid(40,20);
      letter-spacing: .1em;

      @include media(sp) {
        line-height: 1.6;
        letter-spacing: 0;
        margin-bottom: 7px;
      }
      &.mb_ttl{
        margin-bottom: fluid(30, 20);
      }
    }

    p {
      margin-bottom: fluid(30, 20);
    }

    .d_list {
      dl {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include media(sp) {
          line-height: 1.6;
        }

        &:not(:last-of-type) {
          margin-bottom: 3px;
        }

        dt {
          color: #868a8c;
          width: fluid(120,80);
         
        }

        dd {
          width: calc(100% - fluid(120,80));
          .r_dd{
            margin-right: 20px;
          }
          a{
            display: inline-block;
          }
        }
      }
    }
    .map{
      left: 0;
      display: inline-block;
      @include media(sp) {
        display: block;
        margin-top: 5px;
      }
    }
  }

  .sec02 {
    .sec_ttl_jp{
      margin-bottom: 20px;
      @include media(sp) {
        margin-bottom: 30px;
      }
      & + p{
        text-align: center;
        margin-bottom: fluid(65,30);
      }
    }

    .sec_in {
      .s_list{
        h4{
          background-color: #F9F9F9;
          border-top: 1px solid #e9e9e9;
          border-bottom: 1px solid #e9e9e9;
          padding: fluid(24,15) 20px;
          font-size: fluid(19,16);
          color: #1F1F1F;
          font-weight: 300;
          @include media(sp) {
            margin: 0 calc(50% - 50vw) ;
            width: 100vw;
          }
        }
        .s_d_list{
          & > li{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            position: relative;
            padding: fluid(45,20) 0 fluid(55,20) fluid(20,0);
            border-bottom: 1px solid #e9e9e9;
            &:last-child{
              border-bottom: none;
            }
            .s_name{
              width: 23%;
              margin-top: fluid(12,0);
              @include media(sp) {
                width: 100%;
              }
              h5{
                color: #1F1F1F;
                font-size: fluid(28,19);
                margin-bottom: fluid(10,5);
                font-weight: 300;
                letter-spacing: .1em;
                line-height: 1.4;
              }
              }
            }
            .d_list {
              width: 75%;
              margin-top: 15px;
              @include media(sp) {
                width: 100%;
              }
              dl {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                @include media(sp) {
                  line-height: 1.6;
                }
        
                &:not(:last-of-type) {
                  margin-bottom: 7px;
                }
        
                dt {
                  color: #868a8c;
                  width: fluid(160,120);
                }
        
                dd {
                  width: calc(100% - fluid(160,120));
                  a{
                    display: inline-block;
                  }
                }
              }
              .note{
                background-color: #F7F0F2;
                border: 1px solid #FFBCBF;
                color: #E05A5A;
                padding: fluid(20,15) fluid(25,20);
                margin-top: fluid(40,15);
                display: flex;
                @include media(sp) {
                  display: block;
                }
                span{
                  font-weight: 400;
                  display: inline-block;
                  width: 150px;
                  @include media(sp) {
                    display: block;
                    width: 100%;
                    margin: 0 0 7px 0;
                  }
                }
              }
            }


            .icon_list{
              margin-top: 5%;
              @include media(sp) {
                display: flex;
                margin-top: 6%;
                li{
                  &:not(:last-child){
                    margin-right: 25px;
                  }
                }
              }
              li{
                position: relative;
                font-size: fluid(16,12);
                &:not(:last-child){
                  margin-bottom: 3px;
                }
              }
            }
          }
          .b_list{
            li{
              display: inline-block;
              a{
                text-decoration: underline;
                &:hover{
                  text-decoration: none;
                }
              }
              &:not(:last-child){
              a:after{
                content: '、';
                display: inline-block;
                font-size: fluid(16,13);
                background-size: contain;
              }
            }}
          }
          &:last-of-type{
            & >ul> li:last-child{
            @include media(sp) {
              border-bottom: 1px solid #cdcdcd;
            }
          }
          }
        }
      }
    }


    a{
      &.map{
        color: #717D95;
        &:hover{
          text-decoration: underline;
        }
      &::before{
        content: '';
        display: inline-block;
        width: fluid(16,12);
        height: fluid(19,14);
        background-image: url("../../assets/img/shop/icon_map.svg");
        background-size: contain;
        vertical-align: middle;
        margin: -3px 8px 0 0;
        background-repeat: no-repeat;
      }
    }
    &.parking{
      color: #717D95;
      &:hover{
        text-decoration: underline;
      }
      &::before{
        content: '';
        display: inline-block;
        width: fluid(18,14);
        height: fluid(19,15);
        background-image: url("../../assets/img/shop/icon_parking.svg");
        background-size: contain;
        vertical-align: middle;
        margin: -3px 8px 0 0;
        background-repeat: no-repeat;
      }
    }
  }
  }
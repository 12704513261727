@use "./assets/scss/mixin"as *;

.header {
	width: 100%;
	top: 0;
	z-index: 999;
	position: fixed !important;

	@include media(sp) {
		background: #fff;
	}

	.header-nav_sp {
		display: none;
		width: 50%;
		position: fixed;
		display: none;
		border: none;
		z-index: -1;
		height: 100vh;
		background: #fff;
		padding: fluid_tb(200, 160) 0 fluid_tb(60, 20) fluid_tb(120, 60);
		top: 0;
		-webkit-overflow-scrolling: touch;
		overflow-y: scroll;

		@media only screen and (max-height: 730px) {
			padding-top: 160px;
		}


		@include media(sp) {
			padding:36px 0 10% 10%;
			width: 100%;
			top: 58px;
			max-height: calc(100vh - 46px);
    	transition-duration: .3s;
    	transition-property: max-height;
		}

		&::after {
			content: '';
			display: inline-block;
			position: fixed;
			width: fluid_tb(223, 114);
			height: fluid_tb(224, 121);
			bottom: 3%;
			right: 51.5%;
			background-image: url("../../assets/img/common/header_bg.jpg");
			background-size: fluid_tb(223, 114), fluid_tb(224, 121);
			vertical-align: middle;
			background-repeat: no-repeat;
			transition: .3s;
			@include media(sp) {
				z-index: -1;
				right: 15px;
			}
		}


		.nav_list {
			display: block;
			margin-bottom: 110px;
			position: relative;
			@media only screen and (max-height: 730px) {
				margin-bottom: 55px;
			}
			&::after{
				content: '';
				position: absolute;
				height: 1px;
				width: 60%;
				background-color: #e9e9e9;
				bottom: -55px;
				@media only screen and (max-height: 730px) {
					bottom: -25px;
				}
			}

			&>li {
				height: auto;
				display: block;

				&:not(:last-child) {
					padding-bottom: 22px;

					@media only screen and (max-height: 730px) {
						padding-bottom: 10px;
					}

					@include media(sp) {
						padding-bottom: 20px;
					}
				}

				a {
					position: relative;
					flex-wrap: wrap;
					letter-spacing: 0;
					font-size: fluid(13, 12);
					display: flex;
					align-items: center;

					span {
						font-family: 'Poppins', sans-serif;
						font-size: fluid(22, 18);
						display: block;
    				margin-bottom: 0;
    				letter-spacing: .14em;
    				padding-right: 15px;
    				margin-right: 10px;
						position: relative;
						&::after{
							content: '';
    					width: 1px;
    					height: 15px;
    					background-color: #1f1f1f;
    					position: absolute;
    					top: calc(50% - 7.5px);
    					right: 0px;

						}
						@include media(sp) {
							width: 100%;
							line-height: 1.2;
							padding-right: 0;
    					margin-right: 0;
							&::after{
								content: none;
	
							}
						}
					}

					&:hover {
						color: #717D95;
					}
				}

				.mega_menu {
					display: none;
					position: static;
					padding-bottom: 20px;
					width: 100%;
					height: auto;
					margin-top: fluid(30, 20);

					li {
						height: auto;
						line-height: 1.4em;

						&:not(:last-child) {
							margin-bottom: fluid_tb(20, 13);
						}


						a {
							font-size: fluid(16, 12);
							font-family: "Poppins",sans-serif;

							&.no_blank::after {
								content: none;
							}

							&::after {
								content: '';
								display: inline-block;
								width: 15px;
								height: 16px;
								background-image: url("../../assets/img/common/icon_blank.png");
								background-size: contain;
								vertical-align: middle;
								background-repeat: no-repeat;
								margin: -3px 0 0 5px;
							}
						}
					}
				}
			}

		}

		.icon_list {

			li {
				position: relative;
				&:first-child{
					margin-bottom: fluid(25,15)
				}

				a {
					display: flex;
					font-size: 13px;
					align-items: center;
					letter-spacing: .05em;
				}

				img {
					max-width: 20px;
					margin-right: 10px;
				}

				&:last-child {
					margin-left: 0;

					img {
						width: 59%;
					}
				}
			}
		}
	}


	&.change {
		.header-in {
			.header_up{
				background-color: #fff;
			h1{
				max-width: 146px;
			}
			.nav_toggle {
				width: 22px;
				cursor: pointer;
				height: 16px;
				position: absolute;
				top: calc(50% - 16px / 2);
				z-index: 100;
				left: 35px;

				@include media(sp) {
					top: calc(50% - 16px / 2);
					left: 15px;
				}

				span {
					display: block;
					height: 1px;
					background: #222;
					position: absolute;
					width: 100%;
					left: 0;
					transition: 0.3s ease-in-out;

					&:nth-child(1) {
						top: 0;
					}

					&:nth-child(2) {
						top: 7px;
					}

					&:nth-child(3) {
						top: 14px;
					}
				}
				

			/*開閉ボタンopen時*/
			&.open {
				height: 24px;
				top: calc(50% - 24px / 2);
				span {
					&:nth-child(1) {
						top: 12px;
						-webkit-transform: rotate(135deg);
						-moz-transform: rotate(135deg);
						transform: rotate(135deg);
					}

					&:nth-child(2) {
						width: 0;
						left: 50%;
					}

					&:nth-child(3) {
						top: 12px;
						-webkit-transform: rotate(-135deg);
						-moz-transform: rotate(-135deg);
						transform: rotate(-135deg);
					}
				}
			}
			}

			.header_up {
				padding: fluid(35, 10) 0;
				background-color: #fff;
			}
		}
	}
}

	.header-in {
		margin: 0 auto;
		position: relative;
		height: 100%;

		@include media(sp) {
			width: 100%;
		}

		.header_up {
			position: relative;
			border-bottom: 1px solid #e2e2e2;
			width: 100%;
			padding: 16px 0;
			transition: .3s;

			@include media(sp) {
				padding: 18px 0;
			}

			h1 {
				max-width: 194px;
				transition: .3s;
				margin: 0 auto;
				width: 19.7%;

				img {
					display: block;
					top: 0;
				}

				@include media(sp) {
					width: 89px;
					top: 20px;
				}
			}
		}



		.header-nav {
			border-bottom: 1px solid #e9e9e9;
			height: fluid_tb(100, 90);

			@include media(sp) {
				display: none;
			}



			.nav_list {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				height: 100%;

				& > li {
					position: relative;
					display: flex;
					align-items: center;
					height: 100%;

					@include media(sp) {
						height: auto;
						display: block;
					}

					&:not(:last-child) {
						margin-right:fluid(50,20);

						@include media(sp) {
							margin-right: 0;
						}
					}

					a {
						transition: 0.2s;
						text-align: center;
						font-size: fluid_tb(13, 12);

						@include media(sp) {
							padding-bottom: 18px;
							position: relative;
							letter-spacing: 0;
						}

						span {
							font-size: fluid_tb(16, 15);
							font-family: futura-pt, sans-serif;
							font-weight: 500;
							display: block;
							font-style: normal;
							letter-spacing: .03em;
							margin-bottom: -5px;

							@include media(sp) {
								font-family: 'Poppins', sans-serif;
								font-size: 18px;
								letter-spacing: .14em;
							}
						}

						&:hover {
							color: #717D95;
						}
					}

					.mega_menu {
						position: absolute;
						left: calc(50% - 220px / 2);
						box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
						display: none;
						background: #fff;
						top: fluid_tb(90, 80);
						padding: 25px;
						min-width: 225px;
						z-index: 1;

						@include media(sp) {
							position: static;
							background: #f2f2f2;
							width: 100%;
							padding: 0;
							height: auto;
						}

						li {
							height: auto;
							line-height: 1.4em;

							&:not(:last-child) {
								margin-bottom: 15px;
							}


							a {
								text-align: left;
								font-family: 'Poppins', sans-serif;
								font-size: fluid(16, 14);

								&.no_blank::after {
									content: none;
								}

								&::after {
									content: '';
									display: inline-block;
									width: 15px;
									height: 16px;
									background-image: url("../../assets/img/common/icon_blank.png");
									background-size: contain;
									vertical-align: middle;
									background-repeat: no-repeat;
									margin: -3px 0 0 5px;
								}


								@include media(sp) {
									padding: 18px;
									border-bottom: none;
									position: relative;
								}

							}
						}
					}
				}
			}

			.icon_list {
				display: flex;
				position: absolute;
				right: fluid_tb(80, 20);
				top: 13px;

				@include media(sp) {
					right: 0;
					top: auto;
					bottom: 0;
					justify-content: space-between;
					background-color: #ECF1F5;
					width: 100%;
					height: 10%;
					align-items: center;
				}

				li {
					@include media(sp) {
						width: 50%;
						text-align: center;
						height: 100%;
						position: relative;

						&:not(:first-child)::before {
							content: '';
							position: absolute;
							top: calc(50%);
							left: 0;
							transform: translateY(-50%);
							display: block;
							width: 1px;
							height: 14px;
							background-color: #aaa;
						}
					}

					a {
						@include media(sp) {
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							text-decoration: underline;
						}
					}

					img {
						max-width: 32px;
						display: block;
						margin: 0 auto 3px;

						@include media(sp) {
							display: none;
						}
					}

					p {
						font-size: 11px;

						@include media(sp) {
							font-size: 12px;
						}
					}

					&:last-child {
						margin-left: 15px;

						@include media(sp) {
							margin-left: 0;
						}
					}
				}
			}
		}

	}
}
@use "./assets/scss/mixin"as *;

#privacy {

  .sec01 {

    .sec_ttl {
      margin-bottom: fluid(70, 30);
    }

    .box {
      background-color: #FAFAFA;
      padding: fluid(80, 30) fluid(80, 20);
      max-width: calc(1108px + 30px * 2);
      width: 100%;
      margin: 0 auto;
      h4{
        font-size: fluid(28,18);
        font-weight: 300;
        margin-bottom: 5px;
        color: #1F1F1F;
        & + p{
          margin-bottom: fluid(50,25);
        }
      }
      dl{
        &:not(:last-of-type){
          margin-bottom: fluid(40,30);
        }
        dt{
          font-size: fluid(19,16);
          margin-bottom: 10px;
          font-weight: 400;
          line-height: 1.6;
        }
        dd{
          @include media(sp) {
            line-height: 1.6;
          }
          ul{
            list-style-type: disc;
            padding-left: 25px;
          }
        }
      }
    }
  }
}
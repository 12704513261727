@use "./assets/scss/mixin"as *;

#mypage {

  .mainSection {
    margin-top: 0;
  }

  .sec01 {
    margin: fluid(120, 60) 0 fluid(100, 40);

    .sec_ttl_jp {
      text-align: left;
      font-size: fluid(19, 16);
      margin-bottom: 15px;
      letter-spacing: 0;

      .acceptance {
        border-radius: 50px;
        font-size: fluid(16, 14);
        color: #fff;
        width: auto;
        padding: 0 15px;
        background-color: #717D95;
        display: inline-block;
        margin-left: 10px;
      }
    }

    .member_head {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: fluid(80, 40);
      position: relative;

      @include media(sp) {
        display: block;
        width: 100%;
      }

      .name_area {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;

        .ship {
          max-width: 168px;
          margin-right: 25px;

          @include media(sp) {
            width: 100%;
            display: flex;
            max-width: 100%;
            flex-wrap: wrap;
            align-items: center;
            img{
              width: 96px;
              max-width: 100%;
              margin-right: 15px;
              top: 0;
            }
          }
        }


          h2 {
            font-size: fluid(32, 19);
            font-weight: 300;
            color: #1F1F1F;
            line-height: 1.6;
          }

        .name {
          @include media(sp) {
            width: 100%;
          }
          .point_area{

            @include media(sp) {
              border: 1px solid #e9e9e9;
              padding: 20px;
              margin-top: 30px;
            }
          }

          .point {
            .point_txt {
              display: inline-block;
            }
          }

          .items_point {
            color: #787878;
            font-size: fluid(13, 12);

            span {
              display: inline-block;
            }

            .pc_none {
              @include media(sp) {
                display: none;
              }
            }
          }

          .rank {
            font-size: fluid(19, 14);
            margin-top: -12px;

            @include media(sp) {
              margin-top: -8px;
            }


          }
        }
      }

      .btn {
        border: 1px solid #d3d7e0;
        position: absolute;
        right: 0;

        @include media(sp) {
          text-decoration: underline;
          background: none;
          border: none;
          color: #717D95;
          padding: 0;
          margin: 0;
          width: auto;
          font-size: 12px;
          top: 5px;
        }
      }
    }

    .member_detals {
      margin-bottom: fluid(80,40);

      .up_area,
      .under_area {
        padding: fluid(40, 30) fluid(65, 20) fluid(35, 30);
        width: 100%;

        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          border-bottom: 1px solid #d9d9d9;
          padding-bottom: 15px;
          margin-bottom: fluid(35, 25);
          @include media(sp) {
            display: block;
          }
        }

        .txt {
          font-size: fluid(19, 16);
          @include media(sp) {
            text-align: center;
            margin-bottom: 10px;
          }
        }

        .number {
          display: flex;
          align-items: center;
          @include media(sp) {
            text-align: center;
            display: block;
          }

          .period {
            display: inline-block;
            @include media(sp) {
              text-align: center;
              display: block;
            }
          }

          .point {
            font-family: 'Poppins', sans-serif;
            font-size: fluid(34, 21);
            margin-left: 30px;
            @include media(sp) {
              margin: 0;
            }
          }


          span {
            font-family: 'Poppins', sans-serif;
          }
        }

        &.pearl {
          background-color: #fdf8f6;

          .txt,
          .gauge_txt {
            color: #d6ae97;
          }

          .gauge span {
            background-color: #D6AE97;
          }
        }

        &.sapphire {
          background-color: #EBEDF4;

          .txt,
          .gauge_txt {
            color: #3c5497;
          }

          .gauge span {
            background-color: #3c5497;
          }
        }

        &.ruby {
          background-color: #FBEEF3;

          .txt,
          .gauge_txt {
            color: #d95889;
          }

          .gauge span {
            background-color: #d95889;
          }
        }

        &.emerald {
          background-color: #ECF6F6;

          .txt,
          .gauge_txt {
            color: #45a9aa;
          }

          .gauge span {
            background-color: #45a9aa;
          }
        }

        &.diamond {
          background-color: #EFF0F0;

          .txt,
          .gauge_txt {
            color: #646b71;
          }

          .gauge span {
            background-color: #646b71;
          }
        }

        &.pinkdiamond {
          background-color: #FAF2F8;

          .txt,
          .gauge_txt {
            color: #c983b6;
          }

          .gauge span {
            background-color: #c983b6;
          }
        }
      }

      .up_area {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 20px;
        @include media(sp) {
          display: block;
        }
      }

      .under_area {

        .gauge {
          height: fluid(30, 24);
          width: 90%;
          border-radius: 50px;
          background-color: #fff;
          margin: 0 auto fluid(20, 8) auto;
          @include media(sp) {
            width: 100%;
          }

          span {
            height: fluid(30, 24);
            display: block;
            width: 40%;
            border-radius: 50px;

          }
        }

        .gauge_under {
          position: relative;
          padding-bottom: 60px;
          @include media(sp) {
            padding-bottom: 0;
          }

          .price {
            position: relative;
            font-size: fluid(16, 12);

            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              width: 1px;
              height: 12px;
              background-color: #b2b2b2;
              top: - 10px;
              left: calc(50% - 1px / 2);
              background-size: contain;
              vertical-align: middle;

              @include media(sp) {
                content: none;
              }
            }

            &.price_l {
              position: absolute;
              left: 3%;

              @include media(sp) {
                margin: 0;
                left: 0;
              }
            }

            &.price_r {
              position: absolute;
              right: 3%;

              @include media(sp) {
                margin: 0;
                right: 0;
              }
            }
          }

        .gauge_txt_area{
          text-align: center;
          position:absolute;
          left: 0;
          right: 0;
          top: 5px;
          @include media(sp) {
            position: static;
            text-align: center;
            padding-top: 30px;
          }
        }
        .gauge_txt {
          font-size: fluid(13, 11);

          @include media(sp) {
            margin-bottom: 15px;
          }
        }

        .coments {
          font-size: 11px;
          color: #787878;
          text-align: center;
          width: 100%;
          display: block;
          margin-top: -5px;
          @include media(sp) {
            margin-top: -16px;
          }
        }
        }
      }
    }

    .benefits_area {
      background-color: #ECF1F5;
      padding: fluid(50, 40) fluid(50, 20);
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;

      .info_area {
        width: 46%;

        @include media(sp) {
          width: 100%;
        }

        h4 {
          color: #717D95;
          font-size: fluid(19, 19);
          font-weight: 300;
          line-height: 1.5;
          letter-spacing: .15em;
          margin-bottom: 15px;

          @include media(sp) {
            text-align: center;
            margin-bottom: 25px;
          }

        }
      }


      p {
        line-height: 1.5;
        font-size: fluid(13, 12);

        span {
          font-size: fluid(13, 12);
          color: #878A8D;
          display: block;
          margin-top: 5px;
        }
      }

      img {
        max-width: 630px;
        width: 48%;
        height: 100%;

        @include media(sp) {
          width: 100%;
          margin-bottom: 25px;
          max-width: 100%;
        }
      }
    }
  }

  .sec02 {
    background-color: #FAFAFA;
    margin-bottom: 0;

    .sec_in {
      padding: fluid_tb(100, 20) fluid_tb(120, 20);

      @include media(sp) {
        padding: 0;
      }
    }

    .tab_list {
      display: flex;

      li {
        width: 160px;
        line-height: 1;
        text-align: center;
        z-index: 3;
        color: #000;
        font-size: fluid(18, 14);
        color: #717D95;
        font-weight: 400;
        display: block;
        background-color: #fff;
        height: 64px;
        line-height: 64px;
        cursor: pointer;
        transition: .3s;

        @include media(sp) {
          width: 33.33333%;
          background: none;
        }

        &:not(:last-child) {
          margin-right: 1%;

          @include media(sp) {
            margin-right: 0;
          }
        }

        &:hover {
          background: #f2f2f2;
        }

        &.active {
          border: 1px solid #ddd;
          border-bottom: 1px solid #fff;
          background-color: #fff;
          position: relative;
          z-index: 2;
          bottom: -1px;
        }
      }
    }

    .panel-group {
      width: 100% !important;
      padding: fluid(60, 25) fluid(60, 20) 60px;
      background-color: #fff;
      position: relative;
      border: 1px solid #ddd;

      @include media(sp) {
        border-left: none;
        border-right: none;
        border-bottom: none;
        padding-bottom: 120px;
      }

      .panel {
        display: none;

        &.show {
          display: block;
          -webkit-animation: news_list_animation 1.0s cubic-bezier(0.22, 1, 0.36, 1) 0s;
          animation: news_list_animation 1.0s cubic-bezier(0.22, 1, 0.36, 1) 0s;

        }

        @-webkit-keyframes news_list_animation {
          0% {
            opacity: 0;
            bottom: -20px;
          }

          100% {
            opacity: 1;
            bottom: 0;
          }
        }

        @keyframes news_list_animation {
          0% {
            opacity: 0;
            bottom: -20px;
          }

          100% {
            opacity: 1;
            bottom: 0;
          }
        }

        .rank_list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          &::after {
            content: '';
            display: block;
            width: 31%;
            height: 0;
          }

          li {
            width: 31.5%;
            background-color: #F9F9F9;
            border: 1px solid #e9e9e9;
            height: fluid(88, 60);
            padding: 0 20px;

            &:nth-child(n+4) {
              margin-top: fluid(35, 20);

              @include media(sp) {
                margin-top: 0;
              }
            }

            p {
              height: 100%;
              font-size: fluid(16, 12);
              display: flex;
              align-items: center;
              justify-content: center;

              @include media(sp) {
                justify-content: flex-start;
              }
            }

            @include media(sp) {
              width: 48.5%;

              &:nth-child(n+3) {
                margin-top: 25px;
              }
            }

            @media only screen and (max-width: 767px) {
              width: 100%;

              &:nth-child(n+3),
              &:nth-child(n+4) {
                margin-top: 0;
              }

              &:not(:last-child) {
                margin-bottom: 20px;
              }
            }

            &.no_active {
              p {
                opacity: .2;
              }
            }
          }
        }

        .sec_ttl_jp {
          margin-bottom: 10px;
          padding-top: 40px;

          &+p {
            text-align: center;
            margin-bottom: 80px;
          }
        }

        .box {
          &:not(:last-of-type) {
            .a_area {
              margin-bottom: fluid(120, 40);
            }
          }
        }

        .exclusive_list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: fluid(65, 40);

          @include media(sp) {
            overflow-x: auto;
            flex-wrap: nowrap;
            justify-content: flex-start;
          }

          &:after {
            content: '';
            display: block;
            width: 31.5%;
            height: 0;
          }

          .item {
            width: 31.5%;

            @include media(sp) {
              flex-shrink: 0;
              width: 41.5%;

              &:not(:last-of-type) {
                margin-right: 5%;
              }
            }

            p {
              font-size: fluid(19, 14);

              span {
                font-size: fluid(16, 12);
                font-weight: 300;
              }
            }

            .p_ttl {
              margin-top: 20px;
              line-height: 1.6;

              &.link_blank {
                &::after {
                  content: '';
                  display: inline-block;
                  width: 22px;
                  height: 23px;
                  background-image: url("../../assets/img/common/icon_blank.png");
                  background-size: contain;
                  vertical-align: middle;
                  background-repeat: no-repeat;
                  margin: -3px 0 0 7px;
                }
              }
            }

            .p_price {
              font-weight: 600;
              font-family: "Poppins", sans-serif;
            }

            .e_price {
              font-size: fluid(16, 12);
              color: #888c8e;
            }

            a {
              &:hover {
                opacity: .7;
                text-decoration: underline;
              }
            }

            &:nth-of-type(n+4) {
              margin-top: fluid(40, 20);

              @include media(sp) {
                margin-top: 0;
              }
            }
          }
        }

        .a_area {
          background-color: #f7f0f2;
          padding: 60px fluid(60, 20) fluid(60, 30);

          &:not(:last-of-type) {
            margin-bottom: 100px;
          }

          .coments {
            position: relative;
            padding: 0 fluid(92, 0);
            margin: 0 auto fluid(50, 20);

            &::before,
            &::after {
              content: '';
              display: inline-block;
              position: absolute;
              width: 43px;
              height: 38px;
              top: 0;
              left: 0;
              background-size: contain;
              vertical-align: middle;
              background: url("../../assets/img/top/txt_obj2.svg") no-repeat center;
              background-size: cover;

              @include media(sp) {
                width: 24px;
                height: 21px;
                top: -35px;
              }
            }

            &::after {
              transform: rotate(-180deg);
              top: auto;
              left: auto;
              right: 0;
              bottom: 0;

              @include media(sp) {
                content: none;
              }
            }
          }

          .a_profile {
            display: flex;
            align-items: center;

            img {
              width: fluid(72, 40);
              margin-right: 20px;
              border-radius: 50%;

              @include media(sp) {
                margin-right: 3%;
              }
            }

            .p_area {
              .name {
                font-weight: 400;
                font-size: fluid(19, 14);
                line-height: 1.6;
              }

              .title {
                font-size: fluid(16, 12);
              }
            }
          }
        }

        dl {
          background-color: #F9F9F9;
          border: 1px solid #d4d4d4;
          padding: fluid(26, 16) 25px 0px fluid(25, 18);

          &:not(:last-of-type) {
            margin-bottom: fluid(22, 18)
          }

          dt {
            display: flex;
            cursor: pointer;
            justify-content: space-between;
            flex-wrap: wrap;
            position: relative;
            padding: 0 fluid(70, 30) fluid(22, 14) 0;
            font-size: fluid(19, 14);

            &::after {
              content: '';
              display: inline-block;
              position: absolute;
              width: 14px;
              height: 14px;
              top: 18%;
              right: 0;
              background-image: url("../../assets/img/member/icon_open.svg");
              background-size: contain;
              vertical-align: middle;
            }

            &.active {
              &::after {
                background-image: url("../../assets/img/member/icon_close.svg");
              }
            }
          }


          dd {
            border-top: 1px solid #ddd;
            padding: fluid(30, 20) 0 fluid(30, 20);
            display: none;

            .month {
              &:not(:last-of-type) {
                margin-bottom: fluid(40, 25);
              }

              .m_txt {
                font-size: fluid(19, 14);
                margin-bottom: 20px;
              }

              ul {
                li {
                  display: flex;
                  align-items: center;

                  &:not(:last-child) {
                    margin-bottom: 20px;
                  }

                  img {
                    width: fluid(110, 55);
                    margin-right: fluid(30, 20);
                  }
                }
              }
            }
          }
        }

        .history_txt {
          text-align: center;
          margin-top: 45px;
          font-size: fluid(19, 14);
          color: #1F1F1F;
        }
      }
    }
  }

  .topic_path_area {
    background-color: #fafafa;

    @include media(sp) {
      background-color: #fff;
    }
  }
}
@use "./assets/scss/mixin"as *;

#info {
  .sec01 {
    .sec_in {

      .info_list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &:before,
        &::after {
          content: '';
          display: block;
          width: 23%;
          height: 0;
          @include media(sp) {
            width: 31.5%;
          }
        }

        &:before {
          order: 1;
        }

        

        li {
          width: 23%;
          position: relative;

          @include media(sp) {
            width: 31.5%;
            &:nth-child(n+4) {
               margin-top: 30px;
                @media only screen and (max-width: 767px){
                  margin-top: 0;
                }
            }
          }

          @media only screen and (max-width: 767px){
            width: 100%;

            &:not(:last-child) {
              margin-bottom: 30px;
            }
          }

          a{
          &:hover {
            opacity: .7;
            text-decoration: underline;
          }
          }
          figure{
            position: relative;
          img{
            height: auto;
          }
        }

          &:nth-child(n+5) {
            margin-top: 35px;

            @include media(sp) {
              margin-top: 30px;
              @media only screen and (max-width: 767px){
                margin-top: 0;
              }
            }
          }

          .d_head {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            @include media(sp) {
              margin: 13px 0 7px;
            }
          }

          time {
            margin: 10px 5px 0 0;
            display: block;
            font-family: 'Poppins', sans-serif;

            @include media(sp) {
              margin: 0 8px 0 0;
            }
          }

          .cat {
            position: absolute;
            left: 10px;
            display: block;
            background-color: #fff;
            bottom:fluid(10,7);
            height: fluid(32, 24);
            line-height: fluid(33, 22);
            letter-spacing: .01em;
            text-align: center;
            padding: 0 10px;
            font-size: fluid(13, 11);

            @include media(sp) {
              position: static;
              border: 1px solid #e9ebee;
              margin-top: -2px;
            }
          }

          h3 {
            font-size: fluid(16, 14);
            font-weight: 300;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            line-height: 1.6;
          }
        }
      }
    }
  }
  &.info_single{
    .mainSection{
      @include media(sp) {
        margin-top: 0;
      }
    }
  }
  .info_d_sec {
    .sec_in {
      max-width: calc(1108px + 30px * 2);
      width: 100%;
      margin: 0 auto;
      padding: 0 fluid(30, 20);
    }
    img{
      height: auto;
      width: auto;
      @include media(sp) {
        width: 100%;
      }
    }
    figure{
      margin-bottom: 30px;
      @include media(sp) {
        margin: 0 calc(50% - 50vw) 20px;
        width: 100vw;
      }
      img{
        height: auto;
        width: 100%;
      }
    }

    .info_ttl {
      font-size: fluid(32, 19);
      letter-spacing: .16em;
      font-weight: 300;
      color: #1F1F1F;
      line-height: 1.8;
      margin-bottom: fluid(30,20);
      @include media(sp) {
        line-height: 1.6;
        margin-top: 80px;
      }
    }

    figure + .info_ttl {
      @include media(sp) {
        margin-top: 0;
      }
    }

    p {
      line-height: 2.1;

      &+p {
        margin-top: fluid(35, 30);
      }

    a{
      color: #717D95;
      text-decoration: underline;
      display: inline-block;
      span{
        color: #717D95 !important;
      }
      &:hover{
        text-decoration: none;
      }
    }
    }
  }
}
@use "./assets/scss/mixin"as *;

#faq {

  .sec01 {
    .sec_in{
      max-width: calc(1105px + 120px * 2);
	    width: 100%;
	    margin: 0 auto;
    }

    .tab_list {
      display: flex;
      margin-bottom: 60px;
      flex-wrap: wrap;
      @media only screen and (max-width: 768px){
        overflow-x: auto;
        flex-wrap: nowrap;
        justify-content: flex-start;
        -webkit-overflow-scrolling: touch;
        margin: 0 calc(50% - 50vw) 20px;
        width: 100vw;
        max-width: auto;
        max-width: initial;
      }

      & > div {
        width: 24.85%;
        line-height: 1;
        text-align: center;
        z-index: 3;
        color: #1F1F1F;
        font-size: fluid(18, 14);
        font-weight: 300;
        display: block;
        background-color: #FAFAFA;
        height: 64px;
        line-height: 64px;
        cursor: pointer;
        transition: .3s;
        margin: 0 .2% .2% 0;

        @media only screen and (max-width: 768px){
          width:30%;
          flex-shrink: 0;
          height: auto;
          line-height: 1.6;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:hover {
          background: #f2f2f2;
        }

        &.current-cat, &:first-child{
          background-color: #717D95;
          position: relative;
          color: #fff;
        }
        &:nth-child(4), &:nth-child(8), &:nth-child(12){
          margin-right: 0;
        }
        
      }
    }

    .panel-group {
      position: relative;

      .panel {
        display: none;

        &.show {
          display: block;
          -webkit-animation: news_list_animation 1.0s cubic-bezier(0.22, 1, 0.36, 1) 0s;
          animation: news_list_animation 1.0s cubic-bezier(0.22, 1, 0.36, 1) 0s;

        }

        @-webkit-keyframes news_list_animation {
          0% {
            opacity: 0;
            bottom: -20px;
          }

          100% {
            opacity: 1;
            bottom: 0;
          }
        }

        @keyframes news_list_animation {
          0% {
            opacity: 0;
            bottom: -20px;
          }

          100% {
            opacity: 1;
            bottom: 0;
          }
        }
        dl {
          background-color: #F9F9F9;
          border: 1px solid #e9e9e9;
          padding: 0 fluid(25,18) 0px fluid(25,18);

          &:not(:last-of-type) {
            margin-bottom: fluid(30,18)
          }

          dt {
            display: flex;
            cursor: pointer;
            justify-content: space-between;
            flex-wrap: wrap;
            position: relative;
            padding: fluid(24,16) fluid(70,30) fluid(25,14) fluid(35,25);
            font-size: fluid(19, 14);
            position: relative;
            @include media(sp) {
              line-height: 1.6;
            }

            &::before {
              content: 'Q .';
              display: inline-block;
              position: absolute;
              font-size: fluid(19,16);
              top:  fluid(22,13);
              left: 0;
              background-size: contain;
              vertical-align: middle;
            }

            &::after {
              content: '';
              display: inline-block;
              position: absolute;
              width: fluid(20,12);
              height: fluid(20,12);
              top: calc(50% - fluid(20,12) / 2);
              right: 0;
              background-image: url("../../assets/img/member/icon_open.svg");
              background-size: contain;
              vertical-align: middle;
            }

            &.active {
              &::after {
                background-image: url("../../assets/img/member/icon_close.svg");
              }
            }
          }


          dd {
            border-top: 1px solid #ddd;
            padding: fluid(30, 20) 0 fluid(30, 20);
            display: none;
            a{
              color: #717D95;
              text-decoration: underline;
              display: inline-block;
              &:hover{
                text-decoration: none;
              }
            }
          }
        }
      }
    }
    .contact_link_txt{
      text-align: center;
      margin-top: fluid(60,40);
      a{
        color: #717d95;
        text-decoration: underline;
        display: inline-block;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  &.faq_tax{
    .tab_list {

      li {
        &:first-child{
          background-color: #fafafa;
          color: #1f1f1f;
        }
        &.current-cat{
          background-color: #717D95;
          position: relative;
          color: #fff;
        }
        
      }
    }

  }
}
@use "sass:math";
@use "sass:map";

//----------------------------
//	breakpoints
//----------------------------
$breakpoints: (
  sp:"screen and (max-width: 1024px)",
  pc:"screen and (min-width: 1100px)",

  //fluidへの代入値
  f_sp: 375px,
  f_tb: 768px,
  f_pc: 1400px,
);

@mixin media($breakpoint) {
  @media only #{map.get($breakpoints, $breakpoint)} {
    @content;
  }
}

//@media screen and (max-width: 767px)

//----------------------------
//	fluid
//----------------------------
@function fluid($fontsize-l,$fontsize) {
  @return clamp( #{$fontsize * 1px}, calc( #{$fontsize * 1px} + (( 100vw - #{map.get($breakpoints, f_tb)} ) * (( #{$fontsize-l} - #{$fontsize} ) / ( #{num(map.get($breakpoints, f_pc))} - #{num(map.get($breakpoints, f_tb))} )))), #{$fontsize-l * 1px} );
}

@function num($input){
  @return math.div( $input, ($input * 0 + 1) );
}

//----------------------------
//	fluid2
//----------------------------
@function fluid_tb($fontsize-l,$fontsize) {
  @return clamp( #{$fontsize * 1px}, calc( #{$fontsize * 1px} + (( 100vw - #{map.get($breakpoints, f_tb)} ) * (( #{$fontsize-l} - #{$fontsize} ) / ( #{num(map.get($breakpoints, f_pc))} - #{num(map.get($breakpoints, f_tb))} )))), #{$fontsize-l * 1px} );
}

@function num($input){
  @return math.div( $input, ($input * 0 + 1) );
}

//----------------------------
//	font-size(vw)
//----------------------------
@function get_vw($size, $viewport:375){
  $rate: math.div(100, $viewport);
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size:10){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

// @include fz_vw(13);

//----------------------------
//	font-family
//----------------------------
$font-sans-serif: "Noto Sans JPe","ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;

//----------------------------
//	center
//----------------------------
@mixin center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	text-align: center;
}

//----------------------------
//	full-width
//----------------------------
@mixin full-width(){
  margin-left: calc((100vw - 100%) / -2);
  margin-right: calc((100vw - 100%) / -2);
  max-width: auto; // IE対策
  max-width: initial;
}

@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100&family=Roboto:wght@300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300&display=swap");
/*--------------------------------------------------------------
	reset
---------------------------------------------------------------*/
* {
  box-sizing: border-box;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
input,
button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}

sup {
  vertical-align: super;
}

sub {
  vertical-align: sub;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ul,
ol {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  transition: 0.2s;
}

img {
  vertical-align: bottom;
  border: none;
  width: 100%;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

body {
  color: #1F1F1F;
  line-height: 2;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", sans-serif;
  word-break: break-word;
  width: 100%;
  font-weight: 300;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 16 - 14 ) / ( 1400 - 768 )))), 16px );
}
@media only screen and (max-width: 1024px) {
  body p {
    line-height: 1.6;
  }
}
body.fixed {
  overflow: hidden;
}

.container {
  overflow: hidden;
  position: relative;
  margin-top: 68px;
}
@media only screen and (max-width: 1024px) {
  .container {
    margin-top: 58px;
  }
}

.mainSection {
  position: relative;
  margin-top: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 120 - 60 ) / ( 1400 - 768 )))), 120px );
}
.mainSection.fixed {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.modaal_overlay {
  transition: 0.3s;
}
.modaal_overlay.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.no_link {
  pointer-events: none;
}

.pc {
  display: block !important;
}
@media only screen and (max-width: 1024px) {
  .pc {
    display: none !important;
  }
}

.sp {
  display: none !important;
}
@media only screen and (max-width: 1024px) {
  .sp {
    display: block !important;
  }
}

.btn_area_c {
  text-align: center;
}

.btn_area {
  text-align: left;
}

.btn {
  display: inline-block;
  width: clamp( 170px, calc( 170px + (( 100vw - 768px ) * (( 190 - 170 ) / ( 1400 - 768 )))), 190px );
  text-align: center;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
  background-color: #fff;
  color: #717D95;
  height: clamp( 50px, calc( 50px + (( 100vw - 768px ) * (( 64 - 50 ) / ( 1400 - 768 )))), 64px );
  line-height: clamp( 49px, calc( 49px + (( 100vw - 768px ) * (( 62 - 49 ) / ( 1400 - 768 )))), 62px );
  font-weight: 500;
  font-size: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 18 - 15 ) / ( 1400 - 768 )))), 18px );
}
@media only screen and (max-width: 1024px) {
  .btn {
    display: block;
    margin: 0 auto;
    justify-content: center;
  }
}
.btn:hover {
  background-color: #717D95;
  color: #fff;
}
.btn.iv_btn {
  background-color: #717D95;
  color: #fff;
  border: 1px solid #717D95;
}
.btn.iv_btn:hover {
  background-color: #fff;
  color: #717D95;
}
.btn.border {
  border: 1px solid #d3d7e0;
}
section {
  margin: 0 auto clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 120 - 60 ) / ( 1400 - 768 )))), 120px );
}
section.last {
  margin-bottom: 0;
}

.sec_in {
  max-width: 1680px;
  width: 100%;
  margin: 0 auto;
  padding: 0 clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 120 - 20 ) / ( 1400 - 768 )))), 120px );
}
.sec_in.sec_in_bg {
  padding: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 120 - 60 ) / ( 1400 - 768 )))), 120px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 120 - 20 ) / ( 1400 - 768 )))), 120px );
}
@media only screen and (max-width: 1024px) {
  .sec_in.sec_in_bg {
    padding: 60px 20px;
  }
}

.topic_path_area {
  border-top: 1px solid #e9e9e9;
}
@media only screen and (max-width: 1024px) {
  .topic_path_area {
    border: none;
  }
}

.topic_path {
  display: flex;
  margin: 0 auto;
  max-width: 1500px;
  width: 100%;
  padding: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 20 - 15 ) / ( 1400 - 768 )))), 20px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
}
@media only screen and (max-width: 1024px) {
  .topic_path {
    max-width: inherit;
  }
}
.topic_path li {
  margin-right: 10px;
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 13 - 12 ) / ( 1400 - 768 )))), 13px );
  padding-left: 15px;
  box-sizing: border-box;
  position: relative;
}
.topic_path li:before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: rotate(45deg);
  position: absolute;
  top: 10px;
  left: 0;
}
.topic_path li:first-child {
  padding-left: 0;
}
.topic_path li:first-child:before {
  display: none;
}
.topic_path li a {
  display: block;
}
.topic_path li a:hover {
  text-decoration: underline;
}

.disc {
  margin-left: 20px;
}
.disc li {
  list-style-type: disc;
  text-align: left;
}
.disc li:not(:last-child) {
  margin-bottom: 15px;
}

.inview {
  opacity: 0;
  position: relative;
}
.inview.animate {
  -webkit-animation: inview_slide_up 1.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards 0.2s;
  animation: inview_slide_up 1.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards 0.2s;
}

.inview-group .inview-list {
  opacity: 0;
  position: relative;
}
.inview-group .inview-list.animate {
  -webkit-animation: inview_slide_up 0.7s cubic-bezier(0.4, 0, 0.2, 1) forwards 0.2s;
  animation: inview_slide_up 0.7s cubic-bezier(0.4, 0, 0.2, 1) forwards 0.2s;
}

@-webkit-keyframes inview_slide_up {
  0% {
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes inview_slide_up {
  0% {
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media only screen and (min-width: 1100px) {
  a[href*="tel:"] {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
}
.main_u_v {
  margin: 90px 0 20px;
  height: 300px;
  position: relative;
  background: url("https://placehold.jp/1500x350.png") no-repeat center;
  background-size: cover;
}
@media only screen and (max-width: 1024px) {
  .main_u_v {
    background: url("https://placehold.jp/760x350.png") no-repeat center;
    background-size: cover;
    margin: 60px 0 20px;
    padding-bottom: 52%;
    height: auto;
  }
}
.main_u_v .main_u_v_in {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: #fff;
}
.main_u_v .main_u_v_in h2 {
  font-size: clamp( 32px, calc( 32px + (( 100vw - 768px ) * (( 26 - 32 ) / ( 1400 - 768 )))), 26px );
  letter-spacing: 0.05em;
  line-height: 1.4;
}

.sec_ttl {
  font-size: clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 44 - 25 ) / ( 1400 - 768 )))), 44px );
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
  text-align: center;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-family: "Poppins", sans-serif;
}
.sec_ttl.sec_ttl_u {
  margin-bottom: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 120 - 60 ) / ( 1400 - 768 )))), 120px );
}
.sec_ttl span {
  display: block;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
  font-family: fot-tsukumin-pr6n, sans-serif;
  letter-spacing: 0.07em;
  font-weight: 100;
  margin-top: -15px;
}
@media only screen and (max-width: 1024px) {
  .sec_ttl span {
    margin-top: -11px;
  }
}
.sec_ttl + p {
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 60 - 30 ) / ( 1400 - 768 )))), 60px );
  text-align: center;
}
@media only screen and (max-width: 1024px) {
  .sec_ttl + p {
    padding: 0 20px;
  }
}

.sec_ttl_jp {
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 32 - 19 ) / ( 1400 - 768 )))), 32px );
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 100 - 30 ) / ( 1400 - 768 )))), 100px );
  text-align: center;
  font-weight: 300;
  color: #1F1F1F;
  letter-spacing: 0.1em;
}

.txt_link {
  text-decoration: underline;
  display: inline-block;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 16 - 14 ) / ( 1400 - 768 )))), 16px );
}
.txt_link:hover {
  text-decoration: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=submit] {
  -webkit-appearance: none;
  border-radius: 0;
}

textarea {
  resize: none;
  width: auto;
  height: auto;
}

input[type=text], input[type=email], input[type=tel], input[type=number], input[type=password], input[name=zip] {
  width: 100%;
  height: 56px;
  line-height: 56px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 1em;
  border-radius: 0;
  outline: none;
  font-size: 16px;
}
@media only screen and (max-width: 1024px) {
  input[type=text], input[type=email], input[type=tel], input[type=number], input[type=password], input[name=zip] {
    padding: 0 0.7em;
  }
}
input[type=checkbox] {
  display: none;
}
input[type=checkbox]:checked + .mwform-checkbox-field-text::after {
  opacity: 1;
}
input[type=checkbox]:checked + .mwform-checkbox-field-text::before {
  background-color: #717d95;
}
input::placeholder {
  color: #c4c5c6;
  font-size: clamp( 13px, calc( 13px + (( 100vw - 768px ) * (( 16 - 13 ) / ( 1400 - 768 )))), 16px );
}
input.error_box {
  background-color: #f7f0f2;
  border: 1px solid #e05a5a;
}

textarea {
  width: 100%;
  height: 160px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  line-height: 1.4em;
  padding: 1em;
  border-radius: 0;
  font-size: 16px;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", sans-serif;
  outline: none;
}
textarea::placeholder {
  color: #c4c5c6;
  font-size: clamp( 13px, calc( 13px + (( 100vw - 768px ) * (( 16 - 13 ) / ( 1400 - 768 )))), 16px );
}
textarea.error_box {
  background-color: #f7f0f2;
  border: 1px solid #e05a5a;
}

.warning.hidden {
  display: none;
}

.warning, .modal_warning {
  background-color: #f7f0f2;
  font-weight: 400;
  color: #e05a5a;
  padding: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 20 - 15 ) / ( 1400 - 768 )))), 20px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 25 - 20 ) / ( 1400 - 768 )))), 25px );
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 60 - 30 ) / ( 1400 - 768 )))), 60px );
}

.error, .modal_error {
  color: #e05a5a;
  font-weight: 400;
  width: 100%;
}

.mwform-checkbox-field-text {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding-left: 35px;
  position: relative;
  width: auto;
  font-weight: 400;
}
.mwform-checkbox-field-text::before {
  background: #fff;
  border: 1px solid #e0e0e0;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  left: 0;
  position: absolute;
  top: clamp( 1px, calc( 1px + (( 100vw - 768px ) * (( 5 - 1 ) / ( 1400 - 768 )))), 5px );
}
.mwform-checkbox-field-text::after {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  content: "";
  display: block;
  height: 9px;
  left: 7.5px;
  margin-top: -8px;
  opacity: 0;
  position: absolute;
  top: clamp( 13px, calc( 13px + (( 100vw - 768px ) * (( 17 - 13 ) / ( 1400 - 768 )))), 17px );
  transform: rotate(45deg);
  width: 5px;
}

.post,
.input_p_number {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 768px) {
  .post,
.input_p_number {
    justify-content: space-between;
  }
}
.post .btn,
.input_p_number .btn {
  border: 1px solid #717D95;
  height: clamp( 50px, calc( 50px + (( 100vw - 768px ) * (( 54 - 50 ) / ( 1400 - 768 )))), 54px );
  line-height: clamp( 50px, calc( 50px + (( 100vw - 768px ) * (( 51 - 50 ) / ( 1400 - 768 )))), 51px );
  padding: 0 28px;
  width: 170px;
  margin-left: 15px;
  margin-top: 0 !important;
}
@media only screen and (max-width: 768px) {
  .post .btn,
.input_p_number .btn {
    width: 100%;
    margin: 15px 0 0 !important;
  }
}
.post input[type=text],
.post input[type=tel],
.post input[type=number],
.input_p_number input[type=text],
.input_p_number input[type=tel],
.input_p_number input[type=number] {
  width: 140px;
}
@media only screen and (max-width: 768px) {
  .post input[type=text],
.post input[type=tel],
.post input[type=number],
.input_p_number input[type=text],
.input_p_number input[type=tel],
.input_p_number input[type=number] {
    width: 43.5%;
  }
}

@media only screen and (max-width: 1024px) {
  .input_p_number {
    flex-wrap: nowrap;
  }
  .input_p_number span {
    display: block;
    margin: 0 5px;
    line-height: 1;
  }
}

.radio .radio-label {
  position: relative;
  display: block;
  font-weight: 400;
  padding-left: 32px;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 16 - 14 ) / ( 1400 - 768 )))), 16px );
}
@media only screen and (max-width: 1024px) {
  .radio .radio-label {
    padding-left: 27px;
  }
}
.radio:not(:last-of-type) {
  margin-right: 35px;
}
.radio input[type=radio] {
  position: absolute;
  opacity: 0;
}
.radio input[type=radio] + .radio-label:before {
  content: "";
  background: #fff;
  border-radius: 100%;
  border: 1px solid #e0e0e0;
  display: block;
  width: 21px;
  height: 21px;
  position: absolute;
  left: 0;
  top: 5px;
  margin-right: 8px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
@media only screen and (max-width: 1024px) {
  .radio input[type=radio] + .radio-label:before {
    width: 17px;
    height: 17px;
  }
}
.radio input[type=radio]:checked + .radio-label:before {
  background-color: #63666a;
  box-shadow: inset 0 0 0 4px #fff;
}
.radio input[type=radio]:focus + .radio-label:before {
  outline: none;
  border-color: #63666a;
}
.radio input[type=radio]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #fff;
  border-color: #bfbfbf;
  background: #bfbfbf;
}
.radio input[type=radio] + .radio-label:empty:before {
  margin-right: 0;
}

.modaal-close {
  position: absolute;
  right: 30px;
  width: 30px;
  height: 30px;
  top: 30px;
}
@media only screen and (max-width: 1024px) {
  .modaal-close {
    right: 15px;
    top: 15px;
  }
}
.modaal-close:before, .modaal-close:after {
  background: #1f1f1f;
  width: 2px;
  border-radius: 0;
  height: 25px;
  left: 15px;
  top: 4px;
}
@media only screen and (max-width: 1024px) {
  .modaal-close:before, .modaal-close:after {
    height: 20px;
  }
}
.modaal-close:hover {
  background: none;
  opacity: 0.6;
}
.modaal-close:hover:before, .modaal-close:hover:after {
  background: #1f1f1f;
}

.follow_sns_area {
  display: none;
}
.follow_sns_area .follow_sns {
  background-color: #fff;
  padding: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 38 - 20 ) / ( 1400 - 768 )))), 38px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 38 - 20 ) / ( 1400 - 768 )))), 38px );
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}
@media only screen and (max-width: 1024px) {
  .follow_sns_area .follow_sns {
    width: 100%;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
.follow_sns_area .follow_sns p.ttl {
  font-family: "Poppins", sans-serif;
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 18 - 16 ) / ( 1400 - 768 )))), 18px );
}
.follow_sns_area .follow_sns p.txt {
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px );
  margin: -5px 0 10px;
  line-height: 1.4;
}
@media only screen and (max-width: 1024px) {
  .follow_sns_area .follow_sns p.txt {
    margin: 0;
  }
}
.follow_sns_area .follow_sns ul {
  display: flex;
}
.follow_sns_area .follow_sns ul li {
  width: clamp( 27px, calc( 27px + (( 100vw - 768px ) * (( 30 - 27 ) / ( 1400 - 768 )))), 30px );
}
.follow_sns_area .follow_sns ul li:not(:last-child) {
  margin-right: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 20 - 15 ) / ( 1400 - 768 )))), 20px );
}
.follow_sns_area .follow_sns ul li a:hover {
  opacity: 0.6;
}
.follow_sns_area .follow_sns .close_btn {
  position: absolute;
  right: 15px;
  width: 25px;
  cursor: pointer;
  height: 25px;
  top: 15px;
}
@media only screen and (max-width: 1024px) {
  .follow_sns_area .follow_sns .close_btn {
    right: 15px;
    top: 15px;
  }
}
.follow_sns_area .follow_sns .close_btn:before, .follow_sns_area .follow_sns .close_btn:after {
  background: #1f1f1f;
  width: 1px;
  border-radius: 0;
  height: 20px;
  content: "";
  left: 12px;
  position: absolute;
  top: 4px;
}
@media only screen and (max-width: 1024px) {
  .follow_sns_area .follow_sns .close_btn:before, .follow_sns_area .follow_sns .close_btn:after {
    height: 20px;
  }
}
.follow_sns_area .follow_sns .close_btn:before {
  transform: rotate(-45deg);
}
.follow_sns_area .follow_sns .close_btn:after {
  transform: rotate(45deg);
}
.follow_sns_area .follow_sns .close_btn:hover {
  background: none;
  opacity: 0.6;
}
.follow_sns_area .follow_sns .close_btn:hover:before, .follow_sns_area .follow_sns .close_btn:hover:after {
  background: #1f1f1f;
}

.change_s {
  height: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 84 - 60 ) / ( 1400 - 768 )))), 84px );
  background-color: #ECF1F5;
  color: #717D95;
  text-align: center;
}
.change_s p {
  line-height: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 84 - 60 ) / ( 1400 - 768 )))), 84px );
}

.pagination ul {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
.pagination ul li span,
.pagination ul a {
  display: inline-block;
  color: #717d95;
  background-color: #fff;
  text-decoration: none;
  text-align: center;
  line-height: clamp( 28px, calc( 28px + (( 100vw - 768px ) * (( 42 - 28 ) / ( 1400 - 768 )))), 42px );
  width: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 44 - 30 ) / ( 1400 - 768 )))), 44px );
  height: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 44 - 30 ) / ( 1400 - 768 )))), 44px );
  margin: 0 clamp( 3px, calc( 3px + (( 100vw - 768px ) * (( 10 - 3 ) / ( 1400 - 768 )))), 10px );
  transition: 0.2s ease-in-out;
  white-space: nowrap;
  border: 1px solid #717d95;
}
.pagination ul li span:hover, .pagination ul li span.current,
.pagination ul a:hover,
.pagination ul a.current {
  background: #717d95;
  border: 1px solid #717d95;
  color: #fff;
}

.modaal-overlay {
  display: block;
}

.gt_switcher_wrapper {
  top: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px ) !important;
  bottom: auto !important;
  right: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 28 - 14 ) / ( 1400 - 768 )))), 28px ) !important;
  left: auto !important;
  position: fixed !important;
  width: 34px;
  height: 34px;
  cursor: pointer;
  transition: 0.3s;
  z-index: 999 !important;
}
.gt_switcher_wrapper::before {
  content: "";
  display: inline-block;
  transition: 0.3s;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  background-image: url("../../assets/img/common/icon_lang.png");
  background-size: contain;
  vertical-align: middle;
  background-color: #fff;
}

.gt_selector {
  position: absolute;
  inset: 0;
  margin: auto;
  width: 32px;
  height: 32px;
  border: none;
}

/* 特定スタイル */
#TOP .gt_switcher_wrapper {
  top: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 24 - 12 ) / ( 1400 - 768 )))), 24px ) !important;
}
#TOP .gt_switcher_wrapper::before {
  background-color: #dfe8ee;
}
#TOP .gt_switcher_wrapper.active {
  top: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px ) !important;
}
#TOP .gt_switcher_wrapper.active::before {
  background-color: #fff;
}

#TOP .gt_switcher_wrapper .gt_switcher_wrapper::before {
  background-color: #fff;
}

.header {
  width: 100%;
  top: 0;
  z-index: 999;
  position: fixed !important;
}
@media only screen and (max-width: 1024px) {
  .header {
    background: #fff;
  }
}
.header .header-nav_sp {
  display: none;
  width: 50%;
  position: fixed;
  display: none;
  border: none;
  z-index: -1;
  height: 100vh;
  background: #fff;
  padding: clamp( 160px, calc( 160px + (( 100vw - 768px ) * (( 200 - 160 ) / ( 1400 - 768 )))), 200px ) 0 clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 60 - 20 ) / ( 1400 - 768 )))), 60px ) clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 120 - 60 ) / ( 1400 - 768 )))), 120px );
  top: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}
@media only screen and (max-height: 730px) {
  .header .header-nav_sp {
    padding-top: 160px;
  }
}
@media only screen and (max-width: 1024px) {
  .header .header-nav_sp {
    padding: 36px 0 10% 10%;
    width: 100%;
    top: 58px;
    max-height: calc(100vh - 46px);
    transition-duration: 0.3s;
    transition-property: max-height;
  }
}
.header .header-nav_sp::after {
  content: "";
  display: inline-block;
  position: fixed;
  width: clamp( 114px, calc( 114px + (( 100vw - 768px ) * (( 223 - 114 ) / ( 1400 - 768 )))), 223px );
  height: clamp( 121px, calc( 121px + (( 100vw - 768px ) * (( 224 - 121 ) / ( 1400 - 768 )))), 224px );
  bottom: 3%;
  right: 51.5%;
  background-image: url("../../assets/img/common/header_bg.jpg");
  background-size: clamp( 114px, calc( 114px + (( 100vw - 768px ) * (( 223 - 114 ) / ( 1400 - 768 )))), 223px ), clamp( 121px, calc( 121px + (( 100vw - 768px ) * (( 224 - 121 ) / ( 1400 - 768 )))), 224px );
  vertical-align: middle;
  background-repeat: no-repeat;
  transition: 0.3s;
}
@media only screen and (max-width: 1024px) {
  .header .header-nav_sp::after {
    z-index: -1;
    right: 15px;
  }
}
.header .header-nav_sp .nav_list {
  display: block;
  margin-bottom: 110px;
  position: relative;
}
@media only screen and (max-height: 730px) {
  .header .header-nav_sp .nav_list {
    margin-bottom: 55px;
  }
}
.header .header-nav_sp .nav_list::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 60%;
  background-color: #e9e9e9;
  bottom: -55px;
}
@media only screen and (max-height: 730px) {
  .header .header-nav_sp .nav_list::after {
    bottom: -25px;
  }
}
.header .header-nav_sp .nav_list > li {
  height: auto;
  display: block;
}
.header .header-nav_sp .nav_list > li:not(:last-child) {
  padding-bottom: 22px;
}
@media only screen and (max-height: 730px) {
  .header .header-nav_sp .nav_list > li:not(:last-child) {
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 1024px) {
  .header .header-nav_sp .nav_list > li:not(:last-child) {
    padding-bottom: 20px;
  }
}
.header .header-nav_sp .nav_list > li a {
  position: relative;
  flex-wrap: wrap;
  letter-spacing: 0;
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 13 - 12 ) / ( 1400 - 768 )))), 13px );
  display: flex;
  align-items: center;
}
.header .header-nav_sp .nav_list > li a span {
  font-family: "Poppins", sans-serif;
  font-size: clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 22 - 18 ) / ( 1400 - 768 )))), 22px );
  display: block;
  margin-bottom: 0;
  letter-spacing: 0.14em;
  padding-right: 15px;
  margin-right: 10px;
  position: relative;
}
.header .header-nav_sp .nav_list > li a span::after {
  content: "";
  width: 1px;
  height: 15px;
  background-color: #1f1f1f;
  position: absolute;
  top: calc(50% - 7.5px);
  right: 0px;
}
@media only screen and (max-width: 1024px) {
  .header .header-nav_sp .nav_list > li a span {
    width: 100%;
    line-height: 1.2;
    padding-right: 0;
    margin-right: 0;
  }
  .header .header-nav_sp .nav_list > li a span::after {
    content: none;
  }
}
.header .header-nav_sp .nav_list > li a:hover {
  color: #717D95;
}
.header .header-nav_sp .nav_list > li .mega_menu {
  display: none;
  position: static;
  padding-bottom: 20px;
  width: 100%;
  height: auto;
  margin-top: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
}
.header .header-nav_sp .nav_list > li .mega_menu li {
  height: auto;
  line-height: 1.4em;
}
.header .header-nav_sp .nav_list > li .mega_menu li:not(:last-child) {
  margin-bottom: clamp( 13px, calc( 13px + (( 100vw - 768px ) * (( 20 - 13 ) / ( 1400 - 768 )))), 20px );
}
.header .header-nav_sp .nav_list > li .mega_menu li a {
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px );
  font-family: "Poppins", sans-serif;
}
.header .header-nav_sp .nav_list > li .mega_menu li a.no_blank::after {
  content: none;
}
.header .header-nav_sp .nav_list > li .mega_menu li a::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 16px;
  background-image: url("../../assets/img/common/icon_blank.png");
  background-size: contain;
  vertical-align: middle;
  background-repeat: no-repeat;
  margin: -3px 0 0 5px;
}
.header .header-nav_sp .icon_list li {
  position: relative;
}
.header .header-nav_sp .icon_list li:first-child {
  margin-bottom: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 25 - 15 ) / ( 1400 - 768 )))), 25px );
}
.header .header-nav_sp .icon_list li a {
  display: flex;
  font-size: 13px;
  align-items: center;
  letter-spacing: 0.05em;
}
.header .header-nav_sp .icon_list li img {
  max-width: 20px;
  margin-right: 10px;
}
.header .header-nav_sp .icon_list li:last-child {
  margin-left: 0;
}
.header .header-nav_sp .icon_list li:last-child img {
  width: 59%;
}
.header.change .header-in .header_up {
  background-color: #fff;
}
.header.change .header-in .header_up h1 {
  max-width: 146px;
}
.header.change .header-in .header_up .nav_toggle {
  width: 22px;
  cursor: pointer;
  height: 16px;
  position: absolute;
  top: calc(50% - 8px);
  z-index: 100;
  left: 35px;
  /*開閉ボタンopen時*/
}
@media only screen and (max-width: 1024px) {
  .header.change .header-in .header_up .nav_toggle {
    top: calc(50% - 8px);
    left: 15px;
  }
}
.header.change .header-in .header_up .nav_toggle span {
  display: block;
  height: 1px;
  background: #222;
  position: absolute;
  width: 100%;
  left: 0;
  transition: 0.3s ease-in-out;
}
.header.change .header-in .header_up .nav_toggle span:nth-child(1) {
  top: 0;
}
.header.change .header-in .header_up .nav_toggle span:nth-child(2) {
  top: 7px;
}
.header.change .header-in .header_up .nav_toggle span:nth-child(3) {
  top: 14px;
}
.header.change .header-in .header_up .nav_toggle.open {
  height: 24px;
  top: calc(50% - 12px);
}
.header.change .header-in .header_up .nav_toggle.open span:nth-child(1) {
  top: 12px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  transform: rotate(135deg);
}
.header.change .header-in .header_up .nav_toggle.open span:nth-child(2) {
  width: 0;
  left: 50%;
}
.header.change .header-in .header_up .nav_toggle.open span:nth-child(3) {
  top: 12px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
.header.change .header-in .header_up .header_up {
  padding: clamp( 10px, calc( 10px + (( 100vw - 768px ) * (( 35 - 10 ) / ( 1400 - 768 )))), 35px ) 0;
  background-color: #fff;
}
.header .header-in {
  margin: 0 auto;
  position: relative;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  .header .header-in {
    width: 100%;
  }
}
.header .header-in .header_up {
  position: relative;
  border-bottom: 1px solid #e2e2e2;
  width: 100%;
  padding: 16px 0;
  transition: 0.3s;
}
@media only screen and (max-width: 1024px) {
  .header .header-in .header_up {
    padding: 18px 0;
  }
}
.header .header-in .header_up h1 {
  max-width: 194px;
  transition: 0.3s;
  margin: 0 auto;
  width: 19.7%;
}
.header .header-in .header_up h1 img {
  display: block;
  top: 0;
}
@media only screen and (max-width: 1024px) {
  .header .header-in .header_up h1 {
    width: 89px;
    top: 20px;
  }
}
.header .header-in .header-nav {
  border-bottom: 1px solid #e9e9e9;
  height: clamp( 90px, calc( 90px + (( 100vw - 768px ) * (( 100 - 90 ) / ( 1400 - 768 )))), 100px );
}
@media only screen and (max-width: 1024px) {
  .header .header-in .header-nav {
    display: none;
  }
}
.header .header-in .header-nav .nav_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.header .header-in .header-nav .nav_list > li {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  .header .header-in .header-nav .nav_list > li {
    height: auto;
    display: block;
  }
}
.header .header-in .header-nav .nav_list > li:not(:last-child) {
  margin-right: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 50 - 20 ) / ( 1400 - 768 )))), 50px );
}
@media only screen and (max-width: 1024px) {
  .header .header-in .header-nav .nav_list > li:not(:last-child) {
    margin-right: 0;
  }
}
.header .header-in .header-nav .nav_list > li a {
  transition: 0.2s;
  text-align: center;
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 13 - 12 ) / ( 1400 - 768 )))), 13px );
}
@media only screen and (max-width: 1024px) {
  .header .header-in .header-nav .nav_list > li a {
    padding-bottom: 18px;
    position: relative;
    letter-spacing: 0;
  }
}
.header .header-in .header-nav .nav_list > li a span {
  font-size: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 16 - 15 ) / ( 1400 - 768 )))), 16px );
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  display: block;
  font-style: normal;
  letter-spacing: 0.03em;
  margin-bottom: -5px;
}
@media only screen and (max-width: 1024px) {
  .header .header-in .header-nav .nav_list > li a span {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    letter-spacing: 0.14em;
  }
}
.header .header-in .header-nav .nav_list > li a:hover {
  color: #717D95;
}
.header .header-in .header-nav .nav_list > li .mega_menu {
  position: absolute;
  left: calc(50% - 110px);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  display: none;
  background: #fff;
  top: clamp( 80px, calc( 80px + (( 100vw - 768px ) * (( 90 - 80 ) / ( 1400 - 768 )))), 90px );
  padding: 25px;
  min-width: 225px;
  z-index: 1;
}
@media only screen and (max-width: 1024px) {
  .header .header-in .header-nav .nav_list > li .mega_menu {
    position: static;
    background: #f2f2f2;
    width: 100%;
    padding: 0;
    height: auto;
  }
}
.header .header-in .header-nav .nav_list > li .mega_menu li {
  height: auto;
  line-height: 1.4em;
}
.header .header-in .header-nav .nav_list > li .mega_menu li:not(:last-child) {
  margin-bottom: 15px;
}
.header .header-in .header-nav .nav_list > li .mega_menu li a {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 16 - 14 ) / ( 1400 - 768 )))), 16px );
}
.header .header-in .header-nav .nav_list > li .mega_menu li a.no_blank::after {
  content: none;
}
.header .header-in .header-nav .nav_list > li .mega_menu li a::after {
  content: "";
  display: inline-block;
  width: 15px;
  height: 16px;
  background-image: url("../../assets/img/common/icon_blank.png");
  background-size: contain;
  vertical-align: middle;
  background-repeat: no-repeat;
  margin: -3px 0 0 5px;
}
@media only screen and (max-width: 1024px) {
  .header .header-in .header-nav .nav_list > li .mega_menu li a {
    padding: 18px;
    border-bottom: none;
    position: relative;
  }
}
.header .header-in .header-nav .icon_list {
  display: flex;
  position: absolute;
  right: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 80 - 20 ) / ( 1400 - 768 )))), 80px );
  top: 13px;
}
@media only screen and (max-width: 1024px) {
  .header .header-in .header-nav .icon_list {
    right: 0;
    top: auto;
    bottom: 0;
    justify-content: space-between;
    background-color: #ECF1F5;
    width: 100%;
    height: 10%;
    align-items: center;
  }
}
@media only screen and (max-width: 1024px) {
  .header .header-in .header-nav .icon_list li {
    width: 50%;
    text-align: center;
    height: 100%;
    position: relative;
  }
  .header .header-in .header-nav .icon_list li:not(:first-child)::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: 1px;
    height: 14px;
    background-color: #aaa;
  }
}
@media only screen and (max-width: 1024px) {
  .header .header-in .header-nav .icon_list li a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
  }
}
.header .header-in .header-nav .icon_list li img {
  max-width: 32px;
  display: block;
  margin: 0 auto 3px;
}
@media only screen and (max-width: 1024px) {
  .header .header-in .header-nav .icon_list li img {
    display: none;
  }
}
.header .header-in .header-nav .icon_list li p {
  font-size: 11px;
}
@media only screen and (max-width: 1024px) {
  .header .header-in .header-nav .icon_list li p {
    font-size: 12px;
  }
}
.header .header-in .header-nav .icon_list li:last-child {
  margin-left: 15px;
}
@media only screen and (max-width: 1024px) {
  .header .header-in .header-nav .icon_list li:last-child {
    margin-left: 0;
  }
}

.footer {
  background: #717D95;
  position: relative;
}
@media only screen and (max-width: 1024px) {
  .footer {
    height: auto;
  }
}
.footer .footer_in {
  max-width: 1680px;
  padding: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 60 - 30 ) / ( 1400 - 768 )))), 60px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 120 - 20 ) / ( 1400 - 768 )))), 120px );
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: #fff;
}
.footer .footer_in .footer_upper {
  position: relative;
  margin-bottom: 60px;
}
@media only screen and (max-width: 1024px) {
  .footer .footer_in .footer_upper {
    margin-bottom: 80px;
  }
}
.footer .footer_in .footer_upper nav {
  display: flex;
}
@media only screen and (max-width: 1024px) {
  .footer .footer_in .footer_upper nav {
    margin-bottom: 30px;
  }
}
.footer .footer_in .footer_upper nav dl:first-of-type {
  margin-right: 60px;
}
.footer .footer_in .footer_upper nav dl dt {
  font-size: clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 22 - 18 ) / ( 1400 - 768 )))), 22px );
  letter-spacing: 0.15em;
  font-weight: 200;
  margin-bottom: clamp( 5px, calc( 5px + (( 100vw - 768px ) * (( 15 - 5 ) / ( 1400 - 768 )))), 15px );
  font-family: "Poppins", sans-serif;
}
.footer .footer_in .footer_upper nav dl dd:not(:last-child) {
  margin-bottom: clamp( 5px, calc( 5px + (( 100vw - 768px ) * (( 13 - 5 ) / ( 1400 - 768 )))), 13px );
}
.footer .footer_in .footer_upper nav dl dd a {
  text-decoration: underline;
}
.footer .footer_in .footer_upper nav dl dd a:hover {
  text-decoration: none;
}
.footer .footer_in .footer_upper .sns_list {
  position: absolute;
  right: 0;
  display: flex;
  top: 0;
}
@media only screen and (max-width: 1024px) {
  .footer .footer_in .footer_upper .sns_list {
    position: static;
  }
}
.footer .footer_in .footer_upper .sns_list li {
  width: clamp( 28px, calc( 28px + (( 100vw - 768px ) * (( 32 - 28 ) / ( 1400 - 768 )))), 32px );
  transition: 0.3s;
}
.footer .footer_in .footer_upper .sns_list li:hover {
  opacity: 0.6;
}
.footer .footer_in .footer_upper .sns_list li:first-child {
  margin-right: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 30 - 15 ) / ( 1400 - 768 )))), 30px );
}
.footer .footer_in .footer_under {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1024px) {
  .footer .footer_in .footer_under {
    display: block;
  }
}
.footer .footer_in .footer_under ul {
  display: flex;
  flex-wrap: wrap;
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 13 - 12 ) / ( 1400 - 768 )))), 13px );
  margin-bottom: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 20 - 15 ) / ( 1400 - 768 )))), 20px );
  margin-right: 20px;
}
@media only screen and (max-width: 1024px) {
  .footer .footer_in .footer_under ul {
    display: block;
    margin-right: 0;
  }
}
.footer .footer_in .footer_under ul li {
  position: relative;
}
@media only screen and (max-width: 1024px) {
  .footer .footer_in .footer_under ul li {
    display: inline-block;
  }
}
.footer .footer_in .footer_under ul li:not(:first-child)::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  width: 1px;
  height: 14px;
  background-color: #fff;
}
.footer .footer_in .footer_under ul li:not(:last-child) {
  margin-right: 40px;
}
@media only screen and (max-width: 1024px) {
  .footer .footer_in .footer_under ul li:nth-of-type(3)::before {
    content: none;
  }
}
.footer .footer_in .footer_under ul li a:hover {
  opacity: 0.6;
}
.footer .footer_in .footer_under .copy-light {
  font-size: clamp( 10px, calc( 10px + (( 100vw - 768px ) * (( 11 - 10 ) / ( 1400 - 768 )))), 11px );
  font-family: "Poppins", sans-serif;
}

.btn-to-top {
  width: 60px;
  height: 60px;
  background: #333;
  position: fixed;
  display: none;
  right: 25px;
  bottom: 110px;
  border-radius: 100%;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .btn-to-top {
    width: 45px;
    height: 45px;
    right: 15px;
  }
}
.btn-to-top:after {
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  border-top: 4px solid #fff;
  border-right: 4px solid #fff;
  transform: rotate(-45deg);
  position: absolute;
  top: 24px;
  left: 19px;
}
@media only screen and (max-width: 1024px) {
  .btn-to-top:after {
    width: 12px;
    height: 12px;
    top: 18px;
    left: 15px;
  }
}

#TOP .container,
#TOP .mainSection {
  margin-top: 0;
}
#TOP header {
  position: absolute;
}
@media only screen and (max-width: 1024px) {
  #TOP header {
    background: none;
  }
}
#TOP header nav {
  border-bottom: none;
}
@media only screen and (max-width: 1024px) {
  #TOP header .header_up {
    background: none;
  }
  #TOP header .header_up.change_sp, #TOP header .header_up.bg_f {
    background-color: #fff;
  }
}
#TOP .kv {
  background: url("../../assets/img/top/kv.jpg") no-repeat center;
  background-size: cover;
  height: auto;
  position: relative;
  padding-bottom: 56.2%;
  position: relative;
}
@media only screen and (max-width: 768px) {
  #TOP .kv {
    background: url("../../assets/img/top/kv_sp.jpg") no-repeat center;
    background-size: cover;
    height: calc(100vh - 44px);
    padding-bottom: 0;
  }
}
#TOP .kv .kv_in {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  text-align: left;
  left: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 120 - 30 ) / ( 1400 - 768 )))), 120px );
  transform: translate(0%, -50%);
  top: 57%;
  left: 8.42%;
  width: auto;
}
@media only screen and (max-width: 768px) {
  #TOP .kv .kv_in {
    left: 50%;
    top: 30%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
}
#TOP .kv h2 {
  font-family: fot-tsukumin-pr6n, sans-serif;
  font-size: clamp( 38px, calc( 38px + (( 100vw - 768px ) * (( 72 - 38 ) / ( 1400 - 768 )))), 72px );
  font-weight: 100;
  line-height: 1.2;
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  #TOP .kv h2 {
    text-align: center;
    font-size: 38px;
    font-size: 10.1333333333vw;
    line-height: 1.3;
  }
}
#TOP .kv h2 span {
  display: block;
}
@media only screen and (max-width: 768px) {
  #TOP .kv h2 span {
    margin-right: -6%;
  }
}
#TOP .kv .btn {
  margin: 0;
}
@media only screen and (max-width: 768px) {
  #TOP .kv .btn {
    margin: 0 auto;
  }
}
#TOP .bnr_area {
  margin: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 60 - 20 ) / ( 1400 - 768 )))), 60px ) auto 0;
}
#TOP .bnr_area ul {
  display: flex;
  justify-content: space-between;
  gap: 0 60px;
}
@media only screen and (max-width: 1024px) {
  #TOP .bnr_area ul {
    flex-wrap: wrap;
    gap: 10px 0;
  }
}
#TOP .bnr_area ul li {
  width: 33.3333%;
  flex: 1 1 0;
}
@media only screen and (max-width: 1024px) {
  #TOP .bnr_area ul li {
    width: 100%;
    flex: auto;
  }
}
#TOP .bnr_area ul li a:hover {
  opacity: 0.6;
}
#TOP .sec02 {
  background: url("../../assets/img/top/sec02_bg.jpg") no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 0;
  border-bottom: 1px solid #e9e9e9;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec02 {
    background: none;
    position: relative;
  }
  #TOP .sec02::after {
    content: "";
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    z-index: 1;
    bottom: -52px;
    padding-bottom: 39.5%;
    background-image: url("../../assets/img/top/sec02_obj.png");
    background-size: contain;
    vertical-align: middle;
  }
}
#TOP .sec02 .sec_in_bg {
  padding: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 200 - 60 ) / ( 1400 - 768 )))), 200px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px ) clamp( 0px, calc( 0px + (( 100vw - 768px ) * (( 160 - 0 ) / ( 1400 - 768 )))), 160px );
}
@media only screen and (max-width: 1024px) {
  #TOP .sec02 .sec_in_bg {
    margin-bottom: -40px;
  }
}
#TOP .sec02 h3 {
  text-align: center;
  font-family: fot-tsukumin-pr6n, sans-serif;
  font-weight: 100;
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 44 - 19 ) / ( 1400 - 768 )))), 44px );
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 60 - 30 ) / ( 1400 - 768 )))), 60px );
  line-height: 1.6;
}
#TOP .sec02 p {
  line-height: 3;
  text-align: center;
}
@media only screen and (max-width: 1245px) {
  #TOP .sec02 p {
    line-height: 2;
    text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff, 1px -1px 0 #fff, 0px 1px 0 #fff, -1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff;
  }
}
@media only screen and (max-width: 767px) {
  #TOP .sec02 p {
    text-align: left;
  }
}
#TOP .sec02 p + p {
  margin-top: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 40 - 20 ) / ( 1400 - 768 )))), 40px );
}
#TOP .sec03 .sec_in_bg {
  padding: clamp( 80px, calc( 80px + (( 100vw - 768px ) * (( 160 - 80 ) / ( 1400 - 768 )))), 160px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 120 - 20 ) / ( 1400 - 768 )))), 120px ) 0;
}
#TOP .sec03 .sec_ttl {
  margin-bottom: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 120 - 40 ) / ( 1400 - 768 )))), 120px );
}
@media only screen and (max-width: 1024px) {
  #TOP .sec03 .sec_ttl {
    line-height: 1.3;
    letter-spacing: 0.05em;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 320px) {
  #TOP .sec03 .sec_ttl {
    font-size: 21px;
  }
}
@media only screen and (max-width: 1024px) {
  #TOP .sec03 .sec_ttl span {
    margin-top: 5px;
  }
}
#TOP .sec03 .obj {
  position: absolute;
  bottom: 120px;
  left: 0;
  z-index: 10;
  width: clamp( 180px, calc( 180px + (( 100vw - 768px ) * (( 251 - 180 ) / ( 1400 - 768 )))), 251px );
}
@media only screen and (max-width: 1590px) {
  #TOP .sec03 .obj {
    bottom: 0;
  }
}
@media only screen and (max-width: 1440px) {
  #TOP .sec03 .obj {
    bottom: 60px;
    position: absolute;
  }
}
@media only screen and (max-width: 1439px) {
  #TOP .sec03 .obj {
    position: static;
    margin-top: -25px;
  }
}
@media only screen and (max-width: 1024px) {
  #TOP .sec03 .obj {
    margin: 0 auto clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 50 - 40 ) / ( 1400 - 768 )))), 50px );
  }
}
#TOP .sec03 .j_slider {
  overflow: hidden;
  position: relative;
  margin-left: auto;
  padding-bottom: clamp( 0px, calc( 0px + (( 100vw - 768px ) * (( 120 - 0 ) / ( 1400 - 768 )))), 120px );
}
@media only screen and (max-width: 1024px) {
  #TOP .sec03 .j_slider {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    padding-left: 17px;
  }
}
#TOP .sec03 .j_slider .swiper-button {
  position: absolute;
  right: 50.7%;
  display: flex;
  bottom: 115px;
}
@media only screen and (max-width: 1445px) {
  #TOP .sec03 .j_slider .swiper-button {
    bottom: -60px;
    position: relative;
    left: 0;
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 1200px) {
  #TOP .sec03 .j_slider .swiper-button {
    bottom: -27px;
  }
}
#TOP .sec03 .swiper-slide {
  background-color: #fff;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec03 .swiper-slide {
    max-width: 45%;
  }
}
@media only screen and (max-width: 767px) {
  #TOP .sec03 .swiper-slide {
    max-width: 90%;
  }
}
#TOP .sec03 .swiper-slide .info_area {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec03 .swiper-slide .info_area {
    position: relative;
  }
}
#TOP .sec03 .swiper-slide .info_area::after {
  content: "";
  display: block;
  clear: both;
}
#TOP .sec03 .swiper-slide .info_area .txt_area {
  border-left: 1px solid #e9e9e9;
  padding-left: 40px;
  margin-left: clamp( 95px, calc( 95px + (( 100vw - 768px ) * (( 115 - 95 ) / ( 1400 - 768 )))), 115px );
  position: relative;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec03 .swiper-slide .info_area .txt_area {
    margin-left: 0;
    text-align: center;
    border: none;
    padding-left: 0;
    background-color: rgba(255, 255, 255, 0.98);
    padding: 20px;
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 90%;
    height: auto;
  }
}
#TOP .sec03 .swiper-slide .info_area .txt_area .number {
  position: absolute;
  font-family: fot-tsukumin-pr6n, sans-serif;
  font-size: clamp( 26px, calc( 26px + (( 100vw - 768px ) * (( 70 - 26 ) / ( 1400 - 768 )))), 70px );
  top: 190px;
  left: -70px;
  letter-spacing: 0.1em;
  line-height: 1;
  transform: translate(-50%, -50%);
  text-align: center;
}
@media only screen and (max-width: 1440px) {
  #TOP .sec03 .swiper-slide .info_area .txt_area .number {
    top: 260px;
  }
}
@media only screen and (max-width: 1024px) {
  #TOP .sec03 .swiper-slide .info_area .txt_area .number {
    position: static;
    transform: none;
    margin-bottom: 8px;
  }
}
#TOP .sec03 .swiper-slide .info_area img {
  float: right;
  width: 46%;
  margin: 0 0 0 40px;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec03 .swiper-slide .info_area img {
    float: none;
    width: 100%;
    margin: 0;
  }
}
#TOP .sec03 .swiper-slide .info_area h3 {
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 32 - 16 ) / ( 1400 - 768 )))), 32px );
  font-weight: 300;
  letter-spacing: 0.13em;
  color: #1f1f1f;
  line-height: 1.5;
  margin-bottom: clamp( 6px, calc( 6px + (( 100vw - 768px ) * (( 10 - 6 ) / ( 1400 - 768 )))), 10px );
}
#TOP .sec03 .swiper-slide .info_area h4 {
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
  font-weight: 300;
  margin-bottom: clamp( 0px, calc( 0px + (( 100vw - 768px ) * (( 15 - 0 ) / ( 1400 - 768 )))), 15px );
}
@media only screen and (max-width: 1024px) {
  #TOP .sec03 .swiper-slide .info_area h4 {
    line-height: 1.6;
  }
}
@media only screen and (max-width: 1024px) {
  #TOP .sec03 .swiper-slide .info_area h4 + p {
    display: none;
  }
}
#TOP .sec04 {
  background: url("../../assets/img/top/sec04_bg.jpg") no-repeat;
  background-position: right 28% top;
  background-size: cover;
  height: clamp( 345px, calc( 345px + (( 100vw - 768px ) * (( 514 - 345 ) / ( 1400 - 768 )))), 514px );
}
@media only screen and (max-width: 1024px) {
  #TOP .sec04 {
    background: url("../../assets/img/top/sec04_bg_sp.jpg") no-repeat center;
    background-size: cover;
    text-align: center;
    margin-bottom: 75px;
  }
}
#TOP .sec04 h3 {
  font-size: clamp( 22px, calc( 22px + (( 100vw - 768px ) * (( 44 - 22 ) / ( 1400 - 768 )))), 44px );
  letter-spacing: 0.1em;
  margin-bottom: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 25 - 15 ) / ( 1400 - 768 )))), 25px );
  font-weight: 300;
}
#TOP .sec04 h3 span {
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
  font-family: fot-tsukumin-pr6n, sans-serif;
  display: block;
  font-weight: 100;
  letter-spacing: 0;
  margin-top: -10px;
}
#TOP .sec04 p {
  margin-bottom: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 35 - 15 ) / ( 1400 - 768 )))), 35px );
}
#TOP .sec04 .sec_in {
  display: flex;
  align-items: center;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec04 .sec_in {
    justify-content: center;
  }
}
#TOP .sec05 {
  position: relative;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec05 {
    margin-bottom: 80px;
  }
}
#TOP .sec05 .sec_ttl + p {
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 16 - 14 ) / ( 1400 - 768 )))), 16px );
  margin-bottom: clamp( 80px, calc( 80px + (( 100vw - 768px ) * (( 190 - 80 ) / ( 1400 - 768 )))), 190px );
}
@media only screen and (max-width: 1024px) {
  #TOP .sec05 .sec_ttl + p {
    text-align: center;
  }
}
@media only screen and (max-width: 1024px) {
  #TOP .sec06,
#TOP .sec08 {
    margin-bottom: 40px;
  }
}
#TOP .sec06 .sec_in,
#TOP .sec08 .sec_in {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%;
  padding: 0;
}
#TOP .sec06 .sec_in .l_area,
#TOP .sec08 .sec_in .l_area {
  width: 50%;
  background-color: #ecf1f5;
  position: relative;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  #TOP .sec06 .sec_in .l_area,
#TOP .sec08 .sec_in .l_area {
    order: 2;
    width: 100%;
  }
}
#TOP .sec06 .sec_in .l_area .l_area_in,
#TOP .sec08 .sec_in .l_area .l_area_in {
  padding: 0 clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 120 - 30 ) / ( 1400 - 768 )))), 120px );
}
@media only screen and (max-width: 1024px) {
  #TOP .sec06 .sec_in .l_area .l_area_in,
#TOP .sec08 .sec_in .l_area .l_area_in {
    padding: 40px 20px;
  }
}
#TOP .sec06 .sec_in .l_area .l_area_in h3,
#TOP .sec08 .sec_in .l_area .l_area_in h3 {
  width: clamp( 140px, calc( 140px + (( 100vw - 768px ) * (( 233 - 140 ) / ( 1400 - 768 )))), 233px );
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  #TOP .sec06 .sec_in .l_area .l_area_in h3,
#TOP .sec08 .sec_in .l_area .l_area_in h3 {
    margin-bottom: 30px;
    position: absolute;
    top: -60px;
  }
}
#TOP .sec06 .sec_in .l_area .l_area_in p + p,
#TOP .sec08 .sec_in .l_area .l_area_in p + p {
  margin: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px ) 0 clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 40 - 30 ) / ( 1400 - 768 )))), 40px );
}
@media only screen and (max-width: 1024px) {
  #TOP .sec06 .sec_in .l_area .l_area_in p + p,
#TOP .sec08 .sec_in .l_area .l_area_in p + p {
    margin-bottom: 0;
  }
}
#TOP .sec06 .sec_in .r_area,
#TOP .sec08 .sec_in .r_area {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  #TOP .sec06 .sec_in .r_area,
#TOP .sec08 .sec_in .r_area {
    order: 1;
    width: 100%;
  }
}
#TOP .sec06 .sec_in .r_area img,
#TOP .sec08 .sec_in .r_area img {
  min-height: 650px;
  object-fit: cover;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec06 .sec_in .r_area img,
#TOP .sec08 .sec_in .r_area img {
    min-height: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  #TOP .sec06 .btn {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 1024px) {
  #TOP .sec07 .sec_ttl_jp,
#TOP .sec09 .sec_ttl_jp {
    margin-bottom: 40px;
  }
}
#TOP .sec07 .pickup_list,
#TOP .sec09 .pickup_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 100 - 40 ) / ( 1400 - 768 )))), 100px );
}
@media only screen and (max-width: 1024px) {
  #TOP .sec07 .pickup_list,
#TOP .sec09 .pickup_list {
    overflow-x: auto;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}
#TOP .sec07 .pickup_list .item,
#TOP .sec09 .pickup_list .item {
  width: 32%;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec07 .pickup_list .item,
#TOP .sec09 .pickup_list .item {
    width: 41.5%;
    flex-shrink: 0;
  }
  #TOP .sec07 .pickup_list .item:not(:last-of-type),
#TOP .sec09 .pickup_list .item:not(:last-of-type) {
    margin-right: 5%;
  }
}
#TOP .sec07 .pickup_list .item p,
#TOP .sec09 .pickup_list .item p {
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
}
#TOP .sec07 .pickup_list .item p span,
#TOP .sec09 .pickup_list .item p span {
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px );
  font-weight: 300;
}
#TOP .sec07 .pickup_list .item .p_ttl,
#TOP .sec09 .pickup_list .item .p_ttl {
  margin-top: 20px;
  line-height: 1.6;
}
#TOP .sec07 .pickup_list .item .p_ttl.link_blank::after,
#TOP .sec09 .pickup_list .item .p_ttl.link_blank::after {
  content: "";
  display: inline-block;
  width: 22px;
  height: 23px;
  background-image: url("../../assets/img/common/icon_blank.png");
  background-size: contain;
  vertical-align: middle;
  background-repeat: no-repeat;
  margin: -3px 0 0 7px;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec07 .pickup_list .item .p_ttl.link_blank::after,
#TOP .sec09 .pickup_list .item .p_ttl.link_blank::after {
    width: 14px;
    height: 15px;
  }
}
#TOP .sec07 .pickup_list .item .p_price,
#TOP .sec09 .pickup_list .item .p_price {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
#TOP .sec07 .pickup_list .item a:hover,
#TOP .sec09 .pickup_list .item a:hover {
  opacity: 0.7;
  text-decoration: underline;
}
#TOP .sec07 .a_area,
#TOP .sec09 .a_area {
  background-color: #ecf1f5;
  padding: 60px clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 60 - 20 ) / ( 1400 - 768 )))), 60px ) clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 60 - 30 ) / ( 1400 - 768 )))), 60px );
}
#TOP .sec07 .a_area .coments,
#TOP .sec09 .a_area .coments {
  position: relative;
  padding: 0 clamp( 0px, calc( 0px + (( 100vw - 768px ) * (( 92 - 0 ) / ( 1400 - 768 )))), 92px );
  margin: 0 auto clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 50 - 20 ) / ( 1400 - 768 )))), 50px );
}
#TOP .sec07 .a_area .coments::before, #TOP .sec07 .a_area .coments::after,
#TOP .sec09 .a_area .coments::before,
#TOP .sec09 .a_area .coments::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 43px;
  height: 38px;
  top: 0;
  left: 0;
  background-size: contain;
  vertical-align: middle;
  background: url("../../assets/img/top/txt_obj1.svg") no-repeat center;
  background-size: cover;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec07 .a_area .coments::before, #TOP .sec07 .a_area .coments::after,
#TOP .sec09 .a_area .coments::before,
#TOP .sec09 .a_area .coments::after {
    width: 24px;
    height: 21px;
    top: -35px;
  }
}
#TOP .sec07 .a_area .coments::after,
#TOP .sec09 .a_area .coments::after {
  transform: rotate(-180deg);
  top: auto;
  left: auto;
  right: 0;
  bottom: 0;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec07 .a_area .coments::after,
#TOP .sec09 .a_area .coments::after {
    content: none;
  }
}
#TOP .sec07 .a_area .a_profile,
#TOP .sec09 .a_area .a_profile {
  display: flex;
  align-items: center;
}
#TOP .sec07 .a_area .a_profile img,
#TOP .sec09 .a_area .a_profile img {
  width: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 72 - 40 ) / ( 1400 - 768 )))), 72px );
  margin-right: 20px;
  border-radius: 50%;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec07 .a_area .a_profile img,
#TOP .sec09 .a_area .a_profile img {
    margin-right: 3%;
  }
}
#TOP .sec07 .a_area .a_profile .p_area .name,
#TOP .sec09 .a_area .a_profile .p_area .name {
  font-weight: 400;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
  line-height: 1.6;
}
#TOP .sec07 .a_area .a_profile .p_area .title,
#TOP .sec09 .a_area .a_profile .p_area .title {
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px );
}
#TOP .sec08 .sec_in .l_area {
  background-color: #f7f0f2;
}
#TOP .sec08 .sec_in .l_area .l_area_in h3 {
  width: clamp( 119px, calc( 119px + (( 100vw - 768px ) * (( 191 - 119 ) / ( 1400 - 768 )))), 191px );
  margin-bottom: 45px;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec08 .sec_in .l_area .l_area_in h3 {
    top: -60px;
  }
}
#TOP .sec09 .a_area {
  background-color: #f7f0f2;
}
#TOP .sec09 .a_area .coments::before, #TOP .sec09 .a_area .coments::after {
  background: url("../../assets/img/top/txt_obj2.svg") no-repeat center;
  background-size: cover;
}
#TOP .sec10 {
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  padding: clamp( 70px, calc( 70px + (( 100vw - 768px ) * (( 175 - 70 ) / ( 1400 - 768 )))), 175px ) 0;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec10 {
    margin-bottom: 80px;
  }
}
#TOP .sec10 .sec_ttl + p {
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 45 - 30 ) / ( 1400 - 768 )))), 45px );
  text-align: center;
}
#TOP .sec10 .s_list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto clamp( 70px, calc( 70px + (( 100vw - 768px ) * (( 130 - 70 ) / ( 1400 - 768 )))), 130px );
}
#TOP .sec10 .s_list li {
  width: 29%;
  margin-top: 15px;
}
#TOP .sec10 .s_list li:not(:last-child) {
  margin-right: 35px;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec10 .s_list li:not(:last-child) {
    margin-right: 0.5%;
  }
}
@media only screen and (max-width: 767px) {
  #TOP .sec10 .s_list li {
    width: 46.5%;
    margin: 15px 0.5% 0 0.5%;
  }
}
#TOP .sec10 .s_list li a:hover {
  opacity: 0.6;
}
#TOP .sec10 .b_ttl {
  position: absolute;
  left: 9%;
  top: 24%;
}
@media only screen and (max-width: 767px) {
  #TOP .sec10 .b_ttl {
    top: -5%;
    z-index: 2;
  }
}
#TOP .sec10 .b_ttl span {
  font-size: clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 22 - 18 ) / ( 1400 - 768 )))), 22px );
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.1em;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
}
#TOP .sec10 .p_slider_area {
  position: relative;
}
#TOP .sec10 .p_slider {
  overflow: hidden;
  position: relative;
  width: 80%;
  margin-left: auto;
  padding-bottom: clamp( 0px, calc( 0px + (( 100vw - 768px ) * (( 120 - 0 ) / ( 1400 - 768 )))), 120px );
}
@media only screen and (max-width: 767px) {
  #TOP .sec10 .p_slider {
    width: 95.5%;
  }
}
#TOP .sec10 .p_slider .swiper-slide {
  max-width: clamp( 350px, calc( 350px + (( 100vw - 768px ) * (( 532 - 350 ) / ( 1400 - 768 )))), 532px );
}
@media only screen and (max-width: 767px) {
  #TOP .sec10 .p_slider .swiper-slide {
    max-width: 90%;
  }
}
#TOP .sec10 .p_slider .swiper-slide h4 {
  font-weight: 300;
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 19 - 16 ) / ( 1400 - 768 )))), 19px );
  margin: clamp( 10px, calc( 10px + (( 100vw - 768px ) * (( 20 - 10 ) / ( 1400 - 768 )))), 20px ) 0 clamp( 4px, calc( 4px + (( 100vw - 768px ) * (( 10 - 4 ) / ( 1400 - 768 )))), 10px );
  line-height: 1.6;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec10 .p_slider .swiper-slide h4 {
    font-weight: 400;
  }
}
#TOP .sec10 .p_slider .swiper-slide p {
  line-height: 1.6;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 16 - 14 ) / ( 1400 - 768 )))), 16px );
  color: #888c8e;
}
#TOP .sec10 .p_slider .swiper-slide a:hover {
  opacity: 0.7;
  text-decoration: underline;
}
#TOP .sec10 .p_slider .swiper-button {
  position: absolute;
  right: 7%;
  display: flex;
  bottom: 0;
}
#TOP .sec10 .p_slider .swiper-pagination-horizontal {
  bottom: -10px;
  left: 0;
  width: max-content;
  position: relative;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec10 .p_slider .swiper-pagination-horizontal {
    display: none;
  }
}
#TOP .sec10 .p_slider .swiper-pagination-bullet {
  border: 1px solid #717d95;
  background: none;
  width: clamp( 10px, calc( 10px + (( 100vw - 768px ) * (( 14 - 10 ) / ( 1400 - 768 )))), 14px );
  height: clamp( 10px, calc( 10px + (( 100vw - 768px ) * (( 14 - 10 ) / ( 1400 - 768 )))), 14px );
  opacity: 1;
  margin: 0 5px;
}
#TOP .sec10 .p_slider .swiper-pagination-bullet-active {
  background-color: #717d95;
}
#TOP .sec11 .sec_ttl {
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 70 - 30 ) / ( 1400 - 768 )))), 70px );
}
#TOP .sec11 ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 60 - 30 ) / ( 1400 - 768 )))), 60px );
}
#TOP .sec11 ul li {
  width: 48.5%;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 30px;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec11 ul li {
    width: 100%;
    padding-bottom: 15px;
    margin-bottom: 25px;
  }
  #TOP .sec11 ul li:last-child {
    margin-bottom: 0;
  }
}
#TOP .sec11 ul li:nth-child(n+3) {
  margin-top: 45px;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec11 ul li:nth-child(n+3) {
    margin-top: 0;
  }
}
#TOP .sec11 ul li a {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
#TOP .sec11 ul li a:hover {
  opacity: 0.7;
  text-decoration: underline;
}
#TOP .sec11 ul li a .l_area {
  width: 70%;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec11 ul li a .l_area {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  #TOP .sec11 ul li a .l_area .l_area_head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 4px;
  }
}
#TOP .sec11 ul li a .l_area .cat {
  border: 1px solid #e9ebee;
  height: clamp( 24px, calc( 24px + (( 100vw - 768px ) * (( 36 - 24 ) / ( 1400 - 768 )))), 36px );
  line-height: clamp( 23px, calc( 23px + (( 100vw - 768px ) * (( 36 - 23 ) / ( 1400 - 768 )))), 36px );
  font-size: clamp( 11px, calc( 11px + (( 100vw - 768px ) * (( 13 - 11 ) / ( 1400 - 768 )))), 13px );
  width: auto;
  padding: 0 15px;
  display: inline-block;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec11 ul li a .l_area .cat {
    order: 2;
    padding: 0 8px;
  }
}
#TOP .sec11 ul li a .l_area .time {
  margin: 13px 0 0;
  font-family: "Poppins", sans-serif;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec11 ul li a .l_area .time {
    order: 1;
    margin: 0 4% 0 0;
  }
}
#TOP .sec11 ul li a .l_area h3 {
  font-weight: 300;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
#TOP .sec11 ul li a img {
  width: 24.4%;
  height: auto;
}
@media only screen and (max-width: 1024px) {
  #TOP .sec11 ul li a img {
    display: none;
  }
}
#TOP .swiper-button-next,
#TOP .swiper-button-prev {
  top: auto;
  bottom: 0;
  position: static;
  left: auto;
  width: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 80 - 40 ) / ( 1400 - 768 )))), 80px );
  height: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 80 - 40 ) / ( 1400 - 768 )))), 80px );
  transition: 0.3s;
}
#TOP .swiper-button-next:hover,
#TOP .swiper-button-prev:hover {
  opacity: 0.5;
}
@media only screen and (max-width: 1024px) {
  #TOP .swiper-button-next,
#TOP .swiper-button-prev {
    display: none;
  }
}
#TOP .swiper-button-next:after,
#TOP .swiper-button-prev:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: url("../../assets/img/top/arrow.svg") no-repeat center;
  background-size: contain;
  vertical-align: middle;
  transform: rotate(-180deg);
}
#TOP .swiper-button-prev {
  margin-right: 25px;
}
#TOP .swiper-button-prev:after {
  transform: rotate(0deg);
}
@media only screen and (max-width: 1024px) {
  #TOP .footer .footer_in {
    padding-bottom: 135px;
  }
}

#member .mainSection {
  margin-top: 0;
}
#member .sec01 .sec_ttl {
  margin: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 120 - 60 ) / ( 1400 - 768 )))), 120px ) 0 clamp( 35px, calc( 35px + (( 100vw - 768px ) * (( 60 - 35 ) / ( 1400 - 768 )))), 60px );
}
#member .sec01 .box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#member .sec01 .box .l_area, #member .sec01 .box .r_area {
  width: 49%;
  background-color: #FAFAFA;
  padding: clamp( 35px, calc( 35px + (( 100vw - 768px ) * (( 85 - 35 ) / ( 1400 - 768 )))), 85px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 85 - 20 ) / ( 1400 - 768 )))), 85px );
}
@media only screen and (max-width: 1024px) {
  #member .sec01 .box .l_area, #member .sec01 .box .r_area {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  #member .sec01 .box .l_area {
    margin-bottom: 20px;
  }
}
#member .sec01 .box .l_area .btn_area_c {
  margin: clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 50 - 25 ) / ( 1400 - 768 )))), 50px ) 0 25px;
}
#member .sec01 .box .l_area .txt_link {
  display: block;
  width: max-content;
  margin: 0 auto;
}
#member .sec01 .box h4 {
  font-size: clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 28 - 18 ) / ( 1400 - 768 )))), 28px );
  font-weight: 300;
  letter-spacing: 0.1em;
  line-height: 1.6;
  margin-bottom: 10px;
  color: #1F1F1F;
}
#member .sec01 .box h4 + p {
  margin-bottom: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 50 - 15 ) / ( 1400 - 768 )))), 50px );
}
#member .sec01 .box dl:not(:last-of-type) {
  margin-bottom: 25px;
}
#member .sec01 .box dl dt {
  margin-bottom: 5px;
  color: #1F1F1F;
}
#member .modaal-container {
  background: #fff;
  border-radius: 0;
  position: relative;
  padding: 5% 5% 3.5%;
  width: 100%;
  min-height: 400px;
}
@media only screen and (max-width: 1024px) {
  #member .modaal-container {
    padding: 13% 5% 12%;
  }
}
@media only screen and (max-width: 1024px) {
  #member .modaal-container {
    width: 100%;
  }
}
#member .modaal-container h3 {
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 32 - 19 ) / ( 1400 - 768 )))), 32px );
  text-align: center;
  margin-bottom: 10px;
  font-weight: 400;
}
@media only screen and (max-width: 1024px) {
  #member .modaal-container h3 {
    margin-bottom: 15px;
  }
}
#member .modaal-container h3 + p {
  text-align: center;
  margin-bottom: clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 50 - 25 ) / ( 1400 - 768 )))), 50px );
}
@media only screen and (max-width: 1024px) {
  #member .modaal-container h3 + p {
    text-align: left;
  }
}
#member .modaal-container dl {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 860px) {
  #member .modaal-container dl dt {
    margin-bottom: 10px;
  }
}
#member .modaal-container dl dd {
  width: 70%;
}
@media only screen and (max-width: 860px) {
  #member .modaal-container dl dd {
    width: 100%;
  }
}
#member .modaal-container .btn {
  margin-top: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 60 - 40 ) / ( 1400 - 768 )))), 60px );
}
#member .modaal-container .txt_link {
  display: block;
  margin-top: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 25 - 15 ) / ( 1400 - 768 )))), 25px );
  color: #717D95;
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px );
}
#member .modaal-container .code_list {
  display: flex;
  justify-content: center;
}
#member .modaal-container .code_list input {
  text-align: center;
  max-width: 56px;
  max-height: 56px;
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 20 - 16 ) / ( 1400 - 768 )))), 20px );
}
#member .modaal-container .code_list input:not(:last-child) {
  margin-right: 2%;
}
#member .modaal-container .note_area {
  color: #797979;
  border-top: 1px solid #CDCDCD;
  padding-top: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 40 - 20 ) / ( 1400 - 768 )))), 40px );
  margin-top: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 80 - 40 ) / ( 1400 - 768 )))), 80px );
  font-size: clamp( 11px, calc( 11px + (( 100vw - 768px ) * (( 13 - 11 ) / ( 1400 - 768 )))), 13px );
}
#member .modaal-container .note_area p:first-child {
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
}
#member .modaal-container .note_area ol {
  list-style-type: decimal;
  padding-left: 16px;
}
#member .code:focus {
  outline: auto;
}
#member input[type=checkbox] {
  display: none;
}
#member input[type=checkbox]:checked + .checkbox01::before {
  background-color: #717d95;
}
#member input[type=checkbox]:checked + .checkbox01::after {
  opacity: 1;
}
#member .checkbox01 {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding-left: 35px;
  position: relative;
  width: auto;
}
#member .checkbox01::before {
  background: #fff;
  border: 1px solid #e0e0e0;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  left: 0;
  position: absolute;
  top: clamp( 1px, calc( 1px + (( 100vw - 768px ) * (( 5 - 1 ) / ( 1400 - 768 )))), 5px );
}
#member .checkbox01::after {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  content: "";
  display: block;
  height: 9px;
  left: 7.5px;
  margin-top: -8px;
  opacity: 0;
  position: absolute;
  top: clamp( 13px, calc( 13px + (( 100vw - 768px ) * (( 17 - 13 ) / ( 1400 - 768 )))), 17px );
  transform: rotate(45deg);
  width: 5px;
}

#mypage .mainSection {
  margin-top: 0;
}
#mypage .sec01 {
  margin: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 120 - 60 ) / ( 1400 - 768 )))), 120px ) 0 clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 100 - 40 ) / ( 1400 - 768 )))), 100px );
}
#mypage .sec01 .sec_ttl_jp {
  text-align: left;
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 19 - 16 ) / ( 1400 - 768 )))), 19px );
  margin-bottom: 15px;
  letter-spacing: 0;
}
#mypage .sec01 .sec_ttl_jp .acceptance {
  border-radius: 50px;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 16 - 14 ) / ( 1400 - 768 )))), 16px );
  color: #fff;
  width: auto;
  padding: 0 15px;
  background-color: #717D95;
  display: inline-block;
  margin-left: 10px;
}
#mypage .sec01 .member_head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 80 - 40 ) / ( 1400 - 768 )))), 80px );
  position: relative;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_head {
    display: block;
    width: 100%;
  }
}
#mypage .sec01 .member_head .name_area {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}
#mypage .sec01 .member_head .name_area .ship {
  max-width: 168px;
  margin-right: 25px;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_head .name_area .ship {
    width: 100%;
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    align-items: center;
  }
  #mypage .sec01 .member_head .name_area .ship img {
    width: 96px;
    max-width: 100%;
    margin-right: 15px;
    top: 0;
  }
}
#mypage .sec01 .member_head .name_area h2 {
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 32 - 19 ) / ( 1400 - 768 )))), 32px );
  font-weight: 300;
  color: #1F1F1F;
  line-height: 1.6;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_head .name_area .name {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_head .name_area .name .point_area {
    border: 1px solid #e9e9e9;
    padding: 20px;
    margin-top: 30px;
  }
}
#mypage .sec01 .member_head .name_area .name .point .point_txt {
  display: inline-block;
}
#mypage .sec01 .member_head .name_area .name .items_point {
  color: #787878;
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 13 - 12 ) / ( 1400 - 768 )))), 13px );
}
#mypage .sec01 .member_head .name_area .name .items_point span {
  display: inline-block;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_head .name_area .name .items_point .pc_none {
    display: none;
  }
}
#mypage .sec01 .member_head .name_area .name .rank {
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
  margin-top: -12px;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_head .name_area .name .rank {
    margin-top: -8px;
  }
}
#mypage .sec01 .member_head .btn {
  border: 1px solid #d3d7e0;
  position: absolute;
  right: 0;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_head .btn {
    text-decoration: underline;
    background: none;
    border: none;
    color: #717D95;
    padding: 0;
    margin: 0;
    width: auto;
    font-size: 12px;
    top: 5px;
  }
}
#mypage .sec01 .member_detals {
  margin-bottom: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 80 - 40 ) / ( 1400 - 768 )))), 80px );
}
#mypage .sec01 .member_detals .up_area,
#mypage .sec01 .member_detals .under_area {
  padding: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 40 - 30 ) / ( 1400 - 768 )))), 40px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 65 - 20 ) / ( 1400 - 768 )))), 65px ) clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 35 - 30 ) / ( 1400 - 768 )))), 35px );
  width: 100%;
}
#mypage .sec01 .member_detals .up_area .head,
#mypage .sec01 .member_detals .under_area .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 15px;
  margin-bottom: clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 35 - 25 ) / ( 1400 - 768 )))), 35px );
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_detals .up_area .head,
#mypage .sec01 .member_detals .under_area .head {
    display: block;
  }
}
#mypage .sec01 .member_detals .up_area .txt,
#mypage .sec01 .member_detals .under_area .txt {
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 19 - 16 ) / ( 1400 - 768 )))), 19px );
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_detals .up_area .txt,
#mypage .sec01 .member_detals .under_area .txt {
    text-align: center;
    margin-bottom: 10px;
  }
}
#mypage .sec01 .member_detals .up_area .number,
#mypage .sec01 .member_detals .under_area .number {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_detals .up_area .number,
#mypage .sec01 .member_detals .under_area .number {
    text-align: center;
    display: block;
  }
}
#mypage .sec01 .member_detals .up_area .number .period,
#mypage .sec01 .member_detals .under_area .number .period {
  display: inline-block;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_detals .up_area .number .period,
#mypage .sec01 .member_detals .under_area .number .period {
    text-align: center;
    display: block;
  }
}
#mypage .sec01 .member_detals .up_area .number .point,
#mypage .sec01 .member_detals .under_area .number .point {
  font-family: "Poppins", sans-serif;
  font-size: clamp( 21px, calc( 21px + (( 100vw - 768px ) * (( 34 - 21 ) / ( 1400 - 768 )))), 34px );
  margin-left: 30px;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_detals .up_area .number .point,
#mypage .sec01 .member_detals .under_area .number .point {
    margin: 0;
  }
}
#mypage .sec01 .member_detals .up_area .number span,
#mypage .sec01 .member_detals .under_area .number span {
  font-family: "Poppins", sans-serif;
}
#mypage .sec01 .member_detals .up_area.pearl,
#mypage .sec01 .member_detals .under_area.pearl {
  background-color: #fdf8f6;
}
#mypage .sec01 .member_detals .up_area.pearl .txt,
#mypage .sec01 .member_detals .up_area.pearl .gauge_txt,
#mypage .sec01 .member_detals .under_area.pearl .txt,
#mypage .sec01 .member_detals .under_area.pearl .gauge_txt {
  color: #d6ae97;
}
#mypage .sec01 .member_detals .up_area.pearl .gauge span,
#mypage .sec01 .member_detals .under_area.pearl .gauge span {
  background-color: #D6AE97;
}
#mypage .sec01 .member_detals .up_area.sapphire,
#mypage .sec01 .member_detals .under_area.sapphire {
  background-color: #EBEDF4;
}
#mypage .sec01 .member_detals .up_area.sapphire .txt,
#mypage .sec01 .member_detals .up_area.sapphire .gauge_txt,
#mypage .sec01 .member_detals .under_area.sapphire .txt,
#mypage .sec01 .member_detals .under_area.sapphire .gauge_txt {
  color: #3c5497;
}
#mypage .sec01 .member_detals .up_area.sapphire .gauge span,
#mypage .sec01 .member_detals .under_area.sapphire .gauge span {
  background-color: #3c5497;
}
#mypage .sec01 .member_detals .up_area.ruby,
#mypage .sec01 .member_detals .under_area.ruby {
  background-color: #FBEEF3;
}
#mypage .sec01 .member_detals .up_area.ruby .txt,
#mypage .sec01 .member_detals .up_area.ruby .gauge_txt,
#mypage .sec01 .member_detals .under_area.ruby .txt,
#mypage .sec01 .member_detals .under_area.ruby .gauge_txt {
  color: #d95889;
}
#mypage .sec01 .member_detals .up_area.ruby .gauge span,
#mypage .sec01 .member_detals .under_area.ruby .gauge span {
  background-color: #d95889;
}
#mypage .sec01 .member_detals .up_area.emerald,
#mypage .sec01 .member_detals .under_area.emerald {
  background-color: #ECF6F6;
}
#mypage .sec01 .member_detals .up_area.emerald .txt,
#mypage .sec01 .member_detals .up_area.emerald .gauge_txt,
#mypage .sec01 .member_detals .under_area.emerald .txt,
#mypage .sec01 .member_detals .under_area.emerald .gauge_txt {
  color: #45a9aa;
}
#mypage .sec01 .member_detals .up_area.emerald .gauge span,
#mypage .sec01 .member_detals .under_area.emerald .gauge span {
  background-color: #45a9aa;
}
#mypage .sec01 .member_detals .up_area.diamond,
#mypage .sec01 .member_detals .under_area.diamond {
  background-color: #EFF0F0;
}
#mypage .sec01 .member_detals .up_area.diamond .txt,
#mypage .sec01 .member_detals .up_area.diamond .gauge_txt,
#mypage .sec01 .member_detals .under_area.diamond .txt,
#mypage .sec01 .member_detals .under_area.diamond .gauge_txt {
  color: #646b71;
}
#mypage .sec01 .member_detals .up_area.diamond .gauge span,
#mypage .sec01 .member_detals .under_area.diamond .gauge span {
  background-color: #646b71;
}
#mypage .sec01 .member_detals .up_area.pinkdiamond,
#mypage .sec01 .member_detals .under_area.pinkdiamond {
  background-color: #FAF2F8;
}
#mypage .sec01 .member_detals .up_area.pinkdiamond .txt,
#mypage .sec01 .member_detals .up_area.pinkdiamond .gauge_txt,
#mypage .sec01 .member_detals .under_area.pinkdiamond .txt,
#mypage .sec01 .member_detals .under_area.pinkdiamond .gauge_txt {
  color: #c983b6;
}
#mypage .sec01 .member_detals .up_area.pinkdiamond .gauge span,
#mypage .sec01 .member_detals .under_area.pinkdiamond .gauge span {
  background-color: #c983b6;
}
#mypage .sec01 .member_detals .up_area {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_detals .up_area {
    display: block;
  }
}
#mypage .sec01 .member_detals .under_area .gauge {
  height: clamp( 24px, calc( 24px + (( 100vw - 768px ) * (( 30 - 24 ) / ( 1400 - 768 )))), 30px );
  width: 90%;
  border-radius: 50px;
  background-color: #fff;
  margin: 0 auto clamp( 8px, calc( 8px + (( 100vw - 768px ) * (( 20 - 8 ) / ( 1400 - 768 )))), 20px ) auto;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_detals .under_area .gauge {
    width: 100%;
  }
}
#mypage .sec01 .member_detals .under_area .gauge span {
  height: clamp( 24px, calc( 24px + (( 100vw - 768px ) * (( 30 - 24 ) / ( 1400 - 768 )))), 30px );
  display: block;
  width: 40%;
  border-radius: 50px;
}
#mypage .sec01 .member_detals .under_area .gauge_under {
  position: relative;
  padding-bottom: 60px;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_detals .under_area .gauge_under {
    padding-bottom: 0;
  }
}
#mypage .sec01 .member_detals .under_area .gauge_under .price {
  position: relative;
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px );
}
#mypage .sec01 .member_detals .under_area .gauge_under .price:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 1px;
  height: 12px;
  background-color: #b2b2b2;
  top: -10px;
  left: calc(50% - 0.5px);
  background-size: contain;
  vertical-align: middle;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_detals .under_area .gauge_under .price:before {
    content: none;
  }
}
#mypage .sec01 .member_detals .under_area .gauge_under .price.price_l {
  position: absolute;
  left: 3%;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_detals .under_area .gauge_under .price.price_l {
    margin: 0;
    left: 0;
  }
}
#mypage .sec01 .member_detals .under_area .gauge_under .price.price_r {
  position: absolute;
  right: 3%;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_detals .under_area .gauge_under .price.price_r {
    margin: 0;
    right: 0;
  }
}
#mypage .sec01 .member_detals .under_area .gauge_under .gauge_txt_area {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 5px;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_detals .under_area .gauge_under .gauge_txt_area {
    position: static;
    text-align: center;
    padding-top: 30px;
  }
}
#mypage .sec01 .member_detals .under_area .gauge_under .gauge_txt {
  font-size: clamp( 11px, calc( 11px + (( 100vw - 768px ) * (( 13 - 11 ) / ( 1400 - 768 )))), 13px );
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_detals .under_area .gauge_under .gauge_txt {
    margin-bottom: 15px;
  }
}
#mypage .sec01 .member_detals .under_area .gauge_under .coments {
  font-size: 11px;
  color: #787878;
  text-align: center;
  width: 100%;
  display: block;
  margin-top: -5px;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .member_detals .under_area .gauge_under .coments {
    margin-top: -16px;
  }
}
#mypage .sec01 .benefits_area {
  background-color: #ECF1F5;
  padding: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 50 - 40 ) / ( 1400 - 768 )))), 50px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 50 - 20 ) / ( 1400 - 768 )))), 50px );
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
#mypage .sec01 .benefits_area .info_area {
  width: 46%;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .benefits_area .info_area {
    width: 100%;
  }
}
#mypage .sec01 .benefits_area .info_area h4 {
  color: #717D95;
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 19 - 19 ) / ( 1400 - 768 )))), 19px );
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.15em;
  margin-bottom: 15px;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .benefits_area .info_area h4 {
    text-align: center;
    margin-bottom: 25px;
  }
}
#mypage .sec01 .benefits_area p {
  line-height: 1.5;
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 13 - 12 ) / ( 1400 - 768 )))), 13px );
}
#mypage .sec01 .benefits_area p span {
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 13 - 12 ) / ( 1400 - 768 )))), 13px );
  color: #878A8D;
  display: block;
  margin-top: 5px;
}
#mypage .sec01 .benefits_area img {
  max-width: 630px;
  width: 48%;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec01 .benefits_area img {
    width: 100%;
    margin-bottom: 25px;
    max-width: 100%;
  }
}
#mypage .sec02 {
  background-color: #FAFAFA;
  margin-bottom: 0;
}
#mypage .sec02 .sec_in {
  padding: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 100 - 20 ) / ( 1400 - 768 )))), 100px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 120 - 20 ) / ( 1400 - 768 )))), 120px );
}
@media only screen and (max-width: 1024px) {
  #mypage .sec02 .sec_in {
    padding: 0;
  }
}
#mypage .sec02 .tab_list {
  display: flex;
}
#mypage .sec02 .tab_list li {
  width: 160px;
  line-height: 1;
  text-align: center;
  z-index: 3;
  color: #000;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 18 - 14 ) / ( 1400 - 768 )))), 18px );
  color: #717D95;
  font-weight: 400;
  display: block;
  background-color: #fff;
  height: 64px;
  line-height: 64px;
  cursor: pointer;
  transition: 0.3s;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec02 .tab_list li {
    width: 33.33333%;
    background: none;
  }
}
#mypage .sec02 .tab_list li:not(:last-child) {
  margin-right: 1%;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec02 .tab_list li:not(:last-child) {
    margin-right: 0;
  }
}
#mypage .sec02 .tab_list li:hover {
  background: #f2f2f2;
}
#mypage .sec02 .tab_list li.active {
  border: 1px solid #ddd;
  border-bottom: 1px solid #fff;
  background-color: #fff;
  position: relative;
  z-index: 2;
  bottom: -1px;
}
#mypage .sec02 .panel-group {
  width: 100% !important;
  padding: clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 60 - 25 ) / ( 1400 - 768 )))), 60px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 60 - 20 ) / ( 1400 - 768 )))), 60px ) 60px;
  background-color: #fff;
  position: relative;
  border: 1px solid #ddd;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec02 .panel-group {
    border-left: none;
    border-right: none;
    border-bottom: none;
    padding-bottom: 120px;
  }
}
#mypage .sec02 .panel-group .panel {
  display: none;
}
#mypage .sec02 .panel-group .panel.show {
  display: block;
  -webkit-animation: news_list_animation 1s cubic-bezier(0.22, 1, 0.36, 1) 0s;
  animation: news_list_animation 1s cubic-bezier(0.22, 1, 0.36, 1) 0s;
}
@-webkit-keyframes news_list_animation {
  0% {
    opacity: 0;
    bottom: -20px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
@keyframes news_list_animation {
  0% {
    opacity: 0;
    bottom: -20px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
#mypage .sec02 .panel-group .panel .rank_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#mypage .sec02 .panel-group .panel .rank_list::after {
  content: "";
  display: block;
  width: 31%;
  height: 0;
}
#mypage .sec02 .panel-group .panel .rank_list li {
  width: 31.5%;
  background-color: #F9F9F9;
  border: 1px solid #e9e9e9;
  height: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 88 - 60 ) / ( 1400 - 768 )))), 88px );
  padding: 0 20px;
}
#mypage .sec02 .panel-group .panel .rank_list li:nth-child(n+4) {
  margin-top: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 35 - 20 ) / ( 1400 - 768 )))), 35px );
}
@media only screen and (max-width: 1024px) {
  #mypage .sec02 .panel-group .panel .rank_list li:nth-child(n+4) {
    margin-top: 0;
  }
}
#mypage .sec02 .panel-group .panel .rank_list li p {
  height: 100%;
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px );
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec02 .panel-group .panel .rank_list li p {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 1024px) {
  #mypage .sec02 .panel-group .panel .rank_list li {
    width: 48.5%;
  }
  #mypage .sec02 .panel-group .panel .rank_list li:nth-child(n+3) {
    margin-top: 25px;
  }
}
@media only screen and (max-width: 767px) {
  #mypage .sec02 .panel-group .panel .rank_list li {
    width: 100%;
  }
  #mypage .sec02 .panel-group .panel .rank_list li:nth-child(n+3), #mypage .sec02 .panel-group .panel .rank_list li:nth-child(n+4) {
    margin-top: 0;
  }
  #mypage .sec02 .panel-group .panel .rank_list li:not(:last-child) {
    margin-bottom: 20px;
  }
}
#mypage .sec02 .panel-group .panel .rank_list li.no_active p {
  opacity: 0.2;
}
#mypage .sec02 .panel-group .panel .sec_ttl_jp {
  margin-bottom: 10px;
  padding-top: 40px;
}
#mypage .sec02 .panel-group .panel .sec_ttl_jp + p {
  text-align: center;
  margin-bottom: 80px;
}
#mypage .sec02 .panel-group .panel .box:not(:last-of-type) .a_area {
  margin-bottom: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 120 - 40 ) / ( 1400 - 768 )))), 120px );
}
#mypage .sec02 .panel-group .panel .exclusive_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 65 - 40 ) / ( 1400 - 768 )))), 65px );
}
@media only screen and (max-width: 1024px) {
  #mypage .sec02 .panel-group .panel .exclusive_list {
    overflow-x: auto;
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}
#mypage .sec02 .panel-group .panel .exclusive_list:after {
  content: "";
  display: block;
  width: 31.5%;
  height: 0;
}
#mypage .sec02 .panel-group .panel .exclusive_list .item {
  width: 31.5%;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec02 .panel-group .panel .exclusive_list .item {
    flex-shrink: 0;
    width: 41.5%;
  }
  #mypage .sec02 .panel-group .panel .exclusive_list .item:not(:last-of-type) {
    margin-right: 5%;
  }
}
#mypage .sec02 .panel-group .panel .exclusive_list .item p {
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
}
#mypage .sec02 .panel-group .panel .exclusive_list .item p span {
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px );
  font-weight: 300;
}
#mypage .sec02 .panel-group .panel .exclusive_list .item .p_ttl {
  margin-top: 20px;
  line-height: 1.6;
}
#mypage .sec02 .panel-group .panel .exclusive_list .item .p_ttl.link_blank::after {
  content: "";
  display: inline-block;
  width: 22px;
  height: 23px;
  background-image: url("../../assets/img/common/icon_blank.png");
  background-size: contain;
  vertical-align: middle;
  background-repeat: no-repeat;
  margin: -3px 0 0 7px;
}
#mypage .sec02 .panel-group .panel .exclusive_list .item .p_price {
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
#mypage .sec02 .panel-group .panel .exclusive_list .item .e_price {
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px );
  color: #888c8e;
}
#mypage .sec02 .panel-group .panel .exclusive_list .item a:hover {
  opacity: 0.7;
  text-decoration: underline;
}
#mypage .sec02 .panel-group .panel .exclusive_list .item:nth-of-type(n+4) {
  margin-top: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 40 - 20 ) / ( 1400 - 768 )))), 40px );
}
@media only screen and (max-width: 1024px) {
  #mypage .sec02 .panel-group .panel .exclusive_list .item:nth-of-type(n+4) {
    margin-top: 0;
  }
}
#mypage .sec02 .panel-group .panel .a_area {
  background-color: #f7f0f2;
  padding: 60px clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 60 - 20 ) / ( 1400 - 768 )))), 60px ) clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 60 - 30 ) / ( 1400 - 768 )))), 60px );
}
#mypage .sec02 .panel-group .panel .a_area:not(:last-of-type) {
  margin-bottom: 100px;
}
#mypage .sec02 .panel-group .panel .a_area .coments {
  position: relative;
  padding: 0 clamp( 0px, calc( 0px + (( 100vw - 768px ) * (( 92 - 0 ) / ( 1400 - 768 )))), 92px );
  margin: 0 auto clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 50 - 20 ) / ( 1400 - 768 )))), 50px );
}
#mypage .sec02 .panel-group .panel .a_area .coments::before, #mypage .sec02 .panel-group .panel .a_area .coments::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 43px;
  height: 38px;
  top: 0;
  left: 0;
  background-size: contain;
  vertical-align: middle;
  background: url("../../assets/img/top/txt_obj2.svg") no-repeat center;
  background-size: cover;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec02 .panel-group .panel .a_area .coments::before, #mypage .sec02 .panel-group .panel .a_area .coments::after {
    width: 24px;
    height: 21px;
    top: -35px;
  }
}
#mypage .sec02 .panel-group .panel .a_area .coments::after {
  transform: rotate(-180deg);
  top: auto;
  left: auto;
  right: 0;
  bottom: 0;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec02 .panel-group .panel .a_area .coments::after {
    content: none;
  }
}
#mypage .sec02 .panel-group .panel .a_area .a_profile {
  display: flex;
  align-items: center;
}
#mypage .sec02 .panel-group .panel .a_area .a_profile img {
  width: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 72 - 40 ) / ( 1400 - 768 )))), 72px );
  margin-right: 20px;
  border-radius: 50%;
}
@media only screen and (max-width: 1024px) {
  #mypage .sec02 .panel-group .panel .a_area .a_profile img {
    margin-right: 3%;
  }
}
#mypage .sec02 .panel-group .panel .a_area .a_profile .p_area .name {
  font-weight: 400;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
  line-height: 1.6;
}
#mypage .sec02 .panel-group .panel .a_area .a_profile .p_area .title {
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px );
}
#mypage .sec02 .panel-group .panel dl {
  background-color: #F9F9F9;
  border: 1px solid #d4d4d4;
  padding: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 26 - 16 ) / ( 1400 - 768 )))), 26px ) 25px 0px clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 25 - 18 ) / ( 1400 - 768 )))), 25px );
}
#mypage .sec02 .panel-group .panel dl:not(:last-of-type) {
  margin-bottom: clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 22 - 18 ) / ( 1400 - 768 )))), 22px );
}
#mypage .sec02 .panel-group .panel dl dt {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  padding: 0 clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 70 - 30 ) / ( 1400 - 768 )))), 70px ) clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 22 - 14 ) / ( 1400 - 768 )))), 22px ) 0;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
}
#mypage .sec02 .panel-group .panel dl dt::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 14px;
  top: 18%;
  right: 0;
  background-image: url("../../assets/img/member/icon_open.svg");
  background-size: contain;
  vertical-align: middle;
}
#mypage .sec02 .panel-group .panel dl dt.active::after {
  background-image: url("../../assets/img/member/icon_close.svg");
}
#mypage .sec02 .panel-group .panel dl dd {
  border-top: 1px solid #ddd;
  padding: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px ) 0 clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
  display: none;
}
#mypage .sec02 .panel-group .panel dl dd .month:not(:last-of-type) {
  margin-bottom: clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 40 - 25 ) / ( 1400 - 768 )))), 40px );
}
#mypage .sec02 .panel-group .panel dl dd .month .m_txt {
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
  margin-bottom: 20px;
}
#mypage .sec02 .panel-group .panel dl dd .month ul li {
  display: flex;
  align-items: center;
}
#mypage .sec02 .panel-group .panel dl dd .month ul li:not(:last-child) {
  margin-bottom: 20px;
}
#mypage .sec02 .panel-group .panel dl dd .month ul li img {
  width: clamp( 55px, calc( 55px + (( 100vw - 768px ) * (( 110 - 55 ) / ( 1400 - 768 )))), 110px );
  margin-right: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
}
#mypage .sec02 .panel-group .panel .history_txt {
  text-align: center;
  margin-top: 45px;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
  color: #1F1F1F;
}
#mypage .topic_path_area {
  background-color: #fafafa;
}
@media only screen and (max-width: 1024px) {
  #mypage .topic_path_area {
    background-color: #fff;
  }
}

#setting .sec01,
#password-reset .sec01,
#client .sec01,
#withdrawal-form .sec01,
#auth-number .sec01 {
  max-width: 1345px;
  padding: 0 clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 120 - 20 ) / ( 1400 - 768 )))), 120px );
}
#setting .sec01 .sec_ttl,
#password-reset .sec01 .sec_ttl,
#client .sec01 .sec_ttl,
#withdrawal-form .sec01 .sec_ttl,
#auth-number .sec01 .sec_ttl {
  margin-bottom: clamp( 35px, calc( 35px + (( 100vw - 768px ) * (( 60 - 35 ) / ( 1400 - 768 )))), 60px );
}
#setting .sec01 .sec_in,
#password-reset .sec01 .sec_in,
#client .sec01 .sec_in,
#withdrawal-form .sec01 .sec_in,
#auth-number .sec01 .sec_in {
  width: 100%;
  background-color: #FAFAFA;
  margin: 0 auto;
  padding: clamp( 35px, calc( 35px + (( 100vw - 768px ) * (( 85 - 35 ) / ( 1400 - 768 )))), 85px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 85 - 20 ) / ( 1400 - 768 )))), 85px );
}
#setting .sec01 .sec_in:not(:last-of-type),
#password-reset .sec01 .sec_in:not(:last-of-type),
#client .sec01 .sec_in:not(:last-of-type),
#withdrawal-form .sec01 .sec_in:not(:last-of-type),
#auth-number .sec01 .sec_in:not(:last-of-type) {
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 100 - 30 ) / ( 1400 - 768 )))), 100px );
}
#setting .sec01 .sec_in.s_sec_in,
#password-reset .sec01 .sec_in.s_sec_in,
#client .sec01 .sec_in.s_sec_in,
#withdrawal-form .sec01 .sec_in.s_sec_in,
#auth-number .sec01 .sec_in.s_sec_in {
  padding: 0;
  background: none;
}
#setting .sec01 .sec_in.s_sec_in .c_select_box,
#password-reset .sec01 .sec_in.s_sec_in .c_select_box,
#client .sec01 .sec_in.s_sec_in .c_select_box,
#withdrawal-form .sec01 .sec_in.s_sec_in .c_select_box,
#auth-number .sec01 .sec_in.s_sec_in .c_select_box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#setting .sec01 .sec_in.s_sec_in .c_select_box > div,
#password-reset .sec01 .sec_in.s_sec_in .c_select_box > div,
#client .sec01 .sec_in.s_sec_in .c_select_box > div,
#withdrawal-form .sec01 .sec_in.s_sec_in .c_select_box > div,
#auth-number .sec01 .sec_in.s_sec_in .c_select_box > div {
  width: 48%;
  border: 1px solid #E0E0E0;
  position: relative;
}
#setting .sec01 .sec_in.s_sec_in .c_select_box > div::after,
#password-reset .sec01 .sec_in.s_sec_in .c_select_box > div::after,
#client .sec01 .sec_in.s_sec_in .c_select_box > div::after,
#withdrawal-form .sec01 .sec_in.s_sec_in .c_select_box > div::after,
#auth-number .sec01 .sec_in.s_sec_in .c_select_box > div::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 7px;
  height: 7px;
  top: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 52 - 40 ) / ( 1400 - 768 )))), 52px );
  right: 10%;
  vertical-align: middle;
  border-top: 1px solid #1F1F1F;
  border-right: 1px solid #1F1F1F;
  transform: rotate(45deg);
}
@media only screen and (max-width: 1024px) {
  #setting .sec01 .sec_in.s_sec_in .c_select_box > div::after,
#password-reset .sec01 .sec_in.s_sec_in .c_select_box > div::after,
#client .sec01 .sec_in.s_sec_in .c_select_box > div::after,
#withdrawal-form .sec01 .sec_in.s_sec_in .c_select_box > div::after,
#auth-number .sec01 .sec_in.s_sec_in .c_select_box > div::after {
    right: 5%;
    top: calc(50% - 3.5px);
  }
}
#setting .sec01 .sec_in.s_sec_in .c_select_box > div a,
#password-reset .sec01 .sec_in.s_sec_in .c_select_box > div a,
#client .sec01 .sec_in.s_sec_in .c_select_box > div a,
#withdrawal-form .sec01 .sec_in.s_sec_in .c_select_box > div a,
#auth-number .sec01 .sec_in.s_sec_in .c_select_box > div a {
  padding: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 40 - 20 ) / ( 1400 - 768 )))), 40px );
}
#setting .sec01 .sec_in.s_sec_in .c_select_box > div a:hover,
#password-reset .sec01 .sec_in.s_sec_in .c_select_box > div a:hover,
#client .sec01 .sec_in.s_sec_in .c_select_box > div a:hover,
#withdrawal-form .sec01 .sec_in.s_sec_in .c_select_box > div a:hover,
#auth-number .sec01 .sec_in.s_sec_in .c_select_box > div a:hover {
  opacity: 0.6;
}
@media only screen and (max-width: 1024px) {
  #setting .sec01 .sec_in.s_sec_in .c_select_box > div a,
#password-reset .sec01 .sec_in.s_sec_in .c_select_box > div a,
#client .sec01 .sec_in.s_sec_in .c_select_box > div a,
#withdrawal-form .sec01 .sec_in.s_sec_in .c_select_box > div a,
#auth-number .sec01 .sec_in.s_sec_in .c_select_box > div a {
    padding-right: 30px;
  }
}
#setting .sec01 .sec_in.s_sec_in .c_select_box > div h3,
#password-reset .sec01 .sec_in.s_sec_in .c_select_box > div h3,
#client .sec01 .sec_in.s_sec_in .c_select_box > div h3,
#withdrawal-form .sec01 .sec_in.s_sec_in .c_select_box > div h3,
#auth-number .sec01 .sec_in.s_sec_in .c_select_box > div h3 {
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 19 - 16 ) / ( 1400 - 768 )))), 19px );
  margin-bottom: clamp( 8px, calc( 8px + (( 100vw - 768px ) * (( 12 - 8 ) / ( 1400 - 768 )))), 12px );
  font-weight: 300;
  width: 90%;
  line-height: 1.6;
}
#setting .sec01 .sec_in.s_sec_in .c_select_box > div h3 + p,
#password-reset .sec01 .sec_in.s_sec_in .c_select_box > div h3 + p,
#client .sec01 .sec_in.s_sec_in .c_select_box > div h3 + p,
#withdrawal-form .sec01 .sec_in.s_sec_in .c_select_box > div h3 + p,
#auth-number .sec01 .sec_in.s_sec_in .c_select_box > div h3 + p {
  color: #787878;
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 13 - 12 ) / ( 1400 - 768 )))), 13px );
}
@media only screen and (max-width: 1024px) {
  #setting .sec01 .sec_in.s_sec_in .c_select_box > div,
#password-reset .sec01 .sec_in.s_sec_in .c_select_box > div,
#client .sec01 .sec_in.s_sec_in .c_select_box > div,
#withdrawal-form .sec01 .sec_in.s_sec_in .c_select_box > div,
#auth-number .sec01 .sec_in.s_sec_in .c_select_box > div {
    width: 100%;
  }
  #setting .sec01 .sec_in.s_sec_in .c_select_box > div:first-of-type,
#password-reset .sec01 .sec_in.s_sec_in .c_select_box > div:first-of-type,
#client .sec01 .sec_in.s_sec_in .c_select_box > div:first-of-type,
#withdrawal-form .sec01 .sec_in.s_sec_in .c_select_box > div:first-of-type,
#auth-number .sec01 .sec_in.s_sec_in .c_select_box > div:first-of-type {
    margin-bottom: 20px;
  }
}
#setting .sec01 .sec_in .sec_ttl_jp,
#password-reset .sec01 .sec_in .sec_ttl_jp,
#client .sec01 .sec_in .sec_ttl_jp,
#withdrawal-form .sec01 .sec_in .sec_ttl_jp,
#auth-number .sec01 .sec_in .sec_ttl_jp {
  margin-bottom: 7px;
  line-height: 1.6;
}
#setting .sec01 .sec_in .sec_ttl_jp + p,
#password-reset .sec01 .sec_in .sec_ttl_jp + p,
#client .sec01 .sec_in .sec_ttl_jp + p,
#withdrawal-form .sec01 .sec_in .sec_ttl_jp + p,
#auth-number .sec01 .sec_in .sec_ttl_jp + p {
  text-align: center;
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 60 - 30 ) / ( 1400 - 768 )))), 60px );
}
#setting .sec01 .sec_in .sec_ttl_jp + p.b_none,
#password-reset .sec01 .sec_in .sec_ttl_jp + p.b_none,
#client .sec01 .sec_in .sec_ttl_jp + p.b_none,
#withdrawal-form .sec01 .sec_in .sec_ttl_jp + p.b_none,
#auth-number .sec01 .sec_in .sec_ttl_jp + p.b_none {
  margin-bottom: 0;
}
#setting .sec01 .input_area dl,
#password-reset .sec01 .input_area dl,
#client .sec01 .input_area dl,
#withdrawal-form .sec01 .input_area dl,
#auth-number .sec01 .input_area dl {
  display: flex;
  justify-content: space-between;
}
#setting .sec01 .input_area dl:not(:last-of-type),
#password-reset .sec01 .input_area dl:not(:last-of-type),
#client .sec01 .input_area dl:not(:last-of-type),
#withdrawal-form .sec01 .input_area dl:not(:last-of-type),
#auth-number .sec01 .input_area dl:not(:last-of-type) {
  margin-bottom: clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 30 - 25 ) / ( 1400 - 768 )))), 30px );
}
#setting .sec01 .input_area dl .required,
#password-reset .sec01 .input_area dl .required,
#client .sec01 .input_area dl .required,
#withdrawal-form .sec01 .input_area dl .required,
#auth-number .sec01 .input_area dl .required {
  color: #FF2700;
  font-size: 12px;
  margin: -3px 0 0 6px;
  display: inline-block;
  vertical-align: middle;
}
#setting .sec01 .input_area dl dt,
#password-reset .sec01 .input_area dl dt,
#client .sec01 .input_area dl dt,
#withdrawal-form .sec01 .input_area dl dt,
#auth-number .sec01 .input_area dl dt {
  width: 180px;
  text-align: left;
  line-height: 1.5;
  font-weight: 400;
  padding-top: 17px;
}
@media only screen and (max-width: 1024px) {
  #setting .sec01 .input_area dl dt,
#password-reset .sec01 .input_area dl dt,
#client .sec01 .input_area dl dt,
#withdrawal-form .sec01 .input_area dl dt,
#auth-number .sec01 .input_area dl dt {
    padding-top: 0;
  }
}
#setting .sec01 .input_area dl dt.a_pt,
#password-reset .sec01 .input_area dl dt.a_pt,
#client .sec01 .input_area dl dt.a_pt,
#withdrawal-form .sec01 .input_area dl dt.a_pt,
#auth-number .sec01 .input_area dl dt.a_pt {
  padding-top: 45px;
}
@media only screen and (max-width: 1024px) {
  #setting .sec01 .input_area dl dt.a_pt,
#password-reset .sec01 .input_area dl dt.a_pt,
#client .sec01 .input_area dl dt.a_pt,
#withdrawal-form .sec01 .input_area dl dt.a_pt,
#auth-number .sec01 .input_area dl dt.a_pt {
    padding-top: 0;
  }
}
#setting .sec01 .input_area dl dd,
#password-reset .sec01 .input_area dl dd,
#client .sec01 .input_area dl dd,
#withdrawal-form .sec01 .input_area dl dd,
#auth-number .sec01 .input_area dl dd {
  width: calc(100% - 180px);
}
#setting .sec01 .input_area dl dd.dd_flex,
#password-reset .sec01 .input_area dl dd.dd_flex,
#client .sec01 .input_area dl dd.dd_flex,
#withdrawal-form .sec01 .input_area dl dd.dd_flex,
#auth-number .sec01 .input_area dl dd.dd_flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
#setting .sec01 .input_area dl dd.dd_flex .n_txt,
#password-reset .sec01 .input_area dl dd.dd_flex .n_txt,
#client .sec01 .input_area dl dd.dd_flex .n_txt,
#withdrawal-form .sec01 .input_area dl dd.dd_flex .n_txt,
#auth-number .sec01 .input_area dl dd.dd_flex .n_txt {
  width: 48.5%;
}
#setting .sec01 .input_area dl dd.dd_flex .n_txt:first-of-type,
#password-reset .sec01 .input_area dl dd.dd_flex .n_txt:first-of-type,
#client .sec01 .input_area dl dd.dd_flex .n_txt:first-of-type,
#withdrawal-form .sec01 .input_area dl dd.dd_flex .n_txt:first-of-type,
#auth-number .sec01 .input_area dl dd.dd_flex .n_txt:first-of-type {
  margin-right: 3%;
}
#setting .sec01 .input_area dl dd.pt_t,
#password-reset .sec01 .input_area dl dd.pt_t,
#client .sec01 .input_area dl dd.pt_t,
#withdrawal-form .sec01 .input_area dl dd.pt_t,
#auth-number .sec01 .input_area dl dd.pt_t {
  margin-top: 13px;
}
@media only screen and (max-width: 1024px) {
  #setting .sec01 .input_area dl dd.pt_t,
#password-reset .sec01 .input_area dl dd.pt_t,
#client .sec01 .input_area dl dd.pt_t,
#withdrawal-form .sec01 .input_area dl dd.pt_t,
#auth-number .sec01 .input_area dl dd.pt_t {
    margin-top: 0;
  }
}
#setting .sec01 .input_area dl dd .note,
#password-reset .sec01 .input_area dl dd .note,
#client .sec01 .input_area dl dd .note,
#withdrawal-form .sec01 .input_area dl dd .note,
#auth-number .sec01 .input_area dl dd .note {
  font-size: clamp( 11px, calc( 11px + (( 100vw - 768px ) * (( 13 - 11 ) / ( 1400 - 768 )))), 13px );
  line-height: 1.7;
  display: block;
  margin-top: 10px;
  color: #888c8e;
}
#setting .sec01 .input_area dl dd .select_box,
#password-reset .sec01 .input_area dl dd .select_box,
#client .sec01 .input_area dl dd .select_box,
#withdrawal-form .sec01 .input_area dl dd .select_box,
#auth-number .sec01 .input_area dl dd .select_box {
  width: auto;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  overflow: hidden;
  width: 140px;
  text-align: center;
  position: relative;
}
@media only screen and (max-width: 768px) {
  #setting .sec01 .input_area dl dd .select_box,
#password-reset .sec01 .input_area dl dd .select_box,
#client .sec01 .input_area dl dd .select_box,
#withdrawal-form .sec01 .input_area dl dd .select_box,
#auth-number .sec01 .input_area dl dd .select_box {
    width: 90%;
  }
}
@media only screen and (max-width: 768px) {
  #setting .sec01 .input_area dl dd .select_box:not(:last-of-type),
#password-reset .sec01 .input_area dl dd .select_box:not(:last-of-type),
#client .sec01 .input_area dl dd .select_box:not(:last-of-type),
#withdrawal-form .sec01 .input_area dl dd .select_box:not(:last-of-type),
#auth-number .sec01 .input_area dl dd .select_box:not(:last-of-type) {
    margin: 0 0 12px 0;
  }
}
#setting .sec01 .input_area dl dd .select_box::before,
#password-reset .sec01 .input_area dl dd .select_box::before,
#client .sec01 .input_area dl dd .select_box::before,
#withdrawal-form .sec01 .input_area dl dd .select_box::before,
#auth-number .sec01 .input_area dl dd .select_box::before {
  position: absolute;
  top: calc(50% - 5px);
  right: 18px;
  width: 7px;
  height: 7px;
  content: "";
  border-top: 2px solid #ccc;
  border-right: 2px solid #ccc;
  transform: rotate(135deg);
  pointer-events: none;
}
#setting .sec01 .input_area dl dd .select_box select,
#password-reset .sec01 .input_area dl dd .select_box select,
#client .sec01 .input_area dl dd .select_box select,
#withdrawal-form .sec01 .input_area dl dd .select_box select,
#auth-number .sec01 .input_area dl dd .select_box select {
  height: 56px;
  line-height: 56px;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  background: transparent;
  background-image: none;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 2em 0 1em;
  color: #333;
}
#setting .sec01 .input_area dl dd .select_box select::-ms-expand,
#password-reset .sec01 .input_area dl dd .select_box select::-ms-expand,
#client .sec01 .input_area dl dd .select_box select::-ms-expand,
#withdrawal-form .sec01 .input_area dl dd .select_box select::-ms-expand,
#auth-number .sec01 .input_area dl dd .select_box select::-ms-expand {
  display: none;
}
@media only screen and (max-width: 1024px) {
  #setting .sec01 .input_area dl dd .select_box.a_select_box,
#password-reset .sec01 .input_area dl dd .select_box.a_select_box,
#client .sec01 .input_area dl dd .select_box.a_select_box,
#withdrawal-form .sec01 .input_area dl dd .select_box.a_select_box,
#auth-number .sec01 .input_area dl dd .select_box.a_select_box {
    width: 43.5%;
  }
}
#setting .sec01 .input_area dl dd .dob,
#password-reset .sec01 .input_area dl dd .dob,
#client .sec01 .input_area dl dd .dob,
#withdrawal-form .sec01 .input_area dl dd .dob,
#auth-number .sec01 .input_area dl dd .dob {
  display: block;
  margin: 0 clamp( 4px, calc( 4px + (( 100vw - 768px ) * (( 15 - 4 ) / ( 1400 - 768 )))), 15px ) 0 clamp( 4px, calc( 4px + (( 100vw - 768px ) * (( 15 - 4 ) / ( 1400 - 768 )))), 15px );
  vertical-align: middle;
}
@media only screen and (max-width: 768px) {
  #setting .sec01 .input_area dl dd .dob,
#password-reset .sec01 .input_area dl dd .dob,
#client .sec01 .input_area dl dd .dob,
#withdrawal-form .sec01 .input_area dl dd .dob,
#auth-number .sec01 .input_area dl dd .dob {
    margin: -5px 0 0 3%;
  }
}
#setting .sec01 .input_area dl .dd_columns,
#password-reset .sec01 .input_area dl .dd_columns,
#client .sec01 .input_area dl .dd_columns,
#withdrawal-form .sec01 .input_area dl .dd_columns,
#auth-number .sec01 .input_area dl .dd_columns {
  width: calc(100% - 180px);
}
@media only screen and (max-width: 768px) {
  #setting .sec01 .input_area dl .dd_columns,
#password-reset .sec01 .input_area dl .dd_columns,
#client .sec01 .input_area dl .dd_columns,
#withdrawal-form .sec01 .input_area dl .dd_columns,
#auth-number .sec01 .input_area dl .dd_columns {
    width: 100%;
  }
}
#setting .sec01 .input_area dl .dd_columns dd,
#password-reset .sec01 .input_area dl .dd_columns dd,
#client .sec01 .input_area dl .dd_columns dd,
#withdrawal-form .sec01 .input_area dl .dd_columns dd,
#auth-number .sec01 .input_area dl .dd_columns dd {
  width: 100%;
}
#setting .sec01 .input_area dl .dd_columns dd:not(:last-of-type),
#password-reset .sec01 .input_area dl .dd_columns dd:not(:last-of-type),
#client .sec01 .input_area dl .dd_columns dd:not(:last-of-type),
#withdrawal-form .sec01 .input_area dl .dd_columns dd:not(:last-of-type),
#auth-number .sec01 .input_area dl .dd_columns dd:not(:last-of-type) {
  margin-bottom: clamp( 10px, calc( 10px + (( 100vw - 768px ) * (( 15 - 10 ) / ( 1400 - 768 )))), 15px );
}
#setting .sec01 .input_area dl .dd_columns dd .a_txt,
#password-reset .sec01 .input_area dl .dd_columns dd .a_txt,
#client .sec01 .input_area dl .dd_columns dd .a_txt,
#withdrawal-form .sec01 .input_area dl .dd_columns dd .a_txt,
#auth-number .sec01 .input_area dl .dd_columns dd .a_txt {
  font-size: clamp( 11px, calc( 11px + (( 100vw - 768px ) * (( 14 - 11 ) / ( 1400 - 768 )))), 14px );
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  #setting .sec01 .input_area,
#password-reset .sec01 .input_area,
#client .sec01 .input_area,
#withdrawal-form .sec01 .input_area,
#auth-number .sec01 .input_area {
    width: 100%;
  }
  #setting .sec01 .input_area dl,
#password-reset .sec01 .input_area dl,
#client .sec01 .input_area dl,
#withdrawal-form .sec01 .input_area dl,
#auth-number .sec01 .input_area dl {
    flex-wrap: wrap;
  }
  #setting .sec01 .input_area dl dt,
#password-reset .sec01 .input_area dl dt,
#client .sec01 .input_area dl dt,
#withdrawal-form .sec01 .input_area dl dt,
#auth-number .sec01 .input_area dl dt {
    width: 100%;
    margin-bottom: 10px;
  }
  #setting .sec01 .input_area dl dt .required,
#password-reset .sec01 .input_area dl dt .required,
#client .sec01 .input_area dl dt .required,
#withdrawal-form .sec01 .input_area dl dt .required,
#auth-number .sec01 .input_area dl dt .required {
    font-size: 10px;
    margin-left: 10px;
  }
  #setting .sec01 .input_area dl dd,
#password-reset .sec01 .input_area dl dd,
#client .sec01 .input_area dl dd,
#withdrawal-form .sec01 .input_area dl dd,
#auth-number .sec01 .input_area dl dd {
    width: 100%;
  }
}
#setting .sec01 .input_area input[type=submit],
#password-reset .sec01 .input_area input[type=submit],
#client .sec01 .input_area input[type=submit],
#withdrawal-form .sec01 .input_area input[type=submit],
#auth-number .sec01 .input_area input[type=submit] {
  margin-top: clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 60 - 25 ) / ( 1400 - 768 )))), 60px );
}
#setting .sec01 .btn,
#password-reset .sec01 .btn,
#client .sec01 .btn,
#withdrawal-form .sec01 .btn,
#auth-number .sec01 .btn {
  margin-top: clamp( 45px, calc( 45px + (( 100vw - 768px ) * (( 60 - 45 ) / ( 1400 - 768 )))), 60px );
}
@media only screen and (max-width: 1024px) {
  #setting .topic_path_area,
#password-reset .topic_path_area,
#client .topic_path_area,
#withdrawal-form .topic_path_area,
#auth-number .topic_path_area {
    background-color: #fff;
  }
}
#setting #contact .error_box,
#password-reset #contact .error_box,
#client #contact .error_box,
#withdrawal-form #contact .error_box,
#auth-number #contact .error_box {
  background-color: #f7f0f2;
  border: 1px solid #e05a5a;
}
#setting #contact .sec_in,
#password-reset #contact .sec_in,
#client #contact .sec_in,
#withdrawal-form #contact .sec_in,
#auth-number #contact .sec_in {
  padding: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 80 - 20 ) / ( 1400 - 768 )))), 80px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 85 - 20 ) / ( 1400 - 768 )))), 85px );
}
#setting #contact .sec_in:first-of-type,
#password-reset #contact .sec_in:first-of-type,
#client #contact .sec_in:first-of-type,
#withdrawal-form #contact .sec_in:first-of-type,
#auth-number #contact .sec_in:first-of-type {
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 80 - 20 ) / ( 1400 - 768 )))), 80px );
}
#setting #contact .sec_ttl_jp,
#password-reset #contact .sec_ttl_jp,
#client #contact .sec_ttl_jp,
#withdrawal-form #contact .sec_ttl_jp,
#auth-number #contact .sec_ttl_jp {
  position: relative;
  font-size: clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 28 - 18 ) / ( 1400 - 768 )))), 28px );
  margin-bottom: 4px;
}
@media only screen and (max-width: 1024px) {
  #setting #contact .sec_ttl_jp,
#password-reset #contact .sec_ttl_jp,
#client #contact .sec_ttl_jp,
#withdrawal-form #contact .sec_ttl_jp,
#auth-number #contact .sec_ttl_jp {
    margin-bottom: 8px;
  }
}
#setting #contact .sec_ttl_jp:before,
#password-reset #contact .sec_ttl_jp:before,
#client #contact .sec_ttl_jp:before,
#withdrawal-form #contact .sec_ttl_jp:before,
#auth-number #contact .sec_ttl_jp:before {
  content: "";
  display: inline-block;
  width: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 27 - 19 ) / ( 1400 - 768 )))), 27px );
  height: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 23 - 16 ) / ( 1400 - 768 )))), 23px );
  top: calc(50% - 11.5px);
  background-image: url("../../assets/img/contact/icon_mail.svg");
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: -5px 14px 0 0;
}
@media only screen and (max-width: 1024px) {
  #setting #contact .sec_ttl_jp:before,
#password-reset #contact .sec_ttl_jp:before,
#client #contact .sec_ttl_jp:before,
#withdrawal-form #contact .sec_ttl_jp:before,
#auth-number #contact .sec_ttl_jp:before {
    margin: -1px 10px 0 0;
  }
}
#setting #contact .sec_ttl_jp.p_sec_ttl_jp:before,
#password-reset #contact .sec_ttl_jp.p_sec_ttl_jp:before,
#client #contact .sec_ttl_jp.p_sec_ttl_jp:before,
#withdrawal-form #contact .sec_ttl_jp.p_sec_ttl_jp:before,
#auth-number #contact .sec_ttl_jp.p_sec_ttl_jp:before {
  content: "";
  display: inline-block;
  width: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 28 - 20 ) / ( 1400 - 768 )))), 28px );
  height: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 28 - 20 ) / ( 1400 - 768 )))), 28px );
  top: calc(50% - 11.5px);
  background-image: url("../../assets/img/contact/icon_phone.svg");
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  margin: -5px 14px 0 0;
}
@media only screen and (max-width: 1024px) {
  #setting #contact .sec_ttl_jp.p_sec_ttl_jp:before,
#password-reset #contact .sec_ttl_jp.p_sec_ttl_jp:before,
#client #contact .sec_ttl_jp.p_sec_ttl_jp:before,
#withdrawal-form #contact .sec_ttl_jp.p_sec_ttl_jp:before,
#auth-number #contact .sec_ttl_jp.p_sec_ttl_jp:before {
    margin: -1px 10px 0 0;
  }
}
#setting #contact .btn,
#password-reset #contact .btn,
#client #contact .btn,
#withdrawal-form #contact .btn,
#auth-number #contact .btn {
  margin-top: clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 30 - 25 ) / ( 1400 - 768 )))), 30px );
}
#setting #contact .privacy_area,
#password-reset #contact .privacy_area,
#client #contact .privacy_area,
#withdrawal-form #contact .privacy_area,
#auth-number #contact .privacy_area {
  height: 300px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  padding: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 40 - 20 ) / ( 1400 - 768 )))), 40px );
  text-align: left;
  overflow-y: scroll;
  font-size: clamp( 13px, calc( 13px + (( 100vw - 768px ) * (( 14 - 13 ) / ( 1400 - 768 )))), 14px );
  line-height: 1.6em;
  letter-spacing: 0.05em;
  margin: 20px 0 40px;
}
#setting #contact .privacy_area h4,
#password-reset #contact .privacy_area h4,
#client #contact .privacy_area h4,
#withdrawal-form #contact .privacy_area h4,
#auth-number #contact .privacy_area h4 {
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 20 - 16 ) / ( 1400 - 768 )))), 20px );
  margin: 25px 0 15px;
  font-weight: 400;
}
#setting #contact .privacy_area dt,
#password-reset #contact .privacy_area dt,
#client #contact .privacy_area dt,
#withdrawal-form #contact .privacy_area dt,
#auth-number #contact .privacy_area dt {
  margin-top: 1em;
  font-weight: 400;
}
#setting #contact .privacy_area dd,
#password-reset #contact .privacy_area dd,
#client #contact .privacy_area dd,
#withdrawal-form #contact .privacy_area dd,
#auth-number #contact .privacy_area dd {
  margin-top: 0.5em;
}
#setting #contact .privacy_area .establishment,
#password-reset #contact .privacy_area .establishment,
#client #contact .privacy_area .establishment,
#withdrawal-form #contact .privacy_area .establishment,
#auth-number #contact .privacy_area .establishment {
  text-align: right;
  margin-top: 50px;
}
#setting #contact .agree_check,
#password-reset #contact .agree_check,
#client #contact .agree_check,
#withdrawal-form #contact .agree_check,
#auth-number #contact .agree_check {
  text-align: center;
}
#setting #contact .mw_wp_form_confirm .input_area dl,
#password-reset #contact .mw_wp_form_confirm .input_area dl,
#client #contact .mw_wp_form_confirm .input_area dl,
#withdrawal-form #contact .mw_wp_form_confirm .input_area dl,
#auth-number #contact .mw_wp_form_confirm .input_area dl {
  border-bottom: 1px solid #ddd;
  padding-bottom: 27px;
  align-items: center;
}
#setting #contact .mw_wp_form_confirm .input_area dl dt,
#password-reset #contact .mw_wp_form_confirm .input_area dl dt,
#client #contact .mw_wp_form_confirm .input_area dl dt,
#withdrawal-form #contact .mw_wp_form_confirm .input_area dl dt,
#auth-number #contact .mw_wp_form_confirm .input_area dl dt {
  padding-top: 0;
}
#setting #contact .mw_wp_form_confirm .privacy_area,
#password-reset #contact .mw_wp_form_confirm .privacy_area,
#client #contact .mw_wp_form_confirm .privacy_area,
#withdrawal-form #contact .mw_wp_form_confirm .privacy_area,
#auth-number #contact .mw_wp_form_confirm .privacy_area {
  display: none;
}
#setting #contact .mw_wp_form_confirm .btn_area_c_flex,
#password-reset #contact .mw_wp_form_confirm .btn_area_c_flex,
#client #contact .mw_wp_form_confirm .btn_area_c_flex,
#withdrawal-form #contact .mw_wp_form_confirm .btn_area_c_flex,
#auth-number #contact .mw_wp_form_confirm .btn_area_c_flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
#setting #contact .mw_wp_form_confirm .btn_area_c_flex button,
#password-reset #contact .mw_wp_form_confirm .btn_area_c_flex button,
#client #contact .mw_wp_form_confirm .btn_area_c_flex button,
#withdrawal-form #contact .mw_wp_form_confirm .btn_area_c_flex button,
#auth-number #contact .mw_wp_form_confirm .btn_area_c_flex button {
  margin-right: 20px;
}
@media only screen and (max-width: 1024px) {
  #setting #contact .mw_wp_form_confirm .btn_area_c_flex button,
#password-reset #contact .mw_wp_form_confirm .btn_area_c_flex button,
#client #contact .mw_wp_form_confirm .btn_area_c_flex button,
#withdrawal-form #contact .mw_wp_form_confirm .btn_area_c_flex button,
#auth-number #contact .mw_wp_form_confirm .btn_area_c_flex button {
    margin: 30px 0 0 0;
    width: 100%;
  }
  #setting #contact .mw_wp_form_confirm .btn_area_c_flex button + input[type=submit],
#password-reset #contact .mw_wp_form_confirm .btn_area_c_flex button + input[type=submit],
#client #contact .mw_wp_form_confirm .btn_area_c_flex button + input[type=submit],
#withdrawal-form #contact .mw_wp_form_confirm .btn_area_c_flex button + input[type=submit],
#auth-number #contact .mw_wp_form_confirm .btn_area_c_flex button + input[type=submit] {
    margin-top: 15px;
    width: 100%;
  }
}
#setting #contact .mw_wp_form_confirm .dd_columns dd,
#password-reset #contact .mw_wp_form_confirm .dd_columns dd,
#client #contact .mw_wp_form_confirm .dd_columns dd,
#withdrawal-form #contact .mw_wp_form_confirm .dd_columns dd,
#auth-number #contact .mw_wp_form_confirm .dd_columns dd {
  margin-bottom: 0;
}
#setting #contact .mw_wp_form_confirm .dd_columns dd:last-of-type,
#password-reset #contact .mw_wp_form_confirm .dd_columns dd:last-of-type,
#client #contact .mw_wp_form_confirm .dd_columns dd:last-of-type,
#withdrawal-form #contact .mw_wp_form_confirm .dd_columns dd:last-of-type,
#auth-number #contact .mw_wp_form_confirm .dd_columns dd:last-of-type {
  display: none;
}
#setting #contact .thanks_sec .sec_ttl_jp,
#password-reset #contact .thanks_sec .sec_ttl_jp,
#client #contact .thanks_sec .sec_ttl_jp,
#withdrawal-form #contact .thanks_sec .sec_ttl_jp,
#auth-number #contact .thanks_sec .sec_ttl_jp {
  margin-bottom: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 25 - 15 ) / ( 1400 - 768 )))), 25px ) !important;
}
#setting #contact .thanks_sec .sec_ttl_jp:before,
#password-reset #contact .thanks_sec .sec_ttl_jp:before,
#client #contact .thanks_sec .sec_ttl_jp:before,
#withdrawal-form #contact .thanks_sec .sec_ttl_jp:before,
#auth-number #contact .thanks_sec .sec_ttl_jp:before {
  content: none;
}
#setting #contact .thanks_sec .sec_ttl_jp + p,
#password-reset #contact .thanks_sec .sec_ttl_jp + p,
#client #contact .thanks_sec .sec_ttl_jp + p,
#withdrawal-form #contact .thanks_sec .sec_ttl_jp + p,
#auth-number #contact .thanks_sec .sec_ttl_jp + p {
  margin-bottom: 0 !important;
}
#setting #contact .thanks_sec .btn,
#password-reset #contact .thanks_sec .btn,
#client #contact .thanks_sec .btn,
#withdrawal-form #contact .thanks_sec .btn,
#auth-number #contact .thanks_sec .btn {
  border: 1px solid #717d95;
}
#setting #contact .phone_area,
#password-reset #contact .phone_area,
#client #contact .phone_area,
#withdrawal-form #contact .phone_area,
#auth-number #contact .phone_area {
  width: 100%;
  background-color: #FAFAFA;
  margin: 0 auto;
  text-align: center;
}
#setting #contact .phone_area .p_number,
#password-reset #contact .phone_area .p_number,
#client #contact .phone_area .p_number,
#withdrawal-form #contact .phone_area .p_number,
#auth-number #contact .phone_area .p_number {
  font-size: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 26 - 20 ) / ( 1400 - 768 )))), 26px );
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.12em;
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 1024px) {
  #setting #contact .phone_area .p_number,
#password-reset #contact .phone_area .p_number,
#client #contact .phone_area .p_number,
#withdrawal-form #contact .phone_area .p_number,
#auth-number #contact .phone_area .p_number {
    margin-bottom: 7px !important;
  }
}
#setting .modaal-container,
#password-reset .modaal-container,
#client .modaal-container,
#withdrawal-form .modaal-container,
#auth-number .modaal-container {
  background: #fff;
  border-radius: 0;
  position: relative;
  padding: 5% 5% 3.5%;
  width: 100%;
  min-height: 400px;
}
@media only screen and (max-width: 1024px) {
  #setting .modaal-container,
#password-reset .modaal-container,
#client .modaal-container,
#withdrawal-form .modaal-container,
#auth-number .modaal-container {
    padding: 13% 5% 12%;
  }
}
@media only screen and (max-width: 1024px) {
  #setting .modaal-container,
#password-reset .modaal-container,
#client .modaal-container,
#withdrawal-form .modaal-container,
#auth-number .modaal-container {
    width: 100%;
  }
}
#setting .modaal-container h3,
#password-reset .modaal-container h3,
#client .modaal-container h3,
#withdrawal-form .modaal-container h3,
#auth-number .modaal-container h3 {
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 32 - 19 ) / ( 1400 - 768 )))), 32px );
  text-align: center;
  margin-bottom: 10px;
  font-weight: 400;
}
@media only screen and (max-width: 1024px) {
  #setting .modaal-container h3,
#password-reset .modaal-container h3,
#client .modaal-container h3,
#withdrawal-form .modaal-container h3,
#auth-number .modaal-container h3 {
    margin-bottom: 15px;
    line-height: 1.6;
  }
}
#setting .modaal-container h3 + p,
#password-reset .modaal-container h3 + p,
#client .modaal-container h3 + p,
#withdrawal-form .modaal-container h3 + p,
#auth-number .modaal-container h3 + p {
  text-align: center;
  margin-bottom: clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 50 - 25 ) / ( 1400 - 768 )))), 50px );
}
@media only screen and (max-width: 1024px) {
  #setting .modaal-container h3 + p,
#password-reset .modaal-container h3 + p,
#client .modaal-container h3 + p,
#withdrawal-form .modaal-container h3 + p,
#auth-number .modaal-container h3 + p {
    text-align: left;
  }
}
#setting .modaal-container dl,
#password-reset .modaal-container dl,
#client .modaal-container dl,
#withdrawal-form .modaal-container dl,
#auth-number .modaal-container dl {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
#setting .modaal-container dl:not(:last-of-type),
#password-reset .modaal-container dl:not(:last-of-type),
#client .modaal-container dl:not(:last-of-type),
#withdrawal-form .modaal-container dl:not(:last-of-type),
#auth-number .modaal-container dl:not(:last-of-type) {
  margin-bottom: 25px;
}
@media only screen and (max-width: 860px) {
  #setting .modaal-container dl dt,
#password-reset .modaal-container dl dt,
#client .modaal-container dl dt,
#withdrawal-form .modaal-container dl dt,
#auth-number .modaal-container dl dt {
    margin-bottom: 10px;
  }
}
#setting .modaal-container dl dt.phone_txt,
#password-reset .modaal-container dl dt.phone_txt,
#client .modaal-container dl dt.phone_txt,
#withdrawal-form .modaal-container dl dt.phone_txt,
#auth-number .modaal-container dl dt.phone_txt {
  margin-bottom: 0;
}
#setting .modaal-container dl dd,
#password-reset .modaal-container dl dd,
#client .modaal-container dl dd,
#withdrawal-form .modaal-container dl dd,
#auth-number .modaal-container dl dd {
  width: 70%;
}
@media only screen and (max-width: 860px) {
  #setting .modaal-container dl dd,
#password-reset .modaal-container dl dd,
#client .modaal-container dl dd,
#withdrawal-form .modaal-container dl dd,
#auth-number .modaal-container dl dd {
    width: 100%;
  }
}
#setting .modaal-container .btn,
#password-reset .modaal-container .btn,
#client .modaal-container .btn,
#withdrawal-form .modaal-container .btn,
#auth-number .modaal-container .btn {
  margin-top: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 60 - 40 ) / ( 1400 - 768 )))), 60px );
}
#setting .modaal-container .txt_link,
#password-reset .modaal-container .txt_link,
#client .modaal-container .txt_link,
#withdrawal-form .modaal-container .txt_link,
#auth-number .modaal-container .txt_link {
  display: block;
  margin-top: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 25 - 15 ) / ( 1400 - 768 )))), 25px );
  color: #717D95;
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px );
}
#setting .modaal-container .code_list,
#password-reset .modaal-container .code_list,
#client .modaal-container .code_list,
#withdrawal-form .modaal-container .code_list,
#auth-number .modaal-container .code_list {
  display: flex;
  justify-content: center;
}
#setting .modaal-container .code_list input,
#password-reset .modaal-container .code_list input,
#client .modaal-container .code_list input,
#withdrawal-form .modaal-container .code_list input,
#auth-number .modaal-container .code_list input {
  text-align: center;
  max-width: 56px;
  max-height: 56px;
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 20 - 16 ) / ( 1400 - 768 )))), 20px );
}
#setting .modaal-container .code_list input:not(:last-child),
#password-reset .modaal-container .code_list input:not(:last-child),
#client .modaal-container .code_list input:not(:last-child),
#withdrawal-form .modaal-container .code_list input:not(:last-child),
#auth-number .modaal-container .code_list input:not(:last-child) {
  margin-right: 2%;
}
#setting .modaal-container .note_area,
#password-reset .modaal-container .note_area,
#client .modaal-container .note_area,
#withdrawal-form .modaal-container .note_area,
#auth-number .modaal-container .note_area {
  color: #797979;
  border-top: 1px solid #CDCDCD;
  padding-top: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 40 - 20 ) / ( 1400 - 768 )))), 40px );
  margin-top: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 80 - 40 ) / ( 1400 - 768 )))), 80px );
  font-size: clamp( 11px, calc( 11px + (( 100vw - 768px ) * (( 13 - 11 ) / ( 1400 - 768 )))), 13px );
}
#setting .modaal-container .note_area p:first-child,
#password-reset .modaal-container .note_area p:first-child,
#client .modaal-container .note_area p:first-child,
#withdrawal-form .modaal-container .note_area p:first-child,
#auth-number .modaal-container .note_area p:first-child {
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
}
#setting .modaal-container .note_area ol,
#password-reset .modaal-container .note_area ol,
#client .modaal-container .note_area ol,
#withdrawal-form .modaal-container .note_area ol,
#auth-number .modaal-container .note_area ol {
  list-style-type: decimal;
  padding-left: 16px;
}
#setting .code:focus,
#password-reset .code:focus,
#client .code:focus,
#withdrawal-form .code:focus,
#auth-number .code:focus {
  outline: auto;
}
#setting .phone_number,
#password-reset .phone_number,
#client .phone_number,
#withdrawal-form .phone_number,
#auth-number .phone_number {
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 20 - 16 ) / ( 1400 - 768 )))), 20px );
  display: block;
  padding-top: 8px;
}
@media only screen and (max-width: 1024px) {
  #setting .phone_number,
#password-reset .phone_number,
#client .phone_number,
#withdrawal-form .phone_number,
#auth-number .phone_number {
    padding-top: 0;
  }
}
#setting .sms_note,
#password-reset .sms_note,
#client .sms_note,
#withdrawal-form .sms_note,
#auth-number .sms_note {
  line-height: 1.7;
  display: block;
  color: #e05a5a;
}

#info .sec01 .sec_in .info_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#info .sec01 .sec_in .info_list:before, #info .sec01 .sec_in .info_list::after {
  content: "";
  display: block;
  width: 23%;
  height: 0;
}
@media only screen and (max-width: 1024px) {
  #info .sec01 .sec_in .info_list:before, #info .sec01 .sec_in .info_list::after {
    width: 31.5%;
  }
}
#info .sec01 .sec_in .info_list:before {
  order: 1;
}
#info .sec01 .sec_in .info_list li {
  width: 23%;
  position: relative;
}
@media only screen and (max-width: 1024px) {
  #info .sec01 .sec_in .info_list li {
    width: 31.5%;
  }
  #info .sec01 .sec_in .info_list li:nth-child(n+4) {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 767px) {
  #info .sec01 .sec_in .info_list li:nth-child(n+4) {
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  #info .sec01 .sec_in .info_list li {
    width: 100%;
  }
  #info .sec01 .sec_in .info_list li:not(:last-child) {
    margin-bottom: 30px;
  }
}
#info .sec01 .sec_in .info_list li a:hover {
  opacity: 0.7;
  text-decoration: underline;
}
#info .sec01 .sec_in .info_list li figure {
  position: relative;
}
#info .sec01 .sec_in .info_list li figure img {
  height: auto;
}
#info .sec01 .sec_in .info_list li:nth-child(n+5) {
  margin-top: 35px;
}
@media only screen and (max-width: 1024px) {
  #info .sec01 .sec_in .info_list li:nth-child(n+5) {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 1024px) and (max-width: 767px) {
  #info .sec01 .sec_in .info_list li:nth-child(n+5) {
    margin-top: 0;
  }
}
#info .sec01 .sec_in .info_list li .d_head {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1024px) {
  #info .sec01 .sec_in .info_list li .d_head {
    margin: 13px 0 7px;
  }
}
#info .sec01 .sec_in .info_list li time {
  margin: 10px 5px 0 0;
  display: block;
  font-family: "Poppins", sans-serif;
}
@media only screen and (max-width: 1024px) {
  #info .sec01 .sec_in .info_list li time {
    margin: 0 8px 0 0;
  }
}
#info .sec01 .sec_in .info_list li .cat {
  position: absolute;
  left: 10px;
  display: block;
  background-color: #fff;
  bottom: clamp( 7px, calc( 7px + (( 100vw - 768px ) * (( 10 - 7 ) / ( 1400 - 768 )))), 10px );
  height: clamp( 24px, calc( 24px + (( 100vw - 768px ) * (( 32 - 24 ) / ( 1400 - 768 )))), 32px );
  line-height: clamp( 22px, calc( 22px + (( 100vw - 768px ) * (( 33 - 22 ) / ( 1400 - 768 )))), 33px );
  letter-spacing: 0.01em;
  text-align: center;
  padding: 0 10px;
  font-size: clamp( 11px, calc( 11px + (( 100vw - 768px ) * (( 13 - 11 ) / ( 1400 - 768 )))), 13px );
}
@media only screen and (max-width: 1024px) {
  #info .sec01 .sec_in .info_list li .cat {
    position: static;
    border: 1px solid #e9ebee;
    margin-top: -2px;
  }
}
#info .sec01 .sec_in .info_list li h3 {
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 16 - 14 ) / ( 1400 - 768 )))), 16px );
  font-weight: 300;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 1.6;
}
@media only screen and (max-width: 1024px) {
  #info.info_single .mainSection {
    margin-top: 0;
  }
}
#info .info_d_sec .sec_in {
  max-width: 1168px;
  width: 100%;
  margin: 0 auto;
  padding: 0 clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
}
#info .info_d_sec img {
  height: auto;
  width: auto;
}
@media only screen and (max-width: 1024px) {
  #info .info_d_sec img {
    width: 100%;
  }
}
#info .info_d_sec figure {
  margin-bottom: 30px;
}
@media only screen and (max-width: 1024px) {
  #info .info_d_sec figure {
    margin: 0 calc(50% - 50vw) 20px;
    width: 100vw;
  }
}
#info .info_d_sec figure img {
  height: auto;
  width: 100%;
}
#info .info_d_sec .info_ttl {
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 32 - 19 ) / ( 1400 - 768 )))), 32px );
  letter-spacing: 0.16em;
  font-weight: 300;
  color: #1F1F1F;
  line-height: 1.8;
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
}
@media only screen and (max-width: 1024px) {
  #info .info_d_sec .info_ttl {
    line-height: 1.6;
    margin-top: 80px;
  }
}
@media only screen and (max-width: 1024px) {
  #info .info_d_sec figure + .info_ttl {
    margin-top: 0;
  }
}
#info .info_d_sec p {
  line-height: 2.1;
}
#info .info_d_sec p + p {
  margin-top: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 35 - 30 ) / ( 1400 - 768 )))), 35px );
}
#info .info_d_sec p a {
  color: #717D95;
  text-decoration: underline;
  display: inline-block;
}
#info .info_d_sec p a span {
  color: #717D95 !important;
}
#info .info_d_sec p a:hover {
  text-decoration: none;
}

#about .mainSection {
  margin-top: 0;
}
#about .sec01 {
  background: url("../../assets/img/about/sec01_bg.jpg") no-repeat center;
  background-size: cover;
  padding: clamp( 50px, calc( 50px + (( 100vw - 768px ) * (( 120 - 50 ) / ( 1400 - 768 )))), 120px ) 0;
  margin-bottom: clamp( 50px, calc( 50px + (( 100vw - 768px ) * (( 120 - 50 ) / ( 1400 - 768 )))), 120px );
}
@media only screen and (max-width: 1024px) {
  #about .sec01 {
    background: url("../../assets/img/about/sec01_bg_sp.jpg") no-repeat center;
    background-size: cover;
  }
}
#about .sec01 .box {
  background-color: rgba(255, 255, 255, 0.98);
  padding: clamp( 50px, calc( 50px + (( 100vw - 768px ) * (( 120 - 50 ) / ( 1400 - 768 )))), 120px ) clamp( 24px, calc( 24px + (( 100vw - 768px ) * (( 120 - 24 ) / ( 1400 - 768 )))), 120px );
}
#about .sec01 .box h4 {
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 30 - 19 ) / ( 1400 - 768 )))), 30px );
  line-height: 2.1;
  letter-spacing: 0.1em;
  font-weight: 100;
  text-align: center;
  font-family: fot-tsukumin-pr6n, sans-serif;
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 80 - 30 ) / ( 1400 - 768 )))), 80px );
}
@media only screen and (max-width: 1024px) {
  #about .sec01 .box h4 {
    line-height: 1.5;
  }
}
#about .sec01 .box p {
  text-align: center;
  line-height: 3.2;
}
@media only screen and (max-width: 1024px) {
  #about .sec01 .box p {
    text-align: left;
    line-height: 2;
  }
}
#about .sec01 .box p + p {
  margin-top: clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 55 - 25 ) / ( 1400 - 768 )))), 55px );
}
@media only screen and (max-width: 1024px) {
  #about .sec01 .box .btn_area_c {
    text-align: center;
  }
}
#about .sec01 .box .btn {
  border: 1px solid #d3d7e0;
  width: 240px;
}
@media only screen and (max-width: 1024px) {
  #about .sec01 .box .btn {
    width: auto;
    padding: 0 20px;
    display: inline-block;
  }
}
#about .sec02 .sec_in {
  max-width: 1168px;
}
#about .sec02 .sec_in .sec_ttl_jp {
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 60 - 30 ) / ( 1400 - 768 )))), 60px );
}
#about .sec02 dl {
  display: flex;
  border-bottom: 1px solid #e9e9e9;
  justify-content: space-between;
  margin-bottom: clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 28 - 18 ) / ( 1400 - 768 )))), 28px );
  padding-bottom: clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 28 - 18 ) / ( 1400 - 768 )))), 28px );
}
#about .sec02 dl:last-of-type {
  margin-bottom: 0;
}
#about .sec02 dl dt {
  width: 15%;
}
#about .sec02 dl dd {
  width: 80%;
}
@media screen and (max-width: 767px) {
  #about .sec02 dl {
    line-height: 1.6;
  }
  #about .sec02 dl dt {
    width: 27%;
  }
  #about .sec02 dl dd {
    width: 70%;
  }
}

#history .sec01 .sec_in {
  padding-bottom: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 120 - 60 ) / ( 1400 - 768 )))), 120px );
}
@media only screen and (max-width: 1024px) {
  #history .sec01 .sec_ttl {
    position: relative;
  }
  #history .sec01 .sec_ttl::after {
    content: "";
    width: 12%;
    height: 1px;
    background-color: #e9e9e9;
    position: absolute;
    left: 0;
    right: 0;
    margin: 30px auto 0;
  }
}
#history .sec01 h3 {
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 38 - 19 ) / ( 1400 - 768 )))), 38px );
  line-height: 1.8;
  letter-spacing: 0.07em;
  font-weight: 100;
  text-align: center;
  font-family: fot-tsukumin-pr6n, sans-serif;
  margin-bottom: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 55 - 40 ) / ( 1400 - 768 )))), 55px );
}
@media only screen and (max-width: 1024px) {
  #history .sec01 h3 {
    line-height: 1.6;
    letter-spacing: 0;
  }
}
#history .sec01 p {
  text-align: center;
  line-height: 2.8;
}
@media only screen and (max-width: 1024px) {
  #history .sec01 p {
    text-align: left;
    line-height: 2;
  }
}
#history .sec01 p + p {
  margin-top: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 55 - 30 ) / ( 1400 - 768 )))), 55px );
}
#history .sec02 .sec_ttl_jp {
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 60 - 30 ) / ( 1400 - 768 )))), 60px );
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 28 - 19 ) / ( 1400 - 768 )))), 28px );
  margin-top: -9px;
}
@media only screen and (max-width: 1024px) {
  #history .sec02 .sec_ttl_jp {
    width: 100%;
    margin-top: 0;
  }
}
#history .sec02 .sec_in {
  max-width: 1345px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#history .sec02 .sec_in .h_list {
  width: 72.67%;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
}
@media only screen and (max-width: 1024px) {
  #history .sec02 .sec_in .h_list {
    width: 100%;
  }
}
#history .sec02 .sec_in .h_list dl {
  display: flex;
  border-bottom: 1px solid #e9e9e9;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 28 - 18 ) / ( 1400 - 768 )))), 28px );
  padding-bottom: clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 28 - 18 ) / ( 1400 - 768 )))), 28px );
}
@media only screen and (max-width: 1024px) {
  #history .sec02 .sec_in .h_list dl {
    line-height: 1.6;
  }
}
#history .sec02 .sec_in .h_list dl:last-of-type {
  margin-bottom: 0;
}
#history .sec02 .sec_in .h_list dl dt {
  width: 160px;
}
@media only screen and (max-width: 1024px) {
  #history .sec02 .sec_in .h_list dl dt {
    width: 36%;
  }
}
#history .sec02 .sec_in .h_list dl dd {
  width: 76%;
}
@media only screen and (max-width: 1024px) {
  #history .sec02 .sec_in .h_list dl dd {
    width: 60%;
  }
}
#history .sec02 .sec_in .h_list dl figure {
  width: 100%;
  margin-top: 15px;
}
#history .sec02 .sec_in .h_list dl figure img {
  width: 50%;
}
@media only screen and (max-width: 1024px) {
  #history .sec02 .sec_in .h_list dl figure img {
    width: 100%;
  }
}
#history .sec02 .sec_in .h_list dl figure figcaption {
  font-size: clamp( 11px, calc( 11px + (( 100vw - 768px ) * (( 13 - 11 ) / ( 1400 - 768 )))), 13px );
  color: #787878;
  margin-top: 5px;
}

#jewelrylifeconcierge .sec01 .sec_ttl {
  margin-bottom: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 120 - 60 ) / ( 1400 - 768 )))), 120px );
  line-height: 1.2;
}
#jewelrylifeconcierge .sec01 .sec_ttl span {
  margin-top: clamp( 7px, calc( 7px + (( 100vw - 768px ) * (( 15 - 7 ) / ( 1400 - 768 )))), 15px );
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .sec01 .sec_ttl {
    position: relative;
  }
  #jewelrylifeconcierge .sec01 .sec_ttl::after {
    content: "";
    width: 12%;
    height: 1px;
    background-color: #e9e9e9;
    position: absolute;
    left: 0;
    right: 0;
    margin: 30px auto 0;
  }
}
#jewelrylifeconcierge .sec01 .box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
#jewelrylifeconcierge .sec01 .box:not(:last-of-type) {
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 100 - 30 ) / ( 1400 - 768 )))), 100px );
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .sec01 .box:last-of-type .info_area {
    padding-bottom: 0;
  }
}
#jewelrylifeconcierge .sec01 .box .info_area {
  width: 52%;
  position: relative;
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .sec01 .box .info_area {
    width: 100%;
    order: 2;
    background-color: rgba(255, 255, 255, 0.98);
    padding: 30px 20px;
    margin-top: -40px;
  }
}
#jewelrylifeconcierge .sec01 .box .info_area .info {
  border-left: 1px solid #e9e9e9;
  padding-left: 8%;
  margin-left: clamp( 80px, calc( 80px + (( 100vw - 768px ) * (( 105 - 80 ) / ( 1400 - 768 )))), 105px );
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .sec01 .box .info_area .info {
    margin-left: 0;
    border-left: none;
    padding-left: 0;
  }
}
#jewelrylifeconcierge .sec01 .box .info_area .info h3 {
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 32 - 19 ) / ( 1400 - 768 )))), 32px );
  font-weight: 300;
  margin-bottom: clamp( 6px, calc( 6px + (( 100vw - 768px ) * (( 25 - 6 ) / ( 1400 - 768 )))), 25px );
  letter-spacing: 0.1em;
  line-height: 1.4;
  color: #1F1F1F;
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .sec01 .box .info_area .info h3 {
    text-align: center;
    letter-spacing: 0;
  }
}
#jewelrylifeconcierge .sec01 .box .info_area .info h4 {
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 19 - 16 ) / ( 1400 - 768 )))), 19px );
  font-weight: 300;
  margin-bottom: 25px;
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .sec01 .box .info_area .info h4 {
    text-align: center;
    line-height: 1.7;
  }
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .sec01 .box .info_area .info h4 + p {
    line-height: 1.8;
  }
}
#jewelrylifeconcierge .sec01 .box .info_area .number {
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 0;
  font-weight: 100;
  position: absolute;
  font-family: fot-tsukumin-pr6n, sans-serif;
  font-size: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 70 - 30 ) / ( 1400 - 768 )))), 70px );
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .sec01 .box .info_area .number {
    position: static;
    width: max-content;
    line-height: 1;
    margin: 0 auto 8px;
  }
}
#jewelrylifeconcierge .sec01 .box .img_aera {
  width: 44.5%;
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .sec01 .box .img_aera {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    order: 1;
  }
}
#jewelrylifeconcierge .sec02 {
  background-color: #FAFAFA;
  margin-bottom: 0;
}
#jewelrylifeconcierge .sec02 .sec_ttl_jp {
  margin-bottom: 20px;
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .sec02 .sec_ttl_jp {
    line-height: 1.6;
  }
}
#jewelrylifeconcierge .sec02 .sec_ttl_jp + p {
  text-align: center;
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 120 - 30 ) / ( 1400 - 768 )))), 120px );
}
#jewelrylifeconcierge .sec02 ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#jewelrylifeconcierge .sec02 ul li {
  width: 49%;
}
@media only screen and (max-width: 768px) {
  #jewelrylifeconcierge .sec02 ul li {
    width: 100%;
  }
  #jewelrylifeconcierge .sec02 ul li:first-child {
    margin-bottom: 30px;
  }
}
#jewelrylifeconcierge .sec02 ul li h4 {
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 19 - 16 ) / ( 1400 - 768 )))), 19px );
  margin: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 20 - 15 ) / ( 1400 - 768 )))), 20px ) 0 5px;
  font-weight: 300;
}
#jewelrylifeconcierge .sec02 ul li h4 + p {
  color: #868a8c;
  line-height: 1.8;
}
#jewelrylifeconcierge .modaal-container {
  background: #F8F4EC;
  border-radius: 0;
  position: relative;
  padding: 5.5%;
}
#jewelrylifeconcierge .modaal-container .modaal-close {
  position: absolute;
  right: 30px;
  width: 30px;
  height: 30px;
  top: 30px;
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .modaal-container .modaal-close {
    right: 15px;
    top: 15px;
  }
}
#jewelrylifeconcierge .modaal-container .modaal-close:before, #jewelrylifeconcierge .modaal-container .modaal-close:after {
  background: #000;
  width: 1px;
  border-radius: 0;
  height: 25px;
  left: 15px;
  top: 4px;
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .modaal-container .modaal-close:before, #jewelrylifeconcierge .modaal-container .modaal-close:after {
    height: 20px;
  }
}
#jewelrylifeconcierge .modaal-container .modaal-close:hover {
  background: none;
  opacity: 0.6;
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .modaal-container {
    width: 100%;
  }
}
#jewelrylifeconcierge .modaal-container h3 {
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 32 - 16 ) / ( 1400 - 768 )))), 32px );
  text-align: center;
  font-family: fot-tsukumin-pr6n, sans-serif;
  margin-bottom: 15px;
  color: #75663f;
  font-weight: 100;
}
#jewelrylifeconcierge .modaal-container ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#jewelrylifeconcierge .modaal-container ul:first-of-type {
  margin-bottom: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 30 - 15 ) / ( 1400 - 768 )))), 30px );
}
#jewelrylifeconcierge .modaal-container ul li {
  width: 25%;
  position: relative;
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .modaal-container ul li {
    width: 28%;
  }
}
#jewelrylifeconcierge .modaal-container ul li img {
  border-radius: 50%;
}
#jewelrylifeconcierge .modaal-container ul li:not(:last-child):after {
  content: "";
  display: inline-block;
  position: absolute;
  width: clamp( 6px, calc( 6px + (( 100vw - 768px ) * (( 17 - 6 ) / ( 1400 - 768 )))), 17px );
  height: clamp( 6px, calc( 6px + (( 100vw - 768px ) * (( 17 - 6 ) / ( 1400 - 768 )))), 17px );
  top: 52.5%;
  right: -28%;
  vertical-align: middle;
  border-top: 4px solid #75663f;
  border-right: 4px solid #75663f;
  transform: rotate(45deg);
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .modaal-container ul li:not(:last-child):after {
    right: -19%;
    top: 57.5%;
    border-top: 3px solid #75663f;
    border-right: 3px solid #75663f;
  }
}
#jewelrylifeconcierge .modaal-container ul li p {
  color: #75663f;
  font-weight: 500;
  text-align: center;
  margin-bottom: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 20 - 15 ) / ( 1400 - 768 )))), 20px );
  font-size: clamp( 10px, calc( 10px + (( 100vw - 768px ) * (( 19 - 10 ) / ( 1400 - 768 )))), 19px );
  line-height: 1.3;
}
#jewelrylifeconcierge .modaal-container ul:last-of-type li:after {
  top: 48%;
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .modaal-container ul:last-of-type li:after {
    top: 45.5%;
  }
}
#jewelrylifeconcierge .topic_path_area {
  background-color: #fafafa;
}
@media only screen and (max-width: 1024px) {
  #jewelrylifeconcierge .topic_path_area {
    background-color: #fff;
  }
}

#shop .sec01 {
  border-bottom: 1px solid #e9e9e9;
}
#shop .sec01 .sec_in {
  padding-bottom: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 120 - 60 ) / ( 1400 - 768 )))), 120px );
}
#shop .sec01 .s_list {
  padding-left: clamp( 0px, calc( 0px + (( 100vw - 768px ) * (( 20 - 0 ) / ( 1400 - 768 )))), 20px );
}
#shop .sec01 .s_list:not(:last-of-type) {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 60 - 30 ) / ( 1400 - 768 )))), 60px );
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 50 - 30 ) / ( 1400 - 768 )))), 50px );
}
#shop .sec01 h4 {
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 28 - 16 ) / ( 1400 - 768 )))), 28px );
  line-height: 1.8;
  font-weight: 300;
  color: #1F1F1F;
  margin-top: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 40 - 20 ) / ( 1400 - 768 )))), 40px );
  letter-spacing: 0.1em;
}
@media only screen and (max-width: 1024px) {
  #shop .sec01 h4 {
    line-height: 1.6;
    letter-spacing: 0;
    margin-bottom: 7px;
  }
}
#shop .sec01 h4.mb_ttl {
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
}
#shop .sec01 p {
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
}
#shop .sec01 .d_list dl {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1024px) {
  #shop .sec01 .d_list dl {
    line-height: 1.6;
  }
}
#shop .sec01 .d_list dl:not(:last-of-type) {
  margin-bottom: 3px;
}
#shop .sec01 .d_list dl dt {
  color: #868a8c;
  width: clamp( 80px, calc( 80px + (( 100vw - 768px ) * (( 120 - 80 ) / ( 1400 - 768 )))), 120px );
}
#shop .sec01 .d_list dl dd {
  width: calc(100% - clamp( 80px, calc( 80px + (( 100vw - 768px ) * (( 120 - 80 ) / ( 1400 - 768 )))), 120px ));
}
#shop .sec01 .d_list dl dd .r_dd {
  margin-right: 20px;
}
#shop .sec01 .d_list dl dd a {
  display: inline-block;
}
#shop .sec01 .map {
  left: 0;
  display: inline-block;
}
@media only screen and (max-width: 1024px) {
  #shop .sec01 .map {
    display: block;
    margin-top: 5px;
  }
}
#shop .sec02 .sec_ttl_jp {
  margin-bottom: 20px;
}
@media only screen and (max-width: 1024px) {
  #shop .sec02 .sec_ttl_jp {
    margin-bottom: 30px;
  }
}
#shop .sec02 .sec_ttl_jp + p {
  text-align: center;
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 65 - 30 ) / ( 1400 - 768 )))), 65px );
}
#shop .sec02 .sec_in .s_list h4 {
  background-color: #F9F9F9;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  padding: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 24 - 15 ) / ( 1400 - 768 )))), 24px ) 20px;
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 19 - 16 ) / ( 1400 - 768 )))), 19px );
  color: #1F1F1F;
  font-weight: 300;
}
@media only screen and (max-width: 1024px) {
  #shop .sec02 .sec_in .s_list h4 {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
  }
}
#shop .sec02 .sec_in .s_list .s_d_list > li {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  padding: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 45 - 20 ) / ( 1400 - 768 )))), 45px ) 0 clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 55 - 20 ) / ( 1400 - 768 )))), 55px ) clamp( 0px, calc( 0px + (( 100vw - 768px ) * (( 20 - 0 ) / ( 1400 - 768 )))), 20px );
  border-bottom: 1px solid #e9e9e9;
}
#shop .sec02 .sec_in .s_list .s_d_list > li:last-child {
  border-bottom: none;
}
#shop .sec02 .sec_in .s_list .s_d_list > li .s_name {
  width: 23%;
  margin-top: clamp( 0px, calc( 0px + (( 100vw - 768px ) * (( 12 - 0 ) / ( 1400 - 768 )))), 12px );
}
@media only screen and (max-width: 1024px) {
  #shop .sec02 .sec_in .s_list .s_d_list > li .s_name {
    width: 100%;
  }
}
#shop .sec02 .sec_in .s_list .s_d_list > li .s_name h5 {
  color: #1F1F1F;
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 28 - 19 ) / ( 1400 - 768 )))), 28px );
  margin-bottom: clamp( 5px, calc( 5px + (( 100vw - 768px ) * (( 10 - 5 ) / ( 1400 - 768 )))), 10px );
  font-weight: 300;
  letter-spacing: 0.1em;
  line-height: 1.4;
}
#shop .sec02 .sec_in .s_list .s_d_list .d_list {
  width: 75%;
  margin-top: 15px;
}
@media only screen and (max-width: 1024px) {
  #shop .sec02 .sec_in .s_list .s_d_list .d_list {
    width: 100%;
  }
}
#shop .sec02 .sec_in .s_list .s_d_list .d_list dl {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1024px) {
  #shop .sec02 .sec_in .s_list .s_d_list .d_list dl {
    line-height: 1.6;
  }
}
#shop .sec02 .sec_in .s_list .s_d_list .d_list dl:not(:last-of-type) {
  margin-bottom: 7px;
}
#shop .sec02 .sec_in .s_list .s_d_list .d_list dl dt {
  color: #868a8c;
  width: clamp( 120px, calc( 120px + (( 100vw - 768px ) * (( 160 - 120 ) / ( 1400 - 768 )))), 160px );
}
#shop .sec02 .sec_in .s_list .s_d_list .d_list dl dd {
  width: calc(100% - clamp( 120px, calc( 120px + (( 100vw - 768px ) * (( 160 - 120 ) / ( 1400 - 768 )))), 160px ));
}
#shop .sec02 .sec_in .s_list .s_d_list .d_list dl dd a {
  display: inline-block;
}
#shop .sec02 .sec_in .s_list .s_d_list .d_list .note {
  background-color: #F7F0F2;
  border: 1px solid #FFBCBF;
  color: #E05A5A;
  padding: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 20 - 15 ) / ( 1400 - 768 )))), 20px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 25 - 20 ) / ( 1400 - 768 )))), 25px );
  margin-top: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 40 - 15 ) / ( 1400 - 768 )))), 40px );
  display: flex;
}
@media only screen and (max-width: 1024px) {
  #shop .sec02 .sec_in .s_list .s_d_list .d_list .note {
    display: block;
  }
}
#shop .sec02 .sec_in .s_list .s_d_list .d_list .note span {
  font-weight: 400;
  display: inline-block;
  width: 150px;
}
@media only screen and (max-width: 1024px) {
  #shop .sec02 .sec_in .s_list .s_d_list .d_list .note span {
    display: block;
    width: 100%;
    margin: 0 0 7px 0;
  }
}
#shop .sec02 .sec_in .s_list .s_d_list .icon_list {
  margin-top: 5%;
}
@media only screen and (max-width: 1024px) {
  #shop .sec02 .sec_in .s_list .s_d_list .icon_list {
    display: flex;
    margin-top: 6%;
  }
  #shop .sec02 .sec_in .s_list .s_d_list .icon_list li:not(:last-child) {
    margin-right: 25px;
  }
}
#shop .sec02 .sec_in .s_list .s_d_list .icon_list li {
  position: relative;
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px );
}
#shop .sec02 .sec_in .s_list .s_d_list .icon_list li:not(:last-child) {
  margin-bottom: 3px;
}
#shop .sec02 .sec_in .s_list .b_list li {
  display: inline-block;
}
#shop .sec02 .sec_in .s_list .b_list li a {
  text-decoration: underline;
}
#shop .sec02 .sec_in .s_list .b_list li a:hover {
  text-decoration: none;
}
#shop .sec02 .sec_in .s_list .b_list li:not(:last-child) a:after {
  content: "、";
  display: inline-block;
  font-size: clamp( 13px, calc( 13px + (( 100vw - 768px ) * (( 16 - 13 ) / ( 1400 - 768 )))), 16px );
  background-size: contain;
}
@media only screen and (max-width: 1024px) {
  #shop .sec02 .sec_in .s_list:last-of-type > ul > li:last-child {
    border-bottom: 1px solid #cdcdcd;
  }
}
#shop a.map {
  color: #717D95;
}
#shop a.map:hover {
  text-decoration: underline;
}
#shop a.map::before {
  content: "";
  display: inline-block;
  width: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px );
  height: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
  background-image: url("../../assets/img/shop/icon_map.svg");
  background-size: contain;
  vertical-align: middle;
  margin: -3px 8px 0 0;
  background-repeat: no-repeat;
}
#shop a.parking {
  color: #717D95;
}
#shop a.parking:hover {
  text-decoration: underline;
}
#shop a.parking::before {
  content: "";
  display: inline-block;
  width: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 18 - 14 ) / ( 1400 - 768 )))), 18px );
  height: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 19 - 15 ) / ( 1400 - 768 )))), 19px );
  background-image: url("../../assets/img/shop/icon_parking.svg");
  background-size: contain;
  vertical-align: middle;
  margin: -3px 8px 0 0;
  background-repeat: no-repeat;
}

#membership .mainSection {
  margin-top: 0;
}
#membership .kv {
  margin-bottom: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 200 - 60 ) / ( 1400 - 768 )))), 200px );
  position: relative;
  background: url("../../assets/img/membership/kv.jpg") no-repeat center;
  background-size: cover;
  padding-bottom: 31.4%;
}
@media only screen and (max-width: 768px) {
  #membership .kv {
    background-size: cover;
    background: url("../../assets/img/membership/kv_sp.jpg") no-repeat center;
    padding-bottom: 115%;
    background-size: cover;
  }
}
#membership .kv .kv_in {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
  transform: translate(0, -50%);
  text-align: left;
  left: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 120 - 60 ) / ( 1400 - 768 )))), 120px );
}
@media only screen and (max-width: 768px) {
  #membership .kv .kv_in {
    text-align: center;
    top: 35%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}
#membership .kv .kv_in h2 {
  font-size: clamp( 22px, calc( 22px + (( 100vw - 768px ) * (( 44 - 22 ) / ( 1400 - 768 )))), 44px );
  letter-spacing: 0.1em;
  margin-bottom: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 25 - 15 ) / ( 1400 - 768 )))), 25px );
  font-weight: 300;
}
#membership .kv .kv_in h2 span {
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
  font-family: fot-tsukumin-pr6n, sans-serif;
  display: block;
  font-weight: 100;
  letter-spacing: 0;
  margin-top: -10px;
}
@media only screen and (max-width: 365px) {
  #membership .kv .kv_in h2 + p br {
    display: none;
  }
}
#membership .sec01 {
  margin-bottom: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 200 - 60 ) / ( 1400 - 768 )))), 200px );
}
#membership .sec01 .sec_in {
  display: flex;
  justify-content: space-between;
  position: relative;
}
@media only screen and (max-width: 1024px) {
  #membership .sec01 .sec_in {
    flex-wrap: wrap;
  }
}
#membership .sec01 .sec_in::before {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #e9e9e9;
  left: 50%;
  display: inline-block;
  position: absolute;
}
@media only screen and (max-width: 1024px) {
  #membership .sec01 .sec_in::before {
    width: 100%;
    height: 1px;
    left: 0;
    top: 49%;
  }
}
#membership .sec01 .sec_in > div:first-of-type {
  max-width: 528px;
  margin-right: 60px;
}
@media only screen and (max-width: 1024px) {
  #membership .sec01 .sec_in > div:first-of-type {
    max-width: 100%;
    margin: 0 0 15% 0;
  }
}
@media only screen and (max-width: 767px) {
  #membership .sec01 .sec_in > div:first-of-type {
    margin: 0 0 30% 0;
  }
}
#membership .sec01 .sec_in > div:last-of-type {
  max-width: 680px;
  margin-left: 60px;
  padding-left: 30px;
}
@media only screen and (max-width: 1024px) {
  #membership .sec01 .sec_in > div:last-of-type {
    max-width: 100%;
    margin-left: 0;
    padding-left: 0;
  }
}
@media only screen and (max-width: 1024px) {
  #membership .sec01 .sec_in > div:last-of-type .btn_area_c {
    text-align: center;
    width: max-content;
    margin: 0 auto;
  }
}
#membership .sec01 .sec_in > div:last-of-type .btn {
  width: auto;
  padding: 0 15px;
}
#membership .sec01 .sec_ttl_jp {
  margin-bottom: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 20 - 15 ) / ( 1400 - 768 )))), 20px );
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 28 - 19 ) / ( 1400 - 768 )))), 28px );
}
#membership .sec01 .sec_ttl_jp + p {
  text-align: center;
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
}
@media only screen and (max-width: 1024px) {
  #membership .sec01 .sec_ttl_jp + p {
    text-align: left;
  }
}
#membership .sec01 .btn {
  border: 1px solid #d3d7e0;
}
#membership .sec02 {
  background: url("../../assets/img/membership/sec02_bg.jpg") no-repeat center;
  background-size: cover;
  height: clamp( 400px, calc( 400px + (( 100vw - 768px ) * (( 852 - 400 ) / ( 1400 - 768 )))), 852px );
}
@media only screen and (max-width: 1024px) {
  #membership .sec02 {
    height: auto;
    margin-bottom: 80px;
  }
}
#membership .sec02 .sec_in {
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-wrap: wrap;
  align-items: center;
}
#membership .sec02 .sec_in .sec_in_box {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  #membership .sec02 .sec_in {
    flex-wrap: wrap;
    padding: 70px 20px;
  }
}
#membership .sec02 .sec_in .sec_ttl_jp {
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  #membership .sec02 .sec_in .sec_ttl_jp {
    text-align: center;
  }
}
#membership .sec02 .sec_in .box {
  background-color: #fff;
  padding: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 80 - 40 ) / ( 1400 - 768 )))), 80px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 80 - 20 ) / ( 1400 - 768 )))), 80px );
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}
#membership .sec02 .sec_in .box .info_area {
  width: 44%;
}
@media only screen and (max-width: 1024px) {
  #membership .sec02 .sec_in .box .info_area {
    width: 100%;
  }
}
#membership .sec02 .sec_in .box .info_area h4 {
  color: #717D95;
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 28 - 19 ) / ( 1400 - 768 )))), 28px );
  font-weight: 300;
  line-height: 1.7;
  letter-spacing: 0.15em;
  margin-bottom: 5px;
  max-width: 395px;
}
@media only screen and (max-width: 1024px) {
  #membership .sec02 .sec_in .box .info_area h4 {
    text-align: center;
    margin-bottom: 25px;
    max-width: 100%;
    line-height: 1.5;
  }
}
#membership .sec02 .sec_in .box p span {
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 13 - 12 ) / ( 1400 - 768 )))), 13px );
  color: #878A8D;
  display: block;
  margin-top: 5px;
}
#membership .sec02 .sec_in .box img {
  margin-bottom: 25px;
  max-width: 586px;
  width: 51%;
  height: 100%;
}
@media only screen and (max-width: 1024px) {
  #membership .sec02 .sec_in .box img {
    width: 100%;
    max-width: 100%;
  }
}
#membership .sec03 .sec_ttl_jp {
  text-align: left;
  margin-bottom: 10px;
}
@media only screen and (max-width: 1024px) {
  #membership .sec03 .sec_ttl_jp {
    text-align: center;
  }
}
#membership .sec03 .sec_ttl_jp + p + img {
  margin: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 100 - 30 ) / ( 1400 - 768 )))), 100px ) 0;
}
#membership .sec03 .rank_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#membership .sec03 .rank_list > li {
  width: 32%;
  padding: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 55 - 40 ) / ( 1400 - 768 )))), 55px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 35 - 20 ) / ( 1400 - 768 )))), 35px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 35 - 20 ) / ( 1400 - 768 )))), 35px );
  margin-bottom: 30px;
}
@media only screen and (max-width: 1024px) {
  #membership .sec03 .rank_list > li {
    width: 48.5%;
  }
}
@media only screen and (max-width: 767px) {
  #membership .sec03 .rank_list > li {
    width: 100%;
  }
  #membership .sec03 .rank_list > li:last-child {
    margin-bottom: 0;
  }
}
#membership .sec03 .rank_list > li h4 {
  margin: 0 auto clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 25 - 15 ) / ( 1400 - 768 )))), 25px );
}
#membership .sec03 .rank_list > li h5 {
  text-align: center;
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 26 - 16 ) / ( 1400 - 768 )))), 26px );
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 5px;
}
@media only screen and (max-width: 1024px) {
  #membership .sec03 .rank_list > li h5 {
    font-size: 19px;
  }
}
#membership .sec03 .rank_list > li h5 + p {
  text-align: center;
  font-size: clamp( 13px, calc( 13px + (( 100vw - 768px ) * (( 13 - 13 ) / ( 1400 - 768 )))), 13px );
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 45 - 20 ) / ( 1400 - 768 )))), 45px );
}
#membership .sec03 .rank_list > li .benefits_box {
  background-color: #fff;
  padding: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
  padding-bottom: clamp( 100px, calc( 100px + (( 100vw - 768px ) * (( 120 - 100 ) / ( 1400 - 768 )))), 120px );
  font-weight: 400;
  position: relative;
}
@media only screen and (max-width: 767px) {
  #membership .sec03 .rank_list > li .benefits_box {
    height: auto !important;
    padding-bottom: 20px;
  }
}
#membership .sec03 .rank_list > li .benefits_box h6 {
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 16 - 14 ) / ( 1400 - 768 )))), 16px );
  font-weight: 500;
  margin-bottom: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 25 - 15 ) / ( 1400 - 768 )))), 25px );
}
#membership .sec03 .rank_list > li .benefits_box ul {
  padding-left: 20px;
}
#membership .sec03 .rank_list > li .benefits_box ul li {
  line-height: 1.6;
  position: relative;
}
#membership .sec03 .rank_list > li .benefits_box ul li:before {
  content: "・";
  font-size: 16px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: -20px;
}
#membership .sec03 .rank_list > li .benefits_box ul li .note {
  color: #888c8e;
  display: block;
}
#membership .sec03 .rank_list > li .benefits_box .btn {
  border: 1px solid #ddd;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 16 - 14 ) / ( 1400 - 768 )))), 16px );
  width: 85%;
  padding: 0;
  position: absolute;
  bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
  margin: 0 auto;
  left: 0;
  right: 0;
}
@media only screen and (max-width: 767px) {
  #membership .sec03 .rank_list > li .benefits_box .btn {
    position: static;
    width: 100%;
    margin-top: 25px;
  }
}
#membership .sec03 .rank_list > li.pearl {
  background-color: #fdf8f6;
}
#membership .sec03 .rank_list > li.pearl h4 {
  width: clamp( 93px, calc( 93px + (( 100vw - 768px ) * (( 130 - 93 ) / ( 1400 - 768 )))), 130px );
}
#membership .sec03 .rank_list > li.pearl h5 {
  margin-bottom: clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 45 - 25 ) / ( 1400 - 768 )))), 45px );
}
#membership .sec03 .rank_list > li.pearl h5,
#membership .sec03 .rank_list > li.pearl p {
  color: #D6AE97;
}
#membership .sec03 .rank_list > li.pearl p {
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 50 - 20 ) / ( 1400 - 768 )))), 50px );
}
#membership .sec03 .rank_list > li.pearl .btn {
  color: #D6AE97;
  border: 1px solid #f6e9e2;
}
#membership .sec03 .rank_list > li.pearl .btn:hover {
  background-color: #D6AE97;
  color: #fff;
}
#membership .sec03 .rank_list > li.sapphire {
  background-color: #EBEDF4;
}
#membership .sec03 .rank_list > li.sapphire h4 {
  width: clamp( 130px, calc( 130px + (( 100vw - 768px ) * (( 189 - 130 ) / ( 1400 - 768 )))), 189px );
}
#membership .sec03 .rank_list > li.sapphire h5,
#membership .sec03 .rank_list > li.sapphire p {
  color: #3C5497;
}
#membership .sec03 .rank_list > li.sapphire .btn {
  color: #3C5497;
  border: 1px solid #C4CBDF;
}
#membership .sec03 .rank_list > li.sapphire .btn:hover {
  background-color: #3C5497;
  color: #fff;
}
#membership .sec03 .rank_list > li.ruby {
  background-color: #FBEEF3;
}
#membership .sec03 .rank_list > li.ruby h4 {
  width: clamp( 98px, calc( 98px + (( 100vw - 768px ) * (( 135 - 98 ) / ( 1400 - 768 )))), 135px );
}
#membership .sec03 .rank_list > li.ruby h5,
#membership .sec03 .rank_list > li.ruby p {
  color: #D95889;
}
#membership .sec03 .rank_list > li.ruby .btn {
  color: #D95889;
  border: 1px solid #F3CCDB;
}
#membership .sec03 .rank_list > li.ruby .btn:hover {
  background-color: #D95889;
  color: #fff;
}
#membership .sec03 .rank_list > li.emerald {
  background-color: #ECF6F6;
}
#membership .sec03 .rank_list > li.emerald h4 {
  width: clamp( 130px, calc( 130px + (( 100vw - 768px ) * (( 179 - 130 ) / ( 1400 - 768 )))), 179px );
}
#membership .sec03 .rank_list > li.emerald h5,
#membership .sec03 .rank_list > li.emerald p {
  color: #45A9AA;
}
#membership .sec03 .rank_list > li.emerald .btn {
  color: #45A9AA;
  border: 1px solid #C7E5E5;
}
#membership .sec03 .rank_list > li.emerald .btn:hover {
  background-color: #45A9AA;
  color: #fff;
}
#membership .sec03 .rank_list > li.diamond {
  background-color: #EFF0F0;
}
#membership .sec03 .rank_list > li.diamond h4 {
  width: clamp( 154px, calc( 154px + (( 100vw - 768px ) * (( 214 - 154 ) / ( 1400 - 768 )))), 214px );
}
#membership .sec03 .rank_list > li.diamond h5,
#membership .sec03 .rank_list > li.diamond p {
  color: #646B71;
}
#membership .sec03 .rank_list > li.diamond .btn {
  color: #646B71;
  border: 1px solid #646B71;
}
#membership .sec03 .rank_list > li.diamond .btn:hover {
  background-color: #45A9AA;
  color: #fff;
}
#membership .sec03 .rank_list > li.pinkdiamond {
  background-color: #FAF2F8;
}
#membership .sec03 .rank_list > li.pinkdiamond h4 {
  max-width: 334px;
}
@media only screen and (max-width: 1024px) {
  #membership .sec03 .rank_list > li.pinkdiamond h4 {
    max-width: 242px;
  }
}
#membership .sec03 .rank_list > li.pinkdiamond h5,
#membership .sec03 .rank_list > li.pinkdiamond p {
  color: #C983B6;
}
#membership .sec03 .rank_list > li.pinkdiamond h5 + p {
  margin-bottom: 45px;
}
@media only screen and (max-width: 1440px) {
  #membership .sec03 .rank_list > li.pinkdiamond h5 + p {
    margin-bottom: clamp( 35px, calc( 35px + (( 100vw - 768px ) * (( 50 - 35 ) / ( 1400 - 768 )))), 50px );
  }
}
@media only screen and (max-width: 1024px) {
  #membership .sec03 .rank_list > li.pinkdiamond h5 + p {
    margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 60 - 20 ) / ( 1400 - 768 )))), 60px );
  }
}
#membership .sec03 .rank_list > li.pinkdiamond .btn {
  color: #C983B6;
  border: 1px solid #EED9E9;
}
#membership .sec03 .rank_list > li.pinkdiamond .btn:hover {
  background-color: #C983B6;
  color: #fff;
}
@media only screen and (max-width: 1024px) {
  #membership .modaal-outer-wrapper, #membership .modaal-inner-wrapper {
    display: block;
  }
}
#membership .modaal-container {
  background: #fff;
  border-radius: 0;
  position: relative;
  padding: 5% 5% 3.5%;
  height: 80vh;
  max-width: 1105px;
  width: 100%;
  overflow-y: scroll;
}
@media only screen and (max-width: 767px) {
  #membership .modaal-container {
    padding: 0 5% 3.5%;
    height: auto;
  }
  #membership .modaal-container img {
    padding-top: 90px;
    width: 680px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  #membership .modaal-container .modaal-content {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
#membership .modaal-container .modaal-close {
  position: absolute;
  right: 30px;
  width: 30px;
  height: 30px;
  top: 30px;
}
@media only screen and (max-width: 1024px) {
  #membership .modaal-container .modaal-close {
    right: 15px;
    top: 15px;
  }
}
#membership .modaal-container .modaal-close:before, #membership .modaal-container .modaal-close:after {
  background: #000;
  width: 1px;
  border-radius: 0;
  height: 25px;
  left: 15px;
  top: 4px;
}
@media only screen and (max-width: 1024px) {
  #membership .modaal-container .modaal-close:before, #membership .modaal-container .modaal-close:after {
    height: 20px;
  }
}
#membership .modaal-container .modaal-close:hover {
  background: none;
  opacity: 0.6;
}
@media only screen and (max-width: 1024px) {
  #membership .modaal-container {
    width: 100%;
  }
}
#membership .modaal-container h3 {
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 32 - 16 ) / ( 1400 - 768 )))), 32px );
  text-align: center;
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
  font-weight: 300;
}
@media only screen and (max-width: 767px) {
  #membership .modaal-container h3 {
    position: absolute;
    left: 0;
    right: 0;
    top: 7%;
    z-index: 99;
    color: #000;
  }
}
#membership .modaal-container ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#membership .modaal-container ul:first-of-type {
  margin-bottom: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 30 - 15 ) / ( 1400 - 768 )))), 30px );
}
#membership .modaal-container ul li {
  width: 30.5%;
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
}
@media only screen and (max-width: 1024px) {
  #membership .modaal-container ul li {
    width: 47.5%;
  }
}
#membership .modaal-container ul li h4 {
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
  font-weight: 300;
  line-height: 1.5;
  margin: clamp( 10px, calc( 10px + (( 100vw - 768px ) * (( 18 - 10 ) / ( 1400 - 768 )))), 18px ) 0 7px;
}
#membership .modaal-container ul li p {
  color: #888c8e;
  line-height: 1.6;
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px );
}
#membership .modaal-container ul:last-of-type li:after {
  top: 48%;
}
@media only screen and (max-width: 1024px) {
  #membership .modaal-container ul:last-of-type li:after {
    top: 45.5%;
  }
}

#privacy .sec01 .sec_ttl {
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 70 - 30 ) / ( 1400 - 768 )))), 70px );
}
#privacy .sec01 .box {
  background-color: #FAFAFA;
  padding: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 80 - 30 ) / ( 1400 - 768 )))), 80px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 80 - 20 ) / ( 1400 - 768 )))), 80px );
  max-width: 1168px;
  width: 100%;
  margin: 0 auto;
}
#privacy .sec01 .box h4 {
  font-size: clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 28 - 18 ) / ( 1400 - 768 )))), 28px );
  font-weight: 300;
  margin-bottom: 5px;
  color: #1F1F1F;
}
#privacy .sec01 .box h4 + p {
  margin-bottom: clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 50 - 25 ) / ( 1400 - 768 )))), 50px );
}
#privacy .sec01 .box dl:not(:last-of-type) {
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 40 - 30 ) / ( 1400 - 768 )))), 40px );
}
#privacy .sec01 .box dl dt {
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 19 - 16 ) / ( 1400 - 768 )))), 19px );
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 1.6;
}
@media only screen and (max-width: 1024px) {
  #privacy .sec01 .box dl dd {
    line-height: 1.6;
  }
}
#privacy .sec01 .box dl dd ul {
  list-style-type: disc;
  padding-left: 25px;
}

#terms .sec01 .sec_ttl {
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 70 - 30 ) / ( 1400 - 768 )))), 70px );
}
#terms .sec01 .box {
  background-color: #FAFAFA;
  padding: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 80 - 30 ) / ( 1400 - 768 )))), 80px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 80 - 20 ) / ( 1400 - 768 )))), 80px );
  max-width: 1168px;
  width: 100%;
  margin: 0 auto;
}
#terms .sec01 .box .f_txt {
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 60 - 30 ) / ( 1400 - 768 )))), 60px );
}
#terms .sec01 .box dl:not(:last-of-type) {
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 60 - 30 ) / ( 1400 - 768 )))), 60px );
}
#terms .sec01 .box dl dt {
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 19 - 16 ) / ( 1400 - 768 )))), 19px );
  margin-bottom: 10px;
  font-weight: 400;
  line-height: 1.6;
}
@media only screen and (max-width: 1024px) {
  #terms .sec01 .box dl dd {
    line-height: 1.6;
  }
}
#terms .sec01 .box dl dd ol {
  list-style-type: decimal;
  padding-left: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 25 - 20 ) / ( 1400 - 768 )))), 25px );
}
#terms .sec01 .box dl dd ul {
  list-style-type: disc;
  padding-left: 25px;
}
#terms .sec01 .box .last_txt {
  text-align: right;
  margin-top: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 40 - 30 ) / ( 1400 - 768 )))), 40px );
}

#faq .sec01 .sec_in {
  max-width: 1345px;
  width: 100%;
  margin: 0 auto;
}
#faq .sec01 .tab_list {
  display: flex;
  margin-bottom: 60px;
  flex-wrap: wrap;
}
@media only screen and (max-width: 768px) {
  #faq .sec01 .tab_list {
    overflow-x: auto;
    flex-wrap: nowrap;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;
    margin: 0 calc(50% - 50vw) 20px;
    width: 100vw;
    max-width: auto;
    max-width: initial;
  }
}
#faq .sec01 .tab_list > div {
  width: 24.85%;
  line-height: 1;
  text-align: center;
  z-index: 3;
  color: #1F1F1F;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 18 - 14 ) / ( 1400 - 768 )))), 18px );
  font-weight: 300;
  display: block;
  background-color: #FAFAFA;
  height: 64px;
  line-height: 64px;
  cursor: pointer;
  transition: 0.3s;
  margin: 0 0.2% 0.2% 0;
}
@media only screen and (max-width: 768px) {
  #faq .sec01 .tab_list > div {
    width: 30%;
    flex-shrink: 0;
    height: auto;
    line-height: 1.6;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
#faq .sec01 .tab_list > div:hover {
  background: #f2f2f2;
}
#faq .sec01 .tab_list > div.current-cat, #faq .sec01 .tab_list > div:first-child {
  background-color: #717D95;
  position: relative;
  color: #fff;
}
#faq .sec01 .tab_list > div:nth-child(4), #faq .sec01 .tab_list > div:nth-child(8), #faq .sec01 .tab_list > div:nth-child(12) {
  margin-right: 0;
}
#faq .sec01 .panel-group {
  position: relative;
}
#faq .sec01 .panel-group .panel {
  display: none;
}
#faq .sec01 .panel-group .panel.show {
  display: block;
  -webkit-animation: news_list_animation 1s cubic-bezier(0.22, 1, 0.36, 1) 0s;
  animation: news_list_animation 1s cubic-bezier(0.22, 1, 0.36, 1) 0s;
}
@-webkit-keyframes news_list_animation {
  0% {
    opacity: 0;
    bottom: -20px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
@keyframes news_list_animation {
  0% {
    opacity: 0;
    bottom: -20px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
#faq .sec01 .panel-group .panel dl {
  background-color: #F9F9F9;
  border: 1px solid #e9e9e9;
  padding: 0 clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 25 - 18 ) / ( 1400 - 768 )))), 25px ) 0px clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 25 - 18 ) / ( 1400 - 768 )))), 25px );
}
#faq .sec01 .panel-group .panel dl:not(:last-of-type) {
  margin-bottom: clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 30 - 18 ) / ( 1400 - 768 )))), 30px );
}
#faq .sec01 .panel-group .panel dl dt {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  padding: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 24 - 16 ) / ( 1400 - 768 )))), 24px ) clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 70 - 30 ) / ( 1400 - 768 )))), 70px ) clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 25 - 14 ) / ( 1400 - 768 )))), 25px ) clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 35 - 25 ) / ( 1400 - 768 )))), 35px );
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
  position: relative;
}
@media only screen and (max-width: 1024px) {
  #faq .sec01 .panel-group .panel dl dt {
    line-height: 1.6;
  }
}
#faq .sec01 .panel-group .panel dl dt::before {
  content: "Q .";
  display: inline-block;
  position: absolute;
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 19 - 16 ) / ( 1400 - 768 )))), 19px );
  top: clamp( 13px, calc( 13px + (( 100vw - 768px ) * (( 22 - 13 ) / ( 1400 - 768 )))), 22px );
  left: 0;
  background-size: contain;
  vertical-align: middle;
}
#faq .sec01 .panel-group .panel dl dt::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 20 - 12 ) / ( 1400 - 768 )))), 20px );
  height: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 20 - 12 ) / ( 1400 - 768 )))), 20px );
  top: calc(50% - clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 20 - 12 ) / ( 1400 - 768 )))), 20px ) / 2);
  right: 0;
  background-image: url("../../assets/img/member/icon_open.svg");
  background-size: contain;
  vertical-align: middle;
}
#faq .sec01 .panel-group .panel dl dt.active::after {
  background-image: url("../../assets/img/member/icon_close.svg");
}
#faq .sec01 .panel-group .panel dl dd {
  border-top: 1px solid #ddd;
  padding: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px ) 0 clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
  display: none;
}
#faq .sec01 .panel-group .panel dl dd a {
  color: #717D95;
  text-decoration: underline;
  display: inline-block;
}
#faq .sec01 .panel-group .panel dl dd a:hover {
  text-decoration: none;
}
#faq .sec01 .contact_link_txt {
  text-align: center;
  margin-top: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 60 - 40 ) / ( 1400 - 768 )))), 60px );
}
#faq .sec01 .contact_link_txt a {
  color: #717d95;
  text-decoration: underline;
  display: inline-block;
}
#faq .sec01 .contact_link_txt a:hover {
  text-decoration: none;
}
#faq.faq_tax .tab_list li:first-child {
  background-color: #fafafa;
  color: #1f1f1f;
}
#faq.faq_tax .tab_list li.current-cat {
  background-color: #717D95;
  position: relative;
  color: #fff;
}

#withdrawal-login .mainSection {
  margin-top: 0;
}
#withdrawal-login .sec01 .sec_ttl {
  margin: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 120 - 60 ) / ( 1400 - 768 )))), 120px ) 0 clamp( 35px, calc( 35px + (( 100vw - 768px ) * (( 60 - 35 ) / ( 1400 - 768 )))), 60px );
}
#withdrawal-login .sec01 .box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#withdrawal-login .sec01 .box .l_area, #withdrawal-login .sec01 .box .r_area {
  width: 100%;
  background-color: #FAFAFA;
  padding: clamp( 35px, calc( 35px + (( 100vw - 768px ) * (( 85 - 35 ) / ( 1400 - 768 )))), 85px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 85 - 20 ) / ( 1400 - 768 )))), 85px );
}
@media only screen and (max-width: 1024px) {
  #withdrawal-login .sec01 .box .l_area, #withdrawal-login .sec01 .box .r_area {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  #withdrawal-login .sec01 .box .l_area {
    margin-bottom: 20px;
  }
}
#withdrawal-login .sec01 .box .l_area .btn_area_c {
  margin: clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 50 - 25 ) / ( 1400 - 768 )))), 50px ) 0 25px;
}
#withdrawal-login .sec01 .box .l_area .txt_link {
  display: block;
  width: max-content;
  margin: 0 auto;
}
#withdrawal-login .sec01 .box h4 {
  font-size: clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 28 - 18 ) / ( 1400 - 768 )))), 28px );
  font-weight: 300;
  letter-spacing: 0.1em;
  line-height: 1.6;
  margin-bottom: 10px;
  color: #1F1F1F;
}
#withdrawal-login .sec01 .box h4 + p {
  margin-bottom: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 50 - 15 ) / ( 1400 - 768 )))), 50px );
}
#withdrawal-login .sec01 .box dl:not(:last-of-type) {
  margin-bottom: 25px;
}
#withdrawal-login .sec01 .box dl dt {
  margin-bottom: 5px;
  color: #1F1F1F;
}
#withdrawal-login .modaal-container {
  background: #fff;
  border-radius: 0;
  position: relative;
  padding: 5% 5% 3.5%;
  width: 100%;
  min-height: 400px;
}
@media only screen and (max-width: 1024px) {
  #withdrawal-login .modaal-container {
    padding: 13% 5% 12%;
  }
}
@media only screen and (max-width: 1024px) {
  #withdrawal-login .modaal-container {
    width: 100%;
  }
}
#withdrawal-login .modaal-container h3 {
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 32 - 19 ) / ( 1400 - 768 )))), 32px );
  text-align: center;
  margin-bottom: 10px;
  font-weight: 400;
}
@media only screen and (max-width: 1024px) {
  #withdrawal-login .modaal-container h3 {
    margin-bottom: 15px;
  }
}
#withdrawal-login .modaal-container h3 + p {
  text-align: center;
  margin-bottom: clamp( 25px, calc( 25px + (( 100vw - 768px ) * (( 50 - 25 ) / ( 1400 - 768 )))), 50px );
}
@media only screen and (max-width: 1024px) {
  #withdrawal-login .modaal-container h3 + p {
    text-align: left;
  }
}
#withdrawal-login .modaal-container dl {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 860px) {
  #withdrawal-login .modaal-container dl dt {
    margin-bottom: 10px;
  }
}
#withdrawal-login .modaal-container dl dd {
  width: 70%;
}
@media only screen and (max-width: 860px) {
  #withdrawal-login .modaal-container dl dd {
    width: 100%;
  }
}
#withdrawal-login .modaal-container .btn {
  margin-top: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 60 - 40 ) / ( 1400 - 768 )))), 60px );
}
#withdrawal-login .modaal-container .txt_link {
  display: block;
  margin-top: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 25 - 15 ) / ( 1400 - 768 )))), 25px );
  color: #717D95;
  font-size: clamp( 12px, calc( 12px + (( 100vw - 768px ) * (( 16 - 12 ) / ( 1400 - 768 )))), 16px );
}
#withdrawal-login .modaal-container .code_list {
  display: flex;
  justify-content: center;
}
#withdrawal-login .modaal-container .code_list input {
  text-align: center;
  max-width: 56px;
  max-height: 56px;
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 20 - 16 ) / ( 1400 - 768 )))), 20px );
}
#withdrawal-login .modaal-container .code_list input:not(:last-child) {
  margin-right: 2%;
}
#withdrawal-login .modaal-container .note_area {
  color: #797979;
  border-top: 1px solid #CDCDCD;
  padding-top: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 40 - 20 ) / ( 1400 - 768 )))), 40px );
  margin-top: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 80 - 40 ) / ( 1400 - 768 )))), 80px );
  font-size: clamp( 11px, calc( 11px + (( 100vw - 768px ) * (( 13 - 11 ) / ( 1400 - 768 )))), 13px );
}
#withdrawal-login .modaal-container .note_area p:first-child {
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
}
#withdrawal-login .modaal-container .note_area ol {
  list-style-type: decimal;
  padding-left: 16px;
}
#withdrawal-login .code:focus {
  outline: auto;
}
#withdrawal-login input[type=checkbox] {
  display: none;
}
#withdrawal-login input[type=checkbox]:checked + .checkbox01::before {
  background-color: #717d95;
}
#withdrawal-login input[type=checkbox]:checked + .checkbox01::after {
  opacity: 1;
}
#withdrawal-login .checkbox01 {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  padding-left: 35px;
  position: relative;
  width: auto;
}
#withdrawal-login .checkbox01::before {
  background: #fff;
  border: 1px solid #e0e0e0;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  left: 0;
  position: absolute;
  top: clamp( 1px, calc( 1px + (( 100vw - 768px ) * (( 5 - 1 ) / ( 1400 - 768 )))), 5px );
}
#withdrawal-login .checkbox01::after {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  content: "";
  display: block;
  height: 9px;
  left: 7.5px;
  margin-top: -8px;
  opacity: 0;
  position: absolute;
  top: clamp( 13px, calc( 13px + (( 100vw - 768px ) * (( 17 - 13 ) / ( 1400 - 768 )))), 17px );
  transform: rotate(45deg);
  width: 5px;
}

#custom .container {
  margin-top: 68px;
}
@media only screen and (max-width: 1024px) {
  #custom .container {
    margin-top: 58px;
  }
}
#custom .container .mainSection {
  margin: 0;
}
#custom .container .mainSection section {
  margin: 0 auto clamp( 80px, calc( 80px + (( 100vw - 768px ) * (( 185 - 80 ) / ( 1400 - 768 )))), 185px );
}
#custom .container .mainSection section .sec_in_bg {
  padding-top: clamp( 80px, calc( 80px + (( 100vw - 768px ) * (( 185 - 80 ) / ( 1400 - 768 )))), 185px );
  padding-bottom: 0;
}
#custom .sec_ttl {
  font-family: fot-tsukumin-pr6n, sans-serif;
  font-size: clamp( 22px, calc( 22px + (( 100vw - 768px ) * (( 44 - 22 ) / ( 1400 - 768 )))), 44px );
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 50 - 30 ) / ( 1400 - 768 )))), 50px );
  letter-spacing: 0.2em;
}
@media only screen and (max-width: 1024px) {
  #custom .sec_ttl {
    line-height: 1.5;
    letter-spacing: 0.1em;
  }
}
#custom h4 {
  text-align: center;
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 32 - 19 ) / ( 1400 - 768 )))), 32px );
  font-weight: 300;
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 60 - 20 ) / ( 1400 - 768 )))), 60px );
  letter-spacing: 0.1em;
  line-height: 1.2;
}
#custom .kv {
  background: url("../../assets/img/custom/kv.jpg") no-repeat top;
  background-size: cover;
  padding-bottom: 49.6%;
  margin-bottom: clamp( 70px, calc( 70px + (( 100vw - 768px ) * (( 180 - 70 ) / ( 1400 - 768 )))), 180px ) !important;
}
@media only screen and (max-width: 1024px) {
  #custom .kv {
    background: url("../../assets/img/custom/kv_sp.jpg") no-repeat top;
    background-size: cover;
    padding-bottom: 167%;
  }
}
#custom .sec01 p {
  text-align: center;
  margin: 0;
  line-height: 2.5;
  font-size: clamp( 14px, calc( 14px + (( 100vw - 768px ) * (( 19 - 14 ) / ( 1400 - 768 )))), 19px );
}
@media only screen and (max-width: 1024px) {
  #custom .sec01 p {
    padding: 0;
    line-height: 1.9;
  }
}
#custom .sec01 p + p {
  margin-top: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 55 - 15 ) / ( 1400 - 768 )))), 55px );
}
#custom .sec02 .sec_ttl {
  margin-bottom: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 100 - 60 ) / ( 1400 - 768 )))), 100px );
}
#custom .sec02 .sec_in_bg {
  padding-top: 0 !important;
}
@media only screen and (max-width: 1024px) {
  #custom .sec02 .sec_in_bg {
    padding-bottom: 0;
  }
}
#custom .sec02__list {
  margin-bottom: clamp( 60px, calc( 60px + (( 100vw - 768px ) * (( 160 - 60 ) / ( 1400 - 768 )))), 160px );
}
#custom .sec02__item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#custom .sec02__item .info {
  width: 55%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 1024px) {
  #custom .sec02__item .info {
    width: 100%;
    order: 2;
    background-color: rgba(255, 255, 255, 0.95);
    margin-top: -30px;
    padding-top: 20px;
  }
}
#custom .sec02__item .info--last {
  margin-top: -15px;
}
#custom .sec02__item .info__num {
  font-family: fot-tsukumin-pr6n, sans-serif;
  font-size: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 70 - 30 ) / ( 1400 - 768 )))), 70px );
  width: 15%;
}
@media only screen and (max-width: 1024px) {
  #custom .sec02__item .info__num {
    width: 100%;
    text-align: center;
  }
}
#custom .sec02__item .info__txt {
  width: 63%;
  padding-left: 5%;
  border-left: 1px solid #ddd;
}
@media only screen and (max-width: 1024px) {
  #custom .sec02__item .info__txt {
    width: 100%;
    border: none;
    padding: 0 4.5%;
  }
  #custom .sec02__item .info__txt p {
    line-height: 1.8;
  }
}
#custom .sec02__item .info__txt h4 {
  text-align: left;
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 25 - 20 ) / ( 1400 - 768 )))), 25px );
}
@media only screen and (max-width: 1024px) {
  #custom .sec02__item .info__txt h4 {
    text-align: center;
  }
}
#custom .sec02__item .img {
  width: 44.5%;
}
@media only screen and (max-width: 1024px) {
  #custom .sec02__item .img {
    order: 1;
    width: 100vw;
    margin: 0 calc(50% - 50vw);
  }
}
#custom .sec02__item:not(:last-child) {
  margin-bottom: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 40 - 30 ) / ( 1400 - 768 )))), 40px );
}
#custom .sec02__item:nth-child(even) .info {
  justify-content: flex-end;
}
#custom .sec02 .color {
  max-width: 1200px;
  margin: 0 auto;
}
@media only screen and (max-width: 1024px) {
  #custom .sec02 .color {
    width: 100%;
    max-width: 100%;
  }
}
#custom .sec02 .color h4 {
  margin-bottom: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 100 - 40 ) / ( 1400 - 768 )))), 100px );
}
#custom .sec02 .color__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 80 - 20 ) / ( 1400 - 768 )))), 80px ) 0;
}
@media only screen and (max-width: 1024px) {
  #custom .sec02 .color__list {
    overflow-x: auto;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 0 6%;
    width: 100vw;
    margin: 0 calc(50% - 50vw);
    padding: 0 20px;
  }
}
#custom .sec02 .color__item {
  width: 31%;
}
@media only screen and (max-width: 1024px) {
  #custom .sec02 .color__item {
    width: 74%;
    flex-shrink: 0;
  }
}
#custom .sec02 .color__item h5 {
  font-weight: 300;
  text-align: center;
  font-size: clamp( 19px, calc( 19px + (( 100vw - 768px ) * (( 26 - 19 ) / ( 1400 - 768 )))), 26px );
  line-height: 1.2;
  margin: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 30 - 15 ) / ( 1400 - 768 )))), 30px ) 0 clamp( 10px, calc( 10px + (( 100vw - 768px ) * (( 30 - 10 ) / ( 1400 - 768 )))), 30px );
}
@media only screen and (max-width: 1024px) {
  #custom .sec02 .color__item h5 {
    text-align: left;
  }
}
#custom .sec02 .color__item img {
  width: 40%;
  display: block;
  margin: 0 auto;
}
@media only screen and (max-width: 1024px) {
  #custom .sec02 .color__item img {
    width: 72%;
  }
}
#custom .sec03 {
  text-align: center;
  background-color: #FAFAFA;
}
#custom .sec03 .sec_in_bg {
  padding-bottom: clamp( 80px, calc( 80px + (( 100vw - 768px ) * (( 185 - 80 ) / ( 1400 - 768 )))), 185px ) !important;
}
@media only screen and (max-width: 1024px) {
  #custom .sec03 .sec_in_bg {
    padding-bottom: 0;
  }
}
#custom .sec03__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: clamp( 18px, calc( 18px + (( 100vw - 768px ) * (( 18 - 18 ) / ( 1400 - 768 )))), 18px );
}
@media only screen and (max-width: 1024px) {
  #custom .sec03__list {
    overflow-x: auto;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 0 5%;
    width: 100vw;
    margin: 0 calc(50% - 50vw) 18px;
    padding: 0 20px;
  }
}
#custom .sec03__item {
  overflow: hidden;
  width: 19%;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: clamp( 10px, calc( 10px + (( 100vw - 768px ) * (( 20 - 10 ) / ( 1400 - 768 )))), 20px ) 0 clamp( 10px, calc( 10px + (( 100vw - 768px ) * (( 15 - 10 ) / ( 1400 - 768 )))), 15px );
  cursor: pointer;
  transition: 0.3s;
}
#custom .sec03__item:hover {
  opacity: 0.6;
}
#custom .sec03__item.active {
  border: 3px solid #717D95;
}
@media only screen and (max-width: 1024px) {
  #custom .sec03__item {
    width: 42%;
    flex-shrink: 0;
  }
}
#custom .sec03__item p {
  line-height: 1.8;
}
@media only screen and (max-width: 1024px) {
  #custom .sec03__item p {
    line-height: 1.6;
  }
}
#custom .sec03__img {
  height: clamp( 110px, calc( 110px + (( 100vw - 768px ) * (( 160 - 110 ) / ( 1400 - 768 )))), 160px );
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (min-width: 1600px) {
  #custom .sec03__img {
    height: 180px;
  }
}
#custom .sec03__img img {
  width: 63%;
  margin: 0 auto;
}
@media only screen and (max-width: 1024px) {
  #custom .sec03__img img {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  #custom .sec03__img img {
    width: 70%;
  }
}
@media only screen and (max-width: 1024px) {
  #custom .sec03 h4 + p {
    text-align: left;
  }
}
#custom .sec03 h4 + p + p {
  margin: clamp( 15px, calc( 15px + (( 100vw - 768px ) * (( 20 - 15 ) / ( 1400 - 768 )))), 20px ) 0 clamp( 35px, calc( 35px + (( 100vw - 768px ) * (( 50 - 35 ) / ( 1400 - 768 )))), 50px );
}
@media only screen and (max-width: 1024px) {
  #custom .sec03 h4 + p + p {
    text-align: left;
  }
}
#custom .sec03 .design {
  padding: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 55 - 30 ) / ( 1400 - 768 )))), 55px ) clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 60 - 20 ) / ( 1400 - 768 )))), 60px );
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  display: none;
}
@media only screen and (max-width: 1024px) {
  #custom .sec03 .design {
    width: 100vw;
    border-radius: 0;
    border-left: none;
    border-right: none;
    margin: 0 calc(50% - 50vw);
  }
}
#custom .sec03 .design.show {
  display: block;
  -webkit-animation: news_list_animation 1s cubic-bezier(0.22, 1, 0.36, 1) 0s;
  animation: news_list_animation 1s cubic-bezier(0.22, 1, 0.36, 1) 0s;
}
@keyframes news_list_animation {
  0% {
    opacity: 0;
    bottom: -20px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}
#custom .sec03 .design__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 35 - 20 ) / ( 1400 - 768 )))), 35px );
}
@media only screen and (max-width: 1024px) {
  #custom .sec03 .design__list {
    overflow-x: auto;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 0 22%;
    width: 100vw;
    margin: 0 calc(50% - 50vw) 30px;
    padding: 0 20px;
  }
}
@media only screen and (max-width: 1024px) {
  #custom .sec03 .design__list + p {
    text-align: left;
  }
}
#custom .sec03 .design__item {
  width: 31%;
}
@media only screen and (max-width: 1024px) {
  #custom .sec03 .design__item {
    width: 60%;
    flex-shrink: 0;
  }
}
#custom .sec03 .design__item img {
  width: 55%;
}
@media only screen and (max-width: 1024px) {
  #custom .sec03 .design__item img {
    width: 65%;
  }
}
#custom .sec03 .design__item--big img {
  width: 80%;
}
@media only screen and (max-width: 1024px) {
  #custom .sec03 .design__item--big img {
    width: 95%;
  }
}
#custom .sec03 .design__price {
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 29 - 16 ) / ( 1400 - 768 )))), 29px );
  letter-spacing: 0.1em;
  line-height: 1.2;
  margin: clamp( 10px, calc( 10px + (( 100vw - 768px ) * (( 12 - 10 ) / ( 1400 - 768 )))), 12px ) 0;
}
@media only screen and (max-width: 1024px) {
  #custom .sec03 .design__price {
    text-align: left;
    letter-spacing: 0.05em;
  }
}
@media only screen and (max-width: 1024px) {
  #custom .sec03 .stone {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
#custom .sec03 .stone p {
  margin-bottom: clamp( 0px, calc( 0px + (( 100vw - 768px ) * (( 10 - 0 ) / ( 1400 - 768 )))), 10px );
}
#custom .sec03 .stone__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 clamp( 5px, calc( 5px + (( 100vw - 768px ) * (( 12 - 5 ) / ( 1400 - 768 )))), 12px );
}
#custom .sec03 .stone__item {
  width: clamp( 9px, calc( 9px + (( 100vw - 768px ) * (( 30 - 9 ) / ( 1400 - 768 )))), 30px );
}
#custom .sec03 .stone__item img {
  display: block;
  width: 100%;
}
#custom .sec04 .sec_in {
  max-width: 1345px;
}
#custom .sec04 h4 {
  text-align: left;
  margin-bottom: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 40 - 20 ) / ( 1400 - 768 )))), 40px );
}
@media only screen and (max-width: 1024px) {
  #custom .sec04 h4 {
    letter-spacing: 0.02em;
  }
}
#custom .sec04__box1 {
  margin-bottom: clamp( 40px, calc( 40px + (( 100vw - 768px ) * (( 80 - 40 ) / ( 1400 - 768 )))), 80px );
}
#custom .sec04__box1 dl {
  display: flex;
  gap: 0 clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 30 - 20 ) / ( 1400 - 768 )))), 30px );
}
#custom .sec04__box1 dl dt {
  width: clamp( 65px, calc( 65px + (( 100vw - 768px ) * (( 80 - 65 ) / ( 1400 - 768 )))), 80px );
}
#custom .sec04__box1 dl dd {
  width: calc(100% - clamp( 65px, calc( 65px + (( 100vw - 768px ) * (( 80 - 65 ) / ( 1400 - 768 )))), 80px ));
}
#custom .sec04__box1 dl dd a {
  display: inline-block;
}
#custom .sec04__box2 .contact-btn {
  border: 1px solid #ddd;
  width: 100%;
  height: clamp( 98px, calc( 98px + (( 100vw - 768px ) * (( 100 - 98 ) / ( 1400 - 768 )))), 100px );
  margin-top: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 40 - 30 ) / ( 1400 - 768 )))), 40px );
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: clamp( 20px, calc( 20px + (( 100vw - 768px ) * (( 40 - 20 ) / ( 1400 - 768 )))), 40px );
  position: relative;
  font-size: clamp( 16px, calc( 16px + (( 100vw - 768px ) * (( 19 - 16 ) / ( 1400 - 768 )))), 19px );
}
#custom .sec04__box2 .contact-btn:hover {
  background-color: #ddd;
}
#custom .sec04__box2 .contact-btn::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 8px;
  height: 8px;
  right: clamp( 30px, calc( 30px + (( 100vw - 768px ) * (( 40 - 30 ) / ( 1400 - 768 )))), 40px );
  background-size: contain;
  vertical-align: middle;
  border-top: 1px solid #1F1F1F;
  border-right: 1px solid #1F1F1F;
  transform: rotate(45deg);
}
@use "./assets/scss/mixin"as *;

#custom {
  .container{
    margin-top: 68px;
    @include media(sp) {
      margin-top:58px;
    }
  .mainSection{
    margin: 0;
    section{
      margin: 0 auto fluid(185,80);
      .sec_in_bg{
        padding-top:fluid(185,80);
        padding-bottom:0;
      }
    }
  }
  }

  .sec_ttl{
    font-family: fot-tsukumin-pr6n, sans-serif;
    font-size: fluid(44,22);
    margin-bottom: fluid(50,30);
    letter-spacing: .2em;
    @include media(sp) {
      line-height: 1.5;
      letter-spacing: .1em;
    }
  }

  h4{
    text-align: center;
    font-size: fluid(32,19);
    font-weight: 300;
    margin-bottom: fluid(60,20);
    letter-spacing: .1em;
    line-height: 1.2;
  }

  .kv{
    background: url("../../assets/img/custom/kv.jpg") no-repeat top;
    background-size: cover;
    padding-bottom: 49.6%;
    margin-bottom: fluid(180,70) !important;
    @include media(sp) {
      background: url("../../assets/img/custom/kv_sp.jpg") no-repeat top;
      background-size: cover;
      padding-bottom: 167%;
    }
  }

  .sec01 {
    p{
      text-align: center;
      margin: 0;
      line-height: 2.5;
      font-size:fluid(19,14);
      @include media(sp) {
        padding: 0;
        line-height: 1.9;
      }
      & + p{
        margin-top: fluid(55,15);
      }
    }
  }
  .sec02{
    .sec_ttl{
      margin-bottom:fluid(100,60);
    }
    .sec_in_bg{
      padding-top: 0 !important;
      @include media(sp) {
        padding-bottom: 0;
      }
    }
    &__list{
      margin-bottom:fluid(160,60);
    }
    &__item{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .info{
        width: 55%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include media(sp) {
          width: 100%;
          order: 2;
          background-color: rgba(255,255,255,.95);
          margin-top: -30px;
          padding-top: 20px;
        }
        &--last{
          margin-top: -15px;
        }
        &__num{
          font-family: fot-tsukumin-pr6n, sans-serif;
          font-size:fluid(70,30);
          width: 15%;
          @include media(sp) {
            width: 100%;
            text-align: center;
          }
        }
        &__txt{
          width: 63%;
          padding-left: 5%;
          border-left: 1px solid #ddd;
          @include media(sp) {
            width: 100%;
            border: none;
            padding:0 4.5%;
            p{
              line-height: 1.8;
            }
          }
          h4{
            text-align: left;
            margin-bottom:fluid(25,20);
            @include media(sp) {
              text-align: center;
            }
          }
        }
      }
      .img{
        width: 44.5%;
        @include media(sp) {
          order: 1;
          width: 100vw;
          margin: 0 calc(50% - 50vw);
        }
      }
      &:not(:last-child){
        margin-bottom:fluid(40,30);
      }
      &:nth-child(even){
        .info{
          justify-content: flex-end;
        }
      }
    }
    .color{


        max-width: 1200px;
        margin: 0 auto;
        @include media(sp) {
          width: 100%;
          max-width: 100%;
        }
      h4{
        margin-bottom:fluid(100,40) ;
      }
      &__list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: fluid(80,20) 0 ;
        @include media(sp) {
          overflow-x: auto;
          justify-content: flex-start;
          flex-wrap: nowrap;
          gap: 0 6%;
          width: 100vw;
          margin: 0 calc(50% - 50vw);
          padding: 0 20px;
        }
      }
      &__item{
        width: 31%;
        @include media(sp) {
          width: 74%;
          flex-shrink: 0;
        }
        h5{
          font-weight: 300;
          text-align: center;
          font-size:fluid(26,19);
          line-height: 1.2;
          margin:fluid(30,15) 0 fluid(30,10);
          @include media(sp) {
            text-align: left;
          }
        }
        img{
          width:40%;
          display: block;
          margin: 0 auto;
          @include media(sp) {
            width: 72%;
          }
        }
      }
    }
  }

  .sec03{
    text-align: center;
    background-color: #FAFAFA;
    .sec_in_bg{
      padding-bottom:fluid(185,80) !important;
      @include media(sp) {
        padding-bottom: 0;
      }
    }
    &__list{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: fluid(18,18);
      @include media(sp) {
        overflow-x: auto;
        justify-content: flex-start;
        flex-wrap: nowrap;
        gap: 0 5%;
        width: 100vw;
        margin: 0 calc(50% - 50vw) 18px;
        padding: 0 20px;
      }
    }

    &__item{
      overflow: hidden;
      width: 19%;
      border-radius: 10px;
      background-color: #fff;
      border: 1px solid #ddd;
      padding: fluid(20,10) 0 fluid(15,10);
      cursor: pointer;
      transition: .3s;

      &:hover{
        opacity: .6;
      }

      &.active{
        border: 3px solid #717D95;
      }
      @include media(sp) {
        width: 42%;
        flex-shrink: 0;
      }
      p{
        line-height: 1.8;
        @include media(sp) {
          line-height: 1.6;
        }
      }
    }
    &__img{
        height:fluid(160,110);
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        @media only screen and (min-width: 1600px){
          height: 180px;
        }
      img{
        width: 63%;
        margin: 0 auto;
        @include media(sp) {
          width:50%;
        }
        @media only screen and (max-width: 767px){
          width:70%;
        }
      }
    }
    h4{
      & + p{ 
        @include media(sp) {
          text-align: left;
        }
        & + p{
        margin: fluid(20,15) 0 fluid(50,35);
        @include media(sp) {
          text-align: left;
        }
       }
      }
    }
    .design{
      padding: fluid(55,30) fluid(60,20);
      border-radius: 10px;
      background-color: #fff;
      border: 1px solid #ddd;
      display: none;
      @include media(sp) {
        width: 100vw;
        border-radius: 0;
        border-left: none;
        border-right: none;
        margin: 0 calc(50% - 50vw);
      }

      &.show {
        display: block;
        -webkit-animation: news_list_animation 1.0s cubic-bezier(0.22, 1, 0.36, 1) 0s;
        animation: news_list_animation 1.0s cubic-bezier(0.22, 1, 0.36, 1) 0s;
      }

      @keyframes news_list_animation {
        0% {
          opacity: 0;
          bottom: -20px;
        }

        100% {
          opacity: 1;
          bottom: 0;
        }
      }

      &__list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom:fluid(35,20);
        @include media(sp) {
          overflow-x: auto;
          justify-content: flex-start;
          flex-wrap: nowrap;
          gap: 0 22%;
          width: 100vw;
          margin: 0 calc(50% - 50vw) 30px;
          padding: 0 20px;
        }

      & + p{
        @include media(sp) {
          text-align: left;
        }
      }
      }
      &__item{
        width: 31%;
        @include media(sp) {
          width: 60%;
          flex-shrink: 0;
        }
        img{
          width:55%;
          @include media(sp) {
            width: 65%;
          }
        }
        &--big{
          img{
            width: 80%;
            @include media(sp) {
              width: 95%;
            }
          }
        }
      }
      &__price{
        font-size:fluid(29,16);
        letter-spacing: .1em;
        line-height: 1.2;
        margin: fluid(12,10) 0;
        @include media(sp) {
          text-align: left;
          letter-spacing: .05em;
        }
      }
    }
    .stone{
      @include media(sp) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      p{
        margin-bottom: fluid(10,0);
      }
      &__list{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        gap: 0 fluid(12,5);
      }
      &__item{
        width: fluid(30,9);
        img{
          display: block;
          width: 100%;
        }
      }
    }
  }

  .sec04{
    .sec_in{
      max-width: calc(1105px + 120px * 2);
    }
    h4{
    text-align: left;
    margin-bottom:fluid(40,20);
    @include media(sp) {
      letter-spacing: .02em;
    }
  }
  &__box1{
    margin-bottom:fluid(80,40);
    dl{
      display: flex;
      gap: 0 fluid(30,20);
      dt{
        width:fluid(80,65);
      }
      dd{
        width: calc(100% - fluid(80,65));
        a{
          display: inline-block;
        }
      }
    }
  }
  &__box2{
    .contact-btn{
      border: 1px solid #ddd;
      width: 100%;
      height:fluid(100,98);
      margin-top:fluid(40,30);
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-left: fluid(40,20);
      position: relative;
      font-size:fluid(19,16);
      &:hover{
        background-color: #ddd;
      }
      &::after{
        content: '';
        display: inline-block;
        position: absolute;
        width: 8px;
        height: 8px;
        right: fluid(40,30);
        background-size: contain;
        vertical-align: middle;
        //矢印要素の場合
        border-top: 1px solid #1F1F1F;
        border-right: 1px solid #1F1F1F;
        transform: rotate(45deg);
      }

    }
  }
}
}
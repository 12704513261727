@use "./assets/scss/mixin"as *;

#about {
  .mainSection{
    margin-top: 0;
  }

  .sec01 {
    background: url("../../assets/img/about/sec01_bg.jpg") no-repeat center;
    background-size: cover;
    padding: fluid(120, 50) 0;
    margin-bottom: fluid(120,50);
    @include media(sp) {
      background: url("../../assets/img/about/sec01_bg_sp.jpg") no-repeat center;
      background-size: cover;
    }

    .box {
      background-color: rgba(255, 255, 255, .98);
      padding: fluid(120, 50) fluid(120, 24);

      h4 {
        font-size: fluid(30, 19);
        line-height: 2.1;
        letter-spacing: .1em;
        font-weight: 100;
        text-align: center;
        font-family: fot-tsukumin-pr6n, sans-serif;
        margin-bottom: fluid(80, 30);
        @include media(sp) {
          line-height: 1.5;
        }
      }

      p {
        text-align: center;
        line-height: 3.2;
        @include media(sp) {
          text-align: left;
          line-height: 2;
        }

        &+p {
          margin-top: fluid(55, 25);
        }
      }
      .btn_area_c{
        @include media(sp) {
          text-align: center;
        }
      }
      .btn {
        border: 1px solid #d3d7e0;
        width: 240px;
        @include media(sp) {
          width: auto;
          padding: 0 20px;
          display: inline-block;
        }
      }
    }
  }

  .sec02 {
    .sec_in {
      max-width: calc(1108px + 30px * 2);
      .sec_ttl_jp{
        margin-bottom: fluid(60, 30);
      }
    }

    dl {
      display: flex;
      border-bottom: 1px solid #e9e9e9;
      justify-content: space-between;
      margin-bottom: fluid(28,18);
      padding-bottom: fluid(28,18);

      &:last-of-type {
        margin-bottom: 0;
      }


      dt {
        width: 15%;
      }

      dd {
        width: 80%;
      }
    }

    @media screen and (max-width: 767px) {
      dl {
        line-height: 1.6;

        dt {
          width: 27%;
        }
  
        dd {
          width: 70%;
        }
      }
    }
  }
}
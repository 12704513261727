@use "./assets/scss/mixin"as *;

#history {

  .sec01 {
    .sec_in{
      padding-bottom: fluid(120,60);
    }

    .sec_ttl {
      @include media(sp) {
        position: relative;
        &::after{
          content: "";
          width: 12%;
          height: 1px;
          background-color: #e9e9e9;
          position: absolute;
          left: 0;
          right: 0;
          margin: 30px auto 0;
        }
      }
    }

    h3 {
      font-size: fluid(38, 19);
      line-height: 1.8;
      letter-spacing: 0.07em;
      font-weight: 100;
      text-align: center;
      font-family: fot-tsukumin-pr6n, sans-serif;
      margin-bottom: fluid(55, 40);

      @include media(sp) {
        line-height: 1.6;
        letter-spacing: 0;
      }
    }

    p {
      text-align: center;
      line-height: 2.8;

      @include media(sp) {
        text-align: left;
        line-height: 2;
      }

      &+p {
        margin-top: fluid(55, 30);
      }
    }
  }

  .sec02 {
    .sec_ttl_jp {
      margin-bottom: fluid(60, 30);
      font-size: fluid(28, 19);
      margin-top: -9px;

      @include media(sp) {
        width: 100%;
        margin-top: 0;
      }
    }

    .sec_in {
      max-width: calc(1105px + 120px * 2);
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;


      .h_list {
        width: 72.67%;
        font-size: fluid(19, 14);

        @include media(sp) {
          width: 100%;
        }

        dl {
          display: flex;
          border-bottom: 1px solid #e9e9e9;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: fluid(28, 18);
          padding-bottom: fluid(28, 18);

          @include media(sp) {
            line-height: 1.6;
          }

          &:last-of-type {
            margin-bottom: 0;
          }


          dt {
            width: 160px;

            @include media(sp) {
              width: 36%;
            }
          }

          dd {
            width: 76%;

            @include media(sp) {
              width: 60%;
            }
          }

          figure {
            width: 100%;
            margin-top: 15px;

            img {
              width: 50%;

              @include media(sp) {
                width: 100%;
              }
            }

            figcaption {
              font-size: fluid(13, 11);
              color: #787878;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
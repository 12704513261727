@use "./assets/scss/mixin"as *;

#membership {
  .mainSection {
    margin-top: 0;
  }

  .kv {
    margin-bottom: fluid(200, 60);
    position: relative;
    background: url("../../assets/img/membership/kv.jpg") no-repeat center;
    background-size: cover;
    padding-bottom: 31.4%;

    @media only screen and (max-width: 768px){
      background-size: cover;
      background: url("../../assets/img/membership/kv_sp.jpg") no-repeat center;
      padding-bottom: 115%;
      background-size: cover;
    }

    .kv_in {
      @include center;
      max-width: calc(1440px + 30px * 2);
      margin: 0 auto;
      padding: 0 fluid(30, 20);
      transform: translate(0, -50%);
      text-align: left;
      left: fluid(120,60);

      @media only screen and (max-width: 768px){
        text-align: center;
        top: 35%;
        transform: translate(-50%, -50%);
        left: 50%;
      }

      h2 {
        font-size: fluid(44, 22);
        letter-spacing: .1em;
        margin-bottom: fluid(25, 15);
        font-weight: 300;

        span {
          font-size: fluid(19, 14);
          font-family: fot-tsukumin-pr6n, sans-serif;
          display: block;
          font-weight: 100;
          letter-spacing: 0;
          margin-top: -10px;
        }

        &+p br {
          @media only screen and (max-width: 365px) {
            display: none;
          }
        }
      }
    }
  }

  .sec01 {
    margin-bottom: fluid(200, 60);

    .sec_in {
      display: flex;
      justify-content: space-between;
      position: relative;

      @include media(sp) {
        flex-wrap: wrap;
      }

      &::before {
        content: '';
        width: 1px;
        height: 100%;
        background-color: #e9e9e9;
        left: 50%;
        display: inline-block;
        position: absolute;

        @include media(sp) {
          width: 100%;
          height: 1px;
          left: 0;
          top: 49%;
        }
      }

      &>div {
        &:first-of-type {
          max-width: calc(468px + 60px);
          margin-right: 60px;

          @include media(sp) {
            max-width: 100%;
            margin: 0 0 15% 0;
          }

          @media only screen and (max-width: 767px){
          margin: 0 0 30% 0;
        }
        }

        &:last-of-type {
          max-width: calc(590px + 90px);
          margin-left: 60px;
          padding-left: 30px;

          @include media(sp) {
            max-width: 100%;
            margin-left: 0;
            padding-left: 0;
          }

          .btn_area_c {
            @include media(sp) {
              text-align: center;
              width: max-content;
              margin: 0 auto;
            }
          }

          .btn {
            width: auto;
            padding: 0 15px;
          }
        }
      }
    }

    .sec_ttl_jp {
      margin-bottom: fluid(20, 15);
      font-size: fluid(28, 19);

      &+p {
        text-align: center;
        margin-bottom: fluid(30, 20);

        @include media(sp) {
          text-align: left;
        }
      }
    }

    .btn {
      border: 1px solid #d3d7e0;
    }
  }

  .sec02 {
    background: url("../../assets/img/membership/sec02_bg.jpg") no-repeat center;
    background-size: cover;
    height: fluid(852, 400);

    @include media(sp) {
      height: auto;
      margin-bottom: 80px;
    }

    .sec_in {
      display: flex;
      justify-content: space-between;
      height: 100%;
      flex-wrap: wrap;
      align-items: center;
      .sec_in_box{
        width: 100%;
      }

      @include media(sp) {
        flex-wrap: wrap;
        padding: 70px 20px;
      }

        .sec_ttl_jp {
          text-align: left;
          margin-bottom: 20px;
          width: 100%;

          @include media(sp) {
            text-align: center;
          }
        }

        .box {
          background-color: #fff;
          padding: fluid(80, 40) fluid(80, 20);
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          align-items: center;

          .info_area {
            width: 44%;
            @include media(sp) {
              width: 100%;
            }
            h4 {
              color: #717D95;
              font-size: fluid(28, 19);
              font-weight: 300;
              line-height: 1.7;
              letter-spacing: .15em;
              margin-bottom: 5px;
              max-width: 395px;

              @include media(sp) {
                text-align: center;
                margin-bottom: 25px;
                max-width: 100%;
                line-height: 1.5;
              }

            }
          }


          p {
            span{
              font-size: fluid(13,12);
              color:#878A8D;
              display: block;
              margin-top: 5px;
            }
          }
          img {
            margin-bottom: 25px;
            max-width: 586px;
             width: 51%;
            height: 100%;
            @include media(sp) {
              width: 100%;
              max-width: 100%;
            }
          }
        }

      }
  }

  .sec03 {
    .sec_ttl_jp {
      text-align: left;
      margin-bottom: 10px;

      @include media(sp) {
        text-align: center;
      }

      &+p {
        &+img {
          margin: fluid(100, 30) 0;
        }
      }
    }

    .rank_list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &>li {
        width: 32%;
        padding: fluid(55, 40) fluid(35, 20) fluid(35, 20);
        margin-bottom: 30px;

        @include media(sp) {
          width: 48.5%;
        }

        @media only screen and (max-width: 767px) {
          width: 100%;

          &:last-child {
            margin-bottom: 0;
          }
        }

        h4 {
          margin: 0 auto fluid(25, 15);
        }

        h5 {
          text-align: center;
          font-size: fluid(26, 16);
          font-weight: 300;
          line-height: 1.4;
          margin-bottom: 5px;
          @include media(sp) {
            font-size: 19px;
          }

          &+p {
            text-align: center;
            font-size: fluid(13, 13);
            margin-bottom: fluid(45, 20);
          }
        }

        .benefits_box {
          background-color: #fff;
          padding: fluid(30, 20);
          padding-bottom: fluid(120, 100);
          font-weight: 400;
          position: relative;
          @media only screen and (max-width: 767px) {
            height: auto !important;
            padding-bottom: 20px;
          }

          h6 {
            font-size: fluid(16, 14);
            font-weight: 500;
            margin-bottom: fluid(25, 15);
          }

          ul {
            padding-left: 20px;
            li {
              line-height: 1.6;
              position: relative;
              &:before{
                content: '・';
                font-size: 16px;
                display: inline-block;
                position: absolute;
                top:0;
                left: -20px;
              }

              .note {
                color: #888c8e;
                display: block;
              }
            }
          }

          .btn {
            border: 1px solid #ddd;
            font-size: fluid(16, 14);
            width: 85%;
            padding: 0;
            position: absolute;
            bottom: fluid(30,20);
            margin: 0 auto;
            left: 0;
            right: 0;
            @media only screen and (max-width: 767px) {
              position: static;
              width: 100%;
              margin-top: 25px;
            }
          }
        }

        &.pearl {
          background-color: #fdf8f6;

          h4 {
            width: fluid(130, 93)
          }

          h5{
            margin-bottom: fluid(45,25);
          }

          h5,
          p {
            color: #D6AE97;
          }

          p {

            margin-bottom: fluid(50, 20);
          }

          .btn {
            color: #D6AE97;
            border: 1px solid #f6e9e2;

            &:hover {
              background-color: #D6AE97;
              color: #fff;
            }
          }
        }

        &.sapphire {
          background-color: #EBEDF4;

          h4 {
            width: fluid(189, 130)
          }

          h5,
          p {
            color: #3C5497;
          }

          .btn {
            color: #3C5497;
            border: 1px solid #C4CBDF;

            &:hover {
              background-color: #3C5497;
              color: #fff;
            }
          }
        }

        &.ruby {
          background-color: #FBEEF3;

          h4 {
            width: fluid(135, 98)
          }

          h5,
          p {
            color: #D95889;
          }

          .btn {
            color: #D95889;
            border: 1px solid #F3CCDB;

            &:hover {
              background-color: #D95889;
              color: #fff;
            }
          }
        }

        &.emerald {
          background-color: #ECF6F6;

          h4 {
            width: fluid(179, 130)
          }

          h5,
          p {
            color: #45A9AA;
          }

          .btn {
            color: #45A9AA;
            border: 1px solid #C7E5E5;

            &:hover {
              background-color: #45A9AA;
              color: #fff;
            }
          }
        }

        &.diamond {
          background-color: #EFF0F0;

          h4 {
            width: fluid(214, 154)
          }

          h5,
          p {
            color: #646B71;
          }

          .btn {
            color: #646B71;
            border: 1px solid #646B71;

            &:hover {
              background-color: #45A9AA;
              color: #fff;
            }
          }
        }

        &.pinkdiamond {
          background-color: #FAF2F8;

          h4 {
            max-width: 334px;

            @include media(sp) {
              max-width: 242px;
            }
          }

          h5,
          p {
            color: #C983B6;
          }

          h5 + p
           {
            margin-bottom: 45px;
            @media only screen and (max-width: 1440px) {
              margin-bottom:fluid(50,35);
            }
            @include media(sp) {
              margin-bottom:fluid(60,20);
            }
          }

          .btn {
            color: #C983B6;
            border: 1px solid #EED9E9;

            &:hover {
              background-color: #C983B6;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .modaal-outer-wrapper, .modaal-inner-wrapper{
  @include media(sp) {
    display: block;
  }
}
  .modaal-container{
    background: #fff;
    border-radius: 0;
    position: relative;
    padding: 5% 5% 3.5%;
    height: 80vh;
    max-width: 1105px;
    width: 100%;
    overflow-y: scroll;
    @media only screen and (max-width: 767px) {
      padding: 0 5% 3.5%;
      height: auto;
      img{
        padding-top: 90px;
        width: 680px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
      }
      .modaal-content{
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
      }
    }

    .modaal-close {
      position: absolute;
      right: 30px;
      width: 30px;
      height: 30px;
      top: 30px;

      @include media(sp) {
        right: 15px;
        top: 15px;
      }

      &:before,
      &:after {
        background: #000;
        width: 1px;
        border-radius: 0;
        height: 25px;
        left: 15px;
        top: 4px;

        @include media(sp) {
          height: 20px;
        }
      }

      &:hover {
        background: none;
        opacity: .6;
      }
    }

    @include media(sp) {
      width: 100%;
    }

    h3 {
      font-size: fluid(32, 16);
      text-align: center;
      margin-bottom: fluid(30, 20);
      font-weight: 300;
      @media only screen and (max-width: 767px) {
        position: absolute;
        left: 0;
        right: 0;
        top: 7%;
        z-index: 99;
        color: #000;
      }
    }

    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &:first-of-type {
        margin-bottom: fluid(30, 15);
      }

      li {
        width: 30.5%;
        margin-bottom: fluid(30, 20);

        @include media(sp) {
          width: 47.5%;
        }

        h4 {
          font-size: fluid(19, 14);
          font-weight: 300;
          line-height: 1.5;
          margin: fluid(18, 10) 0 7px;
        }

        p {
          color: #888c8e;
          line-height: 1.6;
          font-size: fluid(16, 12);
        }
      }

      &:last-of-type {
        li {
          &:after {
            top: 48%;

            @include media(sp) {
              top: 45.5%;
            }
          }
        }
      }
    }
  }
}
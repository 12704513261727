@use "./assets/scss/mixin"as *;

//--------------------------------------------------------------
//font-import
//--------------------------------------------------------------
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100&family=Roboto:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300&display=swap');

//font-family: 'Noto Sans JP','Roboto', sans-serif;

//--------------------------------------------------------------
//html/body
//--------------------------------------------------------------
* {
	box-sizing: border-box;
}

body {
	color: #1F1F1F;
	line-height: 2;
	font-family: 'Noto Sans JP', "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", sans-serif;
	word-break: break-word;
	width: 100%;
	font-weight: 300;
	font-size: fluid(16, 14);

	@include media(sp) {
		p {
			line-height: 1.6;
		}
	}

	&.fixed {
		overflow: hidden;
	}
}

.container {
	overflow: hidden;
	position: relative;
	margin-top: 68px;

	@include media(sp) {
		margin-top:58px;
	}


}

.mainSection {
	position: relative;
	margin-top: fluid_tb(120, 60);

	&.fixed {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
		opacity: 1;
		box-sizing: border-box;
		-webkit-overflow-scrolling: touch;
		-webkit-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}
}

.modaal_overlay {
	transition: .3s;

	&.fixed {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .8);
	}
}

.no_link {
	pointer-events: none;
}

//--------------------------------------------------------------
//レスポンシブスイッチ
//--------------------------------------------------------------
.pc {
	display: block !important;

	@include media(sp) {
		display: none !important;
	}
}

.sp {
	display: none !important;

	@include media(sp) {
		display: block !important;
	}
}

//------------------------------------------------------
//ボタン
//------------------------------------------------------
.btn_area_c {
	text-align: center;
}

.btn_area {
	text-align: left;
}

.btn {
	display: inline-block;
	width: fluid(190, 170);
	text-align: center;
	cursor: pointer;
	outline: none;
	transition: all 0.3s;
	background-color: #fff;
	color: #717D95;
	height: fluid(64, 50);
	line-height: fluid(62, 49);
	font-weight: 500;
	font-size: fluid(18, 15);

	@include media(sp) {
		display: block;
		margin: 0 auto;
		justify-content: center;
	}

	&:hover {
		background-color: #717D95;
		color: #fff;
	}

	&.iv_btn {
		background-color: #717D95;
		color: #fff;
		border: 1px solid #717D95;

		&:hover {
			background-color: #fff;
			color: #717D95;
		}
	}

	&.border {
		border: 1px solid #d3d7e0;
	}

	&.back_btn {}
}

//------------------------------------------------------
//幅、マージン
//------------------------------------------------------
section {
	margin: 0 auto fluid(120, 60);


	&.last {
		margin-bottom: 0;
	}
}

.sec_in {
	max-width: calc(1440px + 120px * 2);
	width: 100%;
	margin: 0 auto;
	padding: 0 fluid_tb(120, 20);

	&.sec_in_bg {
		padding: fluid(120, 60) fluid_tb(120, 20);

		@include media(sp) {
			padding: 60px 20px;
		}
	}
}

// ---------------------------------------------
//パンくず
//----------------------------------------------

.topic_path_area {
	border-top: 1px solid #e9e9e9;

	@include media(sp) {
		border: none;
	}
}

.topic_path {
	display: flex;
	margin: 0 auto;
	max-width: calc(1440px + 30px * 2);
	width: 100%;
	padding: fluid(20, 15) fluid(30, 20);

	@include media(sp) {
		max-width: inherit;
	}

	li {
		margin-right: 10px;
		font-size: fluid(13, 12);
		padding-left: 15px;
		box-sizing: border-box;
		position: relative;

		&:before {
			content: "";
			display: block;
			width: 5px;
			height: 5px;
			border-top: 1px solid #333;
			border-right: 1px solid #333;
			transform: rotate(45deg);
			position: absolute;
			top: 10px;
			left: 0;
		}

		&:first-child {
			padding-left: 0;

			&:before {
				display: none;
			}
		}

		a {
			display: block;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

//--------------------------------------------------------------
//リスト
//----------------------------------------------------------------
.disc {
	margin-left: 20px;

	li {
		list-style-type: disc;
		text-align: left;

		&:not(:last-child) {
			margin-bottom: 15px;
		}
	}
}

//--------------------------------------------------------------
//スクロールアニメーション
//----------------------------------------------------------------
.inview {
	opacity: 0;
	position: relative;

	&.animate {
		-webkit-animation: inview_slide_up 1.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards 0.2s;
		animation: inview_slide_up 1.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards 0.2s;
	}
}

.inview-group .inview-list {
	opacity: 0;
	position: relative;

	&.animate {
		-webkit-animation: inview_slide_up 0.7s cubic-bezier(.4, 0, .2, 1) forwards 0.2s;
		animation: inview_slide_up 0.7s cubic-bezier(.4, 0, .2, 1) forwards 0.2s;
	}
}


@-webkit-keyframes inview_slide_up {
	0% {
		transform: translateY(10px)
	}

	100% {
		opacity: 1;
		transform: translateY(0)
	}
}

@keyframes inview_slide_up {
	0% {
		transform: translateY(10px)
	}

	100% {
		opacity: 1;
		transform: translateY(0)
	}
}

//--------------------------------------------------------------
//電話リンクをスマホのみに有効
//--------------------------------------------------------------
@include media(pc) {
	a[href*="tel:"] {
		pointer-events: none;
		cursor: default;
		text-decoration: none;
	}
}

//--------------------------------------------------------------
//その他汎用CSS
//--------------------------------------------------------------

//下層メインビジュアル------------------------------------------------

.main_u_v {
	margin: 90px 0 20px;
	height: 300px;
	position: relative;
	background: url("https://placehold.jp/1500x350.png") no-repeat center;
	background-size: cover;

	@include media(sp) {
		background: url("https://placehold.jp/760x350.png") no-repeat center;
		background-size: cover;
		margin: 60px 0 20px;
		padding-bottom: 52%;
		height: auto;
	}

	.main_u_v_in {
		@include center;
		color: #fff;

		h2 {
			font-size: fluid(26, 32);
			letter-spacing: .05em;
			line-height: 1.4;
		}
	}
}

.sec_ttl {
	font-size: fluid(44, 25);
	margin-bottom: fluid(30, 20);
	text-align: center;
	font-weight: 300;
	letter-spacing: .1em;
	font-family: 'Poppins', sans-serif;


	&.sec_ttl_u {
		margin-bottom: fluid(120, 60);
	}

	span {
		display: block;
		font-size: fluid(19, 14);
		font-family: fot-tsukumin-pr6n, sans-serif;
		letter-spacing: .07em;
		font-weight: 100;
		margin-top: -15px;

		@include media(sp) {
			margin-top: -11px;
		}
	}

	&+p {
		margin-bottom: fluid(60, 30);
		text-align: center;

		@include media(sp) {
			padding: 0 20px;
		}
	}
}

.sec_ttl_jp {
	font-size: fluid(32, 19);
	margin-bottom: fluid(100, 30);
	text-align: center;
	font-weight: 300;
	color: #1F1F1F;
	letter-spacing: .1em;
}

.txt_link {
	text-decoration: underline;
	display: inline-block;
	font-size: fluid(16, 14);

	&:hover {
		text-decoration: none;
	}
}

//--------------------------------------------------------------
//フォーム関連
//----------------------------------------------------------------



input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

input[type=submit] {
	-webkit-appearance: none;
	border-radius: 0;
}

textarea {
	resize: none;
	width: auto;
	height: auto;
}

input {

	&[type="text"],
	&[type="email"],
	&[type="tel"],
	&[type="number"],
	&[type="password"],
	&[name="zip"] {
		width: 100%;
		height: 56px;
		line-height: 56px;
		border: 1px solid #e0e0e0;
		background-color: #fff;
		box-sizing: border-box;
		padding: 0 1em;
		border-radius: 0;
		outline: none;
		font-size: 16px;

		@include media(sp) {
			padding: 0 .7em;
		}
	}

	&[type=checkbox] {
		display: none;

		&:checked+.mwform-checkbox-field-text::after {
			opacity: 1;
		}
		&:checked+.mwform-checkbox-field-text::before {
			background-color: #717d95;
		}
	}

	&::placeholder {
		color: #c4c5c6;
		font-size: fluid(16, 13);
	}

	&.error_box {
		background-color: #f7f0f2;
		border: 1px solid #e05a5a;
	}
}

textarea {
	width: 100%;
	height: 160px;
	border: 1px solid #e0e0e0;
	background-color: #fff;
	line-height: 1.4em;
	padding: 1em;
	border-radius: 0;
	font-size: 16px;
	font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", sans-serif;
	outline: none;

	&::placeholder {
		color: #c4c5c6;
		font-size: fluid(16, 13);
	}

	&.error_box {
		background-color: #f7f0f2;
		border: 1px solid #e05a5a;
	}
}


.warning.hidden {
	display: none;
}

.warning, .modal_warning{
	background-color: #f7f0f2;
	font-weight: 400;
	color: #e05a5a;
	padding: fluid(20, 15) fluid(25, 20);
	margin-bottom: fluid(60, 30);
}

.error, .modal_error{
	color: #e05a5a;
	font-weight: 400;
	width: 100%;
}

.mwform-checkbox-field-text {
	box-sizing: border-box;
	cursor: pointer;
	display: inline-block;
	padding-left: 35px;
	position: relative;
	width: auto;
	font-weight: 400;

	&::before {
		background: #fff;
		border: 1px solid #e0e0e0;
		content: "";
		display: block;
		height: 20px;
		width: 20px;
		left: 0;
		position: absolute;
		top: fluid(5, 1)
	}

	&::after {
		border-right: 1px solid #fff;
		border-bottom: 1px solid #fff;
		content: '';
		display: block;
		height: 9px;
		left: 7.5px;
		margin-top: -8px;
		opacity: 0;
		position: absolute;
		top: fluid(17,13);
		transform: rotate(45deg);
		width: 5px;
	}
}

.post,
.input_p_number {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	@media only screen and (max-width: 768px){
		justify-content: space-between;
	}

	.btn {
		border: 1px solid #717D95;
		height: fluid(54, 50);
		line-height: fluid(51, 50);
		padding: 0 28px;
		width: 170px;
		margin-left: 15px;
		margin-top: 0 !important;

		@media only screen and (max-width: 768px){
			width: 100%;
			margin: 15px 0 0 !important;
		}
	}

	input[type="text"],
	input[type="tel"],
	input[type="number"] {
		width: 140px;

		@media only screen and (max-width: 768px){
			width: 43.5%;
		}
	}
}

.input_p_number {
	@include media(sp) {
		flex-wrap: nowrap;

		span {
			display: block;
			margin: 0 5px;
			line-height: 1;
		}
	}
}

.radio {

	.radio-label {
		position: relative;
		display: block;
		font-weight: 400;
		padding-left: 32px;
		font-size: fluid(16, 14);

		@include media(sp) {
			padding-left: 27px;
		}
	}

	&:not(:last-of-type) {
		margin-right: 35px;
	}

	input[type="radio"] {
		position: absolute;
		opacity: 0;

		+.radio-label {
			&:before {
				content: '';
				background: #fff;
				border-radius: 100%;
				border: 1px solid #e0e0e0;
				display: block;
				width: 21px;
				height: 21px;
				position: absolute;
				left: 0;
				top: 5px;
				margin-right: 8px;
				vertical-align: top;
				cursor: pointer;
				text-align: center;
				transition: all 250ms ease;

				@include media(sp) {
					width: 17px;
					height: 17px;
				}
			}
		}

		&:checked {
			+.radio-label {
				&:before {
					background-color: #63666a;
					box-shadow: inset 0 0 0 4px #fff;
				}
			}
		}

		&:focus {
			+.radio-label {
				&:before {
					outline: none;
					border-color: #63666a;
				}
			}
		}

		&:disabled {
			+.radio-label {
				&:before {
					box-shadow: inset 0 0 0 4px #fff;
					border-color: darken(#fff, 25%);
					background: darken(#fff, 25%);
				}
			}
		}

		+.radio-label {
			&:empty {
				&:before {
					margin-right: 0;
				}
			}
		}
	}
}


//--------------------------------------------------------------
//モーダル閉じるボタン
//--------------------------------------------------------------

.modaal-close {
	position: absolute;
	right: 30px;
	width: 30px;
	height: 30px;
	top: 30px;

	@include media(sp) {
		right: 15px;
		top: 15px;
	}

	&:before,
	&:after {
		background: #1f1f1f;
		width: 2px;
		border-radius: 0;
		height: 25px;
		left: 15px;
		top: 4px;

		@include media(sp) {
			height: 20px;
		}
	}

	&:hover {
		background: none;
		opacity: .6;

		&:before,
		&:after {
			background: #1f1f1f;
		}
	}
}

//--------------------------------------------------------------
//SNSフォロー
//--------------------------------------------------------------

.follow_sns_area {
	display: none;

	.follow_sns {
		background-color: #fff;
		padding: fluid(38, 20) fluid(38, 20);
		box-shadow: 0 0 12px 0 rgba(0, 0, 0, .1);
		position: fixed;
		bottom: 20px;
		right: 20px;
		z-index: 10;

		@include media(sp) {
			width: 100%;
			bottom: 0;
			right: 0;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}

		p {
			&.ttl {
				font-family: 'Poppins', sans-serif;
				font-size: fluid(18, 16);
			}

			&.txt {
				font-size: fluid(16, 12);
				margin: -5px 0 10px;
				line-height: 1.4;

				@include media(sp) {
					margin: 0;
				}
			}
		}

		ul {
			display: flex;

			li {
				width: fluid(30, 27);

				&:not(:last-child) {
					margin-right: fluid(20, 15);
				}

				a {
					&:hover {
						opacity: .6;
					}
				}
			}
		}

		.close_btn {
			position: absolute;
			right: 15px;
			width: 25px;
			cursor: pointer;
			height: 25px;
			top: 15px;

			@include media(sp) {
				right: 15px;
				top: 15px;
			}

			&:before,
			&:after {
				background: #1f1f1f;
				width: 1px;
				border-radius: 0;
				height: 20px;
				content: "";
				left: 12px;
				position: absolute;
				top: 4px;

				@include media(sp) {
					height: 20px;
				}
			}

			&:before {
				transform: rotate(-45deg);
			}

			&:after {
				transform: rotate(45deg);
			}

			&:hover {
				background: none;
				opacity: .6;

				&:before,
				&:after {
					background: #1f1f1f;
				}
			}
		}
	}
}

//--------------------------------------------------------------
//登録情報変更成功
//--------------------------------------------------------------
.change_s {
	height: fluid(84, 60);
	background-color: #ECF1F5;
	color: #717D95;
	text-align: center;

	p {
		line-height: fluid(84, 60);
	}
}

//--------------------------------------------------------------
//ページネーション
//--------------------------------------------------------------
.pagination ul {
	display: flex;
	justify-content: center;
	margin-top: 60px;

	li span,
	a {
		display: inline-block;
		color: #717d95;
		background-color: #fff;
		text-decoration: none;
		text-align: center;
		line-height: fluid(42, 28);
		width: fluid(44, 30);
		height: fluid(44, 30);
		margin: 0 fluid(10, 3);
		transition: .2s ease-in-out;
		white-space: nowrap;
		border: 1px solid #717d95;

		&:hover,
		&.current {
			background: #717d95;
			border: 1px solid #717d95;
			color: #fff;
		}
	}
}

.modaal-overlay {
	display: block;
}


//--------------------------------------------------------------
//GTranslate
//--------------------------------------------------------------
.gt_switcher_wrapper {
	top: fluid_tb(16, 12) !important;
	bottom: auto !important;
	right: fluid_tb(28, 14) !important;
	left: auto !important;
	position: fixed !important;
	width: 34px;
	height: 34px;
	cursor: pointer;
	transition: .3s;
	z-index: 999 !important;
	&::before {
		content: "";
		display: inline-block;
		transition: .3s;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 2;
		pointer-events: none;
		background-image: url("../../assets/img/common/icon_lang.png");
		background-size: contain;
		vertical-align: middle;
		background-color: #fff;
	}
}
.gt_selector {
	position: absolute;
	inset: 0;
	margin: auto;
	width: 32px;
	height: 32px;
	border: none;
}

/* 特定スタイル */
#TOP .gt_switcher_wrapper {
	top: fluid_tb(24, 12) !important;
	&::before {
		background-color: #dfe8ee;
	}
	
	&.active{	
		top: fluid_tb(16, 12) !important;
		&::before {
			background-color: #fff;
		}
	}
}
#TOP .gt_switcher_wrapper
.gt_switcher_wrapper::before {
	background-color: #fff;
}